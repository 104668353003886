"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.locationStatusToColor = void 0;
const location_status_enum_1 = require("../../locations/enums/location-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function locationStatusToColor(status) {
    switch (status) {
        case location_status_enum_1.LocationStatusEnum.OPEN:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case location_status_enum_1.LocationStatusEnum.CLOSED:
            return record_color_enum_1.RecordColorEnum.SLATE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.locationStatusToColor = locationStatusToColor;
