import { AsyncPipe } from '@angular/common';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
	MAT_TOOLTIP_DEFAULT_OPTIONS,
	MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	ExtraOptions,
	PreloadAllModules,
	Router,
	RouterModule,
} from '@angular/router';
import { PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import * as Sentry from '@sentry/angular';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import moment from 'moment';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { initializeSentry } from '../instrument-sentry';

import { AppConfigService } from './core/config/app.config.service';
import { FUSE_CONFIG as fuseConfig } from './core/config/fuse.config.types';
import { NavigationService } from './core/navigation/navigation.service';
import { NotificationsService } from './core/notifications/notifications.service';
import { TimeTravelingBadgeComponent } from './shared/components/time-traveling-badge/time-traveling-badge.component';

export function momentAdapterFactory() {
	return adapterFactory(moment);
}

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
	showDelay: 700,
	hideDelay: 0,
	touchendHideDelay: 0,
};

const routerConfig: ExtraOptions = {
	preloadingStrategy: PreloadAllModules,
	scrollPositionRestoration: 'enabled',
};

@NgModule({
	declarations: [AppComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: (configService: AppConfigService) => () =>
				configService.getConfig(),
			deps: [AppConfigService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (configService: AppConfigService) => () =>
				initializeSentry(configService),
			deps: [AppConfigService],
			multi: true,
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: true,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (navigationService: NavigationService) => () =>
				navigationService.loadNavigation(),
			deps: [NavigationService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (notificationsService: NotificationsService) => () =>
				notificationsService.load$(),
			deps: [NotificationsService],
			multi: true,
		},
		// THIS WILL BE USED WHEN TIME MACHINE WILL BE IMPLEMENTED
		// {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useClass: TimeMachineInterceptor,
		// 	multi: true,
		// },
		{ provide: PureAbility, useValue: new PureAbility() },
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
	],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(appRoutes, routerConfig),
		LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
		FuseModule,
		FuseConfigModule.forRoot(fuseConfig),
		FuseMockApiModule.forRoot(mockApiServices),
		CoreModule,
		AbilityModule,
		LayoutModule,
		TimeTravelingBadgeComponent,
		AsyncPipe,
	],
})
export class AppModule {}
