"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleModelListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleModelListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    name;
    externalReference;
    make;
    mobileTitle = () => {
        return `${this.name}`;
    };
    mobileSubtitle = () => {
        return `${this.make.name}`;
    };
}
exports.VehicleModelListDto = VehicleModelListDto;
