"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDatesDifferenceInMonths = void 0;
const to_date_without_time_function_1 = require("./to-date-without-time.function");
function toDatesDifferenceInMonths(dateFrom, dateTo) {
    dateFrom = (0, to_date_without_time_function_1.toDateWithoutTime)(dateFrom);
    dateTo = (0, to_date_without_time_function_1.toDateWithoutTime)(dateTo);
    if (isNaN(dateFrom.getTime()) || isNaN(dateTo.getTime())) {
        return 0;
    }
    if (dateFrom > dateTo) {
        [dateFrom, dateTo] = [dateTo, dateFrom];
    }
    const yearsDifference = dateTo.getFullYear() - dateFrom.getFullYear();
    const monthsDifference = dateTo.getMonth() - dateFrom.getMonth();
    const totalMonthsDifference = yearsDifference * 12 + monthsDifference;
    return Math.max(1, totalMonthsDifference);
}
exports.toDatesDifferenceInMonths = toDatesDifferenceInMonths;
