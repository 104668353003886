"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rentalAgreementStatusToColor = void 0;
const rental_agreements_1 = require("../../rental-agreements");
const record_color_enum_1 = require("../enums/record-color.enum");
function rentalAgreementStatusToColor(status) {
    switch (status) {
        case rental_agreements_1.RentalAgreementStatusEnum.PENDING:
            return record_color_enum_1.RecordColorEnum.AMBER;
        case rental_agreements_1.RentalAgreementStatusEnum.APPROVED:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case rental_agreements_1.RentalAgreementStatusEnum.REJECTED:
            return record_color_enum_1.RecordColorEnum.ROSE;
        case rental_agreements_1.RentalAgreementStatusEnum.IN_PROGRESS:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case rental_agreements_1.RentalAgreementStatusEnum.CANCELLED:
            return record_color_enum_1.RecordColorEnum.SLATE;
        case rental_agreements_1.RentalAgreementStatusEnum.COMPLETED:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.rentalAgreementStatusToColor = rentalAgreementStatusToColor;
