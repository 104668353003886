"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateCustomerDto = void 0;
class CreateCustomerDto {
    color;
    status;
    recordCategory;
    contact;
    driver;
    tags;
}
exports.CreateCustomerDto = CreateCustomerDto;
