import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash';

import { sampleEvents, statusesForMonth } from './data';

@Injectable({ providedIn: 'root' })
export class CalendarApi {
	_statusesForMonth = statusesForMonth;
	_sampleEvents = sampleEvents;

	constructor(private _fuseMockApiService: FuseMockApiService) {
		this.registerHandlers();
	}

	registerHandlers(): void {
		// THESE ARE AN EXAMPLE FOR A MONTH CALL
		this._fuseMockApiService
			.onGet('api/calendar/month-statuses', 500)
			.reply(({ request }) => {
				return [200, cloneDeep(this._statusesForMonth)];
			});

		this._fuseMockApiService
			.onGet('api/calendar/events', 500)
			.reply(({ request }) => {
				return [200, cloneDeep(this._sampleEvents)];
			});
	}
}
