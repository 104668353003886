import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash';

import { reportsHome } from './data';

@Injectable({
	providedIn: 'root',
})
export class ReportsHomeApi {
	_reportsHome = reportsHome;

	constructor(private _fuseMockApiService: FuseMockApiService) {
		this.registerHandlers();
	}

	registerHandlers(): void {
		this._fuseMockApiService.onGet('api/reports/home').reply(({ request }) => {
			return [200, cloneDeep(this._reportsHome)];
		});
	}
}
