"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleMaintenanceStatusEnum = void 0;
var VehicleMaintenanceStatusEnum;
(function (VehicleMaintenanceStatusEnum) {
    VehicleMaintenanceStatusEnum["PENDING"] = "pending";
    VehicleMaintenanceStatusEnum["APPROVED"] = "approved";
    VehicleMaintenanceStatusEnum["REJECTED"] = "rejected";
    VehicleMaintenanceStatusEnum["SCHEDULED"] = "scheduled";
    VehicleMaintenanceStatusEnum["IN_PROGRESS"] = "in_progress";
    VehicleMaintenanceStatusEnum["COMPLETED"] = "completed";
})(VehicleMaintenanceStatusEnum || (exports.VehicleMaintenanceStatusEnum = VehicleMaintenanceStatusEnum = {}));
