import {
	Directive,
	ElementRef,
	Input,
	Renderer2,
	OnInit,
	Output,
	EventEmitter,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Directive({
	selector: '[routerActive]',
	standalone: true,
})
export class RouterActiveDirective implements OnInit {
	@Input() routerActive: string | undefined; // URL to match
	@Input() activeClass: string = 'bg-hover'; // Default class to add

	public isActive: boolean = false; // Internal state to determine if the route matches
	@Output() isActiveChange = new EventEmitter<boolean>();

	constructor(
		private router: Router,
		private element: ElementRef,
		private renderer: Renderer2,
	) {}

	ngOnInit(): void {
		if (
			this.router
				.parseUrl(this.router.url)
				.root.children.primary.segments[1].toString() === this.routerActive
		) {
			this.renderer.addClass(this.element.nativeElement, this.activeClass);
			this.renderer.addClass(this.element.nativeElement, 'font-semibold');
			this.isActive = true;
			this.isActiveChange.emit(true);
		} else {
			this.renderer.removeClass(this.element.nativeElement, this.activeClass);
			this.renderer.removeClass(this.element.nativeElement, 'font-semibold');

			this.isActive = false;
			this.isActiveChange.emit(false);
		}

		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				if (
					this.router
						.parseUrl(this.router.url)
						.root.children.primary.segments[1].toString() === this.routerActive
				) {
					this.renderer.addClass(this.element.nativeElement, this.activeClass);
					this.renderer.addClass(this.element.nativeElement, 'font-semibold');
					this.isActive = true;
					this.isActiveChange.emit(true);
				} else {
					this.renderer.removeClass(
						this.element.nativeElement,
						this.activeClass,
					);
					this.renderer.removeClass(
						this.element.nativeElement,
						'font-semibold',
					);
					this.isActive = false;
					this.isActiveChange.emit(false);
				}
			});
	}
}
