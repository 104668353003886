import { CalendarEvent } from 'angular-calendar';
import {
	DayStatus,
	EventGroup,
} from 'app/modules/admin/calendar/components/calendar/calendar.types';

function getRandomInt(max: number): number {
	return Math.floor(Math.random() * max);
}

export const statusesForMonth: DayStatus[] = Array.from(
	{ length: 30 },
	(_, i) => ({
		day: (i + 1).toString(),
		availableVehiclesTitle: 'Available Vehicles',
		availableVehiclesShortLabel: 'AV',
		availableVehicles: getRandomInt(200),
		inwardVehiclesTitle: 'Inward Vehicles',
		inwardVehiclesTitleShortLabel: 'IN',
		inwardVehicles: getRandomInt(10),
		outwardVehiclesTitle: 'Outward Vehicles',
		outwardVehiclesTitleShortLabel: 'OUT',
		outwardVehicles: getRandomInt(50),
		reservedVehiclesTitle: 'Reserved Vehicles',
		reservedVehiclesTitleShortLabel: 'RV',
		reservedVehicles: getRandomInt(50),
		unavailableVehiclesTitle: 'Unavailable Vehicles',
		unavailableVehiclesTitleShortLabel: 'UV',
		unavailableVehicles: getRandomInt(10),
	}),
);

export const sampleEvents: CalendarEvent<EventGroup>[] = [
	{
		id: 1,
		start: new Date(2023, 6, 1, 9, 0),
		end: new Date(2023, 6, 3, 17, 0),
		title: 'Mr. Alpha Booking',
		meta: {
			group: 'booking',
		},
	},
	{
		id: 2,
		start: new Date(2023, 6, 5, 10, 0),
		end: new Date(2023, 6, 7, 16, 0),
		title: 'Mr. Bravo Meeting',
		meta: {
			group: 'reservation',
		},
	},
	{
		id: 3,
		start: new Date(2023, 6, 4, 9, 30),
		end: new Date(2023, 6, 6, 15, 30),
		title: 'Mr. Charlie Appointment',
		meta: {
			group: 'booking',
		},
	},
	{
		id: 4,
		start: new Date(2023, 6, 6, 11, 0),
		end: new Date(2023, 6, 8, 16, 30),
		title: 'Mr. Delta Conference',
		meta: {
			group: 'booking',
		},
	},
	{
		id: 5,
		start: new Date(2023, 6, 9, 10, 0),
		end: new Date(2023, 6, 11, 15, 0),
		title: 'Mr. Echo Seminar',
		meta: {
			group: 'reservation',
		},
	},
	{
		id: 6,
		start: new Date(2023, 6, 12, 9, 0),
		end: new Date(2023, 6, 14, 17, 0),
		title: 'Mr. Foxtrot Workshop',
		meta: {
			group: 'booking',
		},
	},
	{
		id: 7,
		start: new Date(2023, 6, 15, 10, 30),
		end: new Date(2023, 6, 17, 16, 30),
		title: 'Mr. Golf Retreat',
		meta: {
			group: 'reservation',
		},
	},
	{
		id: 8,
		start: new Date(2023, 6, 18, 11, 0),
		end: new Date(2023, 6, 20, 15, 0),
		title: 'Mr. Hotel Booking',
		meta: {
			group: 'reservation',
		},
	},
	{
		id: 9,
		start: new Date(2023, 6, 21, 10, 0),
		end: new Date(2023, 6, 23, 17, 0),
		title: 'Mr. India Meeting',
		meta: {
			group: 'reservation',
		},
	},
	{
		id: 10,
		start: new Date(2023, 6, 24, 9, 0),
		end: new Date(2023, 6, 26, 17, 0),
		title: 'Mr. Juliet Appointment',
		meta: {
			group: 'booking',
		},
	},
];
