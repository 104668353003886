import { SearchMockApi } from 'app/mock-api/common/search/api';
import { NavigationMockApi } from 'app/mock-api/navigation/api';

import { CalendarApi } from './admin/calendar/api';
import { DashboardsMockApi } from './admin/dashboards/api';
import { ReportsHomeApi } from './admin/reports/home/api';
import { ReportDetailsApi } from './admin/reports/report-details/api';
import { NotificationsMockApi } from './common/notifications/api';
import { WidgetsMockApi } from './shared/widgets/api';

export const mockApiServices = [
	// Common
	SearchMockApi,
	NotificationsMockApi,
	NavigationMockApi,

	// Dashboards
	DashboardsMockApi,

	// Shared
	WidgetsMockApi,

	// Admin
	ReportsHomeApi,
	ReportDetailsApi,
	CalendarApi,
];
