"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSupplierDto = void 0;
class CreateSupplierDto {
    color;
    status;
    recordCategory;
    tags;
    contact;
}
exports.CreateSupplierDto = CreateSupplierDto;
