"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canPerformAction = void 0;
const set_status_schema_1 = require("../../../shared/validators/schemas/set-status.schema");
const vehicle_action_enum_1 = require("../../enums/vehicle-action.enum");
const vehicle_status_enum_1 = require("../../enums/vehicle-status.enum");
const canPerformAction = (record, action, abortEarly = false) => {
    if (!record)
        throw new Error('record is required.');
    let validationResult;
    const fixedStatuses = [
        vehicle_status_enum_1.VehicleStatusEnum.IN_PARK,
        vehicle_status_enum_1.VehicleStatusEnum.SCRAPPED,
        vehicle_status_enum_1.VehicleStatusEnum.SOLD,
        vehicle_status_enum_1.VehicleStatusEnum.OUT_OF_SERVICE,
    ];
    switch (action) {
        case vehicle_action_enum_1.VehicleActionEnum.PARK:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_status_enum_1.VehicleStatusEnum.IN_PARK, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_action_enum_1.VehicleActionEnum.SCRAP:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_status_enum_1.VehicleStatusEnum.SCRAPPED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_action_enum_1.VehicleActionEnum.SELL:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_status_enum_1.VehicleStatusEnum.SOLD, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_action_enum_1.VehicleActionEnum.OUT_OF_SERVICE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_status_enum_1.VehicleStatusEnum.OUT_OF_SERVICE, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_action_enum_1.VehicleActionEnum.UPDATE_ODOMETER:
            break;
        case vehicle_action_enum_1.VehicleActionEnum.CREATE_RENTAL_RESERVATION:
            break;
        case vehicle_action_enum_1.VehicleActionEnum.CREATE_RENTAL_AGREEMENT:
            break;
        case vehicle_action_enum_1.VehicleActionEnum.CREATE_LEASE_AGREEMENT:
            break;
        case vehicle_action_enum_1.VehicleActionEnum.CREATE_ROAD_WORTHY_CHECK:
            break;
        case vehicle_action_enum_1.VehicleActionEnum.CREATE_ROAD_TAX:
            break;
        case vehicle_action_enum_1.VehicleActionEnum.CREATE_MAINTENANCE:
            break;
        case vehicle_action_enum_1.VehicleActionEnum.CREATE_DAMAGE:
            break;
        default:
            throw new Error(`Unknown action: ${action}`);
    }
    if (validationResult.error) {
        return {
            isValid: false,
            errors: validationResult.error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.canPerformAction = canPerformAction;
