import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { AppConfig } from './app.config.types';

@Injectable({
	providedIn: 'root',
})
export class AppConfigService {
	private _config: BehaviorSubject<AppConfig> = new BehaviorSubject(null);

	public get config(): AppConfig {
		return this._config.getValue();
	}

	public get config$(): Observable<AppConfig> {
		return this._config.asObservable();
	}

	constructor(
		private _httpClient: HttpClient,
		private _logger: NGXLogger,
	) {}

	public getConfig(): Observable<AppConfig> {
		return this._httpClient.get('assets/config/config.json').pipe(
			map((response: any) => {
				// Destructure the response
				const { appLogLevel, ...config } = response;

				// Transform the appLogLevel string into a NgxLoggerLevel
				config.appLogLevel = NgxLoggerLevel[appLogLevel];

				// Update logger
				this._logger.updateConfig({ level: config.appLogLevel });

				// Log
				this._logger.info('getConfig > loaded config from server:', config);

				// Store the config in the service
				this._config.next(config);

				// Return the config
				return config;
			}),
		);
	}
}
