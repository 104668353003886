import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { AsyncPipe, DatePipe, JsonPipe, NgIf } from '@angular/common';
import { TimeMachineService } from './time-machine.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { emit } from 'process';
import { Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatInputModule } from '@angular/material/input';

@Component({
	selector: 'app-time-machine',
	templateUrl: 'time-machine.component.html',
	styleUrls: ['time-machine.component.scss'],
	standalone: true,
	imports: [
		MatFormFieldModule,
		MatDatepickerModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		JsonPipe,
		DatePipe,
		NgIf,
		MatIconModule,
		MatTooltipModule,
		MatButtonModule,
		AsyncPipe,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: fuseAnimations,
})
export class TimeMachineComponent {
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public today = this._timeMachineService.today;
	public isEditMode = this._timeMachineService.isEditMode;
	public date = new FormControl<Date | null>(this.today, [Validators.required]);
	constructor(
		private _timeMachineService: TimeMachineService,
		private _cdr: ChangeDetectorRef,
	) {
		this.date.setValue(this._timeMachineService.timeMachineDate.value);
		this._timeMachineService.resetControls
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: () => {
					this.resetToday();
				},
			});
		this.date.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe({
			next: (res) => {
				this._timeMachineService.timeMachineDate.next(res);
				this._timeMachineService.handlePresentState(res, this.today);
			},
		});
	}
	resetToday() {
		this.date.reset(this.today);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
