"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleColorRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleColorRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    name;
    type;
    externalReference;
}
exports.VehicleColorRecordDto = VehicleColorRecordDto;
