"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLegalAge = void 0;
const legal_age_schema_1 = require("../schemas/legal-age.schema");
const isLegalAge = (date, legalAge, abortEarly = false) => {
    const { error } = (0, legal_age_schema_1.legalAgeSchema)(legalAge).validate(date, {
        abortEarly,
    });
    if (error) {
        return {
            isValid: false,
            errors: error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.isLegalAge = isLegalAge;
