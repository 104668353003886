"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleBodyTypeEnum = void 0;
var VehicleBodyTypeEnum;
(function (VehicleBodyTypeEnum) {
    VehicleBodyTypeEnum["SEDAN"] = "sedan";
    VehicleBodyTypeEnum["COUPE"] = "coupe";
    VehicleBodyTypeEnum["CONVERTIBLE"] = "convertible";
    VehicleBodyTypeEnum["ROADSTER"] = "roadster";
    VehicleBodyTypeEnum["HATCHBACK"] = "hatchback";
    VehicleBodyTypeEnum["WAGON"] = "wagon";
    VehicleBodyTypeEnum["SUV"] = "suv";
    VehicleBodyTypeEnum["CUV"] = "cuv";
    VehicleBodyTypeEnum["STREETCAR"] = "streetcar";
    VehicleBodyTypeEnum["PICKUP"] = "pickup";
    VehicleBodyTypeEnum["TRAILER"] = "trailer";
    VehicleBodyTypeEnum["MINIVAN"] = "minivan";
    VehicleBodyTypeEnum["CAMPER"] = "camper";
    VehicleBodyTypeEnum["VAN"] = "van";
    VehicleBodyTypeEnum["TRUCK"] = "truck";
    VehicleBodyTypeEnum["BUS"] = "bus";
    VehicleBodyTypeEnum["TRACTOR"] = "tractor";
    VehicleBodyTypeEnum["BUGGY"] = "buggy";
    VehicleBodyTypeEnum["QUAD_BIKE"] = "quad_bike";
    VehicleBodyTypeEnum["SNOWMOBILE"] = "snowmobile";
    VehicleBodyTypeEnum["BICYCLE"] = "bicycle";
    VehicleBodyTypeEnum["SCOOTER"] = "scooter";
    VehicleBodyTypeEnum["MOTORCYCLE"] = "motorcycle";
    VehicleBodyTypeEnum["OTHER"] = "other";
})(VehicleBodyTypeEnum || (exports.VehicleBodyTypeEnum = VehicleBodyTypeEnum = {}));
