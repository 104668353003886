"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleTransmissionTypeEnum = void 0;
var VehicleTransmissionTypeEnum;
(function (VehicleTransmissionTypeEnum) {
    VehicleTransmissionTypeEnum["MANUAL"] = "manual";
    VehicleTransmissionTypeEnum["AUTOMATIC"] = "automatic";
    VehicleTransmissionTypeEnum["CVT"] = "cvt";
    VehicleTransmissionTypeEnum["OTHER"] = "other";
})(VehicleTransmissionTypeEnum || (exports.VehicleTransmissionTypeEnum = VehicleTransmissionTypeEnum = {}));
