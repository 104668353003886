"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leaseAgreementStatusToColor = void 0;
const lease_agreements_1 = require("../../lease-agreements");
const record_color_enum_1 = require("../enums/record-color.enum");
function leaseAgreementStatusToColor(status) {
    switch (status) {
        case lease_agreements_1.LeaseAgreementStatusEnum.PENDING:
            return record_color_enum_1.RecordColorEnum.AMBER;
        case lease_agreements_1.LeaseAgreementStatusEnum.APPROVED:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case lease_agreements_1.LeaseAgreementStatusEnum.REJECTED:
            return record_color_enum_1.RecordColorEnum.ROSE;
        case lease_agreements_1.LeaseAgreementStatusEnum.IN_PROGRESS:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case lease_agreements_1.LeaseAgreementStatusEnum.CANCELLED:
            return record_color_enum_1.RecordColorEnum.SLATE;
        case lease_agreements_1.LeaseAgreementStatusEnum.COMPLETED:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.leaseAgreementStatusToColor = leaseAgreementStatusToColor;
