"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileSensitivityEnum = void 0;
var FileSensitivityEnum;
(function (FileSensitivityEnum) {
    FileSensitivityEnum["PUBLIC"] = "public";
    FileSensitivityEnum["INTERNAL"] = "internal";
    FileSensitivityEnum["PERSONAL_DATA"] = "personal_data";
    FileSensitivityEnum["CONFIDENTIAL"] = "confidential";
})(FileSensitivityEnum || (exports.FileSensitivityEnum = FileSensitivityEnum = {}));
