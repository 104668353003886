"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleElectricBatteryTypeEnum = void 0;
var VehicleElectricBatteryTypeEnum;
(function (VehicleElectricBatteryTypeEnum) {
    VehicleElectricBatteryTypeEnum["IRON_PHOSPHATE"] = "iron_phosphate";
    VehicleElectricBatteryTypeEnum["LI_ION"] = "liion";
    VehicleElectricBatteryTypeEnum["LI_POLY"] = "lipoly";
    VehicleElectricBatteryTypeEnum["LEAD_ACID"] = "lead_acid";
    VehicleElectricBatteryTypeEnum["LITHIUM_ION"] = "lithium_ion";
    VehicleElectricBatteryTypeEnum["NICKEL_METAL_HYDRIDE"] = "nickel_metal_hydride";
    VehicleElectricBatteryTypeEnum["NICKLE_COBALT_ALUMINUM"] = "nickle_cobalt_aluminum";
    VehicleElectricBatteryTypeEnum["NICKLE_COBALT_MANGANESE"] = "nickle_cobalt_manganese";
    VehicleElectricBatteryTypeEnum["SILICON"] = "silicon";
})(VehicleElectricBatteryTypeEnum || (exports.VehicleElectricBatteryTypeEnum = VehicleElectricBatteryTypeEnum = {}));
