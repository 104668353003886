"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateVehicleDamageDto = void 0;
const transform_date_decorator_1 = require("../../shared/decorators/transform-date.decorator");
const transform_int_decorator_1 = require("../../shared/decorators/transform-int.decorator");
class CreateVehicleDamageDto {
    color;
    code;
    status;
    recordCategory;
    reportedAt;
    type;
    fault;
    severity;
    policeReportFiled;
    policeReportExternalReference;
    notes;
    vehicleId;
    rentalAgreementId;
    leaseAgreementId;
    staffId;
    scrapId;
    thirdPartyContact;
    thirdPartyInsuranceId;
    insuranceClaimId;
    tags;
}
exports.CreateVehicleDamageDto = CreateVehicleDamageDto;
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], CreateVehicleDamageDto.prototype, "reportedAt", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDamageDto.prototype, "vehicleId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDamageDto.prototype, "rentalAgreementId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDamageDto.prototype, "leaseAgreementId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDamageDto.prototype, "staffId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDamageDto.prototype, "scrapId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDamageDto.prototype, "thirdPartyInsuranceId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDamageDto.prototype, "insuranceClaimId", void 0);
