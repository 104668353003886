"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateStaffDto = void 0;
class CreateStaffDto {
    color;
    status;
    recordCategory;
    contact;
    locationId;
    userId;
    tags;
}
exports.CreateStaffDto = CreateStaffDto;
