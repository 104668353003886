"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    vin;
    status;
    recordCategory;
    licensePlate;
    make;
    model;
    year;
    series;
    trim;
    exteriorColor;
    bodyType;
    type;
    transmissionType;
    driveTrainType;
    odometerUnit;
    odometerValue;
    primaryPowerPlantType;
    secondaryPowerPlantType;
    engineSizeUnit;
    engineSize;
    engineOutput;
    engineTurbine;
    averageEmissionsCO2;
    emissionsUnitCO2;
    electricMotorOutput;
    allocation;
    location;
    category;
    valuation;
    tags;
    avatarFileUrl;
    mobileTitle = () => {
        return this.make ? `${this.make}`?.trim().replace(/\s+/g, ' ') : '';
    };
    mobileSubtitle = () => {
        return this.model ? `${this.model}`?.trim().replace(/\s+/g, ' ') : '';
    };
    powerPlantFull = () => {
        const primary = this.primaryPowerPlantType
            ? `${this.primaryPowerPlantType}`?.trim().replace(/\s+/g, ' ')
            : '';
        const secondary = this.secondaryPowerPlantType
            ? `${this.secondaryPowerPlantType}`?.trim().replace(/\s+/g, ' ')
            : '';
        return `${primary}${secondary ? ` / ${secondary}` : ''}`;
    };
    engineFull = () => {
        const engineSize = this.engineSize ? this.engineSize.toLocaleString() : '';
        const engineSizeUnit = this.engineSizeUnit
            ? `${this.engineSizeUnit}`?.trim().replace(/\s+/g, ' ')
            : '';
        return `${engineSize}${engineSizeUnit ? ` ${engineSizeUnit}` : ''}`;
    };
    motorFull = () => {
        const motorOutput = this.electricMotorOutput
            ? this.electricMotorOutput.toLocaleString()
            : '';
        return `${motorOutput} kW`;
    };
    averageEmissionsFull = () => {
        const averageEmissionsCO2 = this.averageEmissionsCO2
            ? this.averageEmissionsCO2.toLocaleString()
            : '';
        const emissionsUnitCO2 = this.emissionsUnitCO2
            ? `${this.emissionsUnitCO2}`?.trim().replace(/\s+/g, ' ')
            : '';
        return `${averageEmissionsCO2}${emissionsUnitCO2 ? ` ${emissionsUnitCO2}` : ''}`;
    };
    makeFull = () => {
        return `${this.make?.name || ''}` + `${this.model?.name || ''}`;
    };
    odometerFull = () => {
        const odometerValue = this.odometerValue
            ? this.odometerValue.toLocaleString()
            : '';
        return (`${odometerValue}` + ` ${this.odometerUnit ? this.odometerUnit : ''}`);
    };
}
exports.VehicleListDto = VehicleListDto;
