import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarModule,
	MatSnackBarRef,
} from '@angular/material/snack-bar';
import { FuseAlertModule } from '@fuse/components/alert';
import { Subject } from 'rxjs';

@Component({
	selector: 'error-alert',
	standalone: true,
	imports: [
		MatSnackBarModule,
		FuseAlertModule,
		MatIconModule,
		MatExpansionModule,
	],
	templateUrl: './error-alert.component.html',
	styleUrls: ['./error-alert.component.scss'],
})
export class ErrorAlertComponent implements OnInit, OnDestroy {
	public panelOpenState: boolean = false;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _snackBarRef: MatSnackBarRef<any>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
	) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
