"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canPerformAction = void 0;
const set_status_schema_1 = require("../../../shared/validators/schemas/set-status.schema");
const vehicle_road_worthy_check_actions_enum_1 = require("../../enums/vehicle-road-worthy-check-actions.enum");
const vehicle_road_worthy_check_status_enum_1 = require("../../enums/vehicle-road-worthy-check-status.enum");
const canPerformAction = (record, action, abortEarly = false) => {
    if (!record)
        throw new Error('record is required.');
    let validationResult;
    const fixedStatuses = [
        vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.APPROVED,
        vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.REJECTED,
        vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.PENDING,
    ];
    switch (action) {
        case vehicle_road_worthy_check_actions_enum_1.VehicleRoadWorthyCheckActionEnum.APPROVE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.APPROVED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_road_worthy_check_actions_enum_1.VehicleRoadWorthyCheckActionEnum.REJECT:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.REJECTED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_road_worthy_check_actions_enum_1.VehicleRoadWorthyCheckActionEnum.PENDING:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.PENDING, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_road_worthy_check_actions_enum_1.VehicleRoadWorthyCheckActionEnum.PAY:
            break;
        case vehicle_road_worthy_check_actions_enum_1.VehicleRoadWorthyCheckActionEnum.CREATE_MAINTENANCE:
            break;
        case vehicle_road_worthy_check_actions_enum_1.VehicleRoadWorthyCheckActionEnum.SCHEDULE:
            break;
        default:
            throw new Error(`Unknown action: ${action}`);
    }
    if (validationResult.error) {
        return {
            isValid: false,
            errors: validationResult.error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.canPerformAction = canPerformAction;
