"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateLeaseAgreementDto = void 0;
const shared_1 = require("../../shared");
const transform_int_decorator_1 = require("../../shared/decorators/transform-int.decorator");
class CreateLeaseAgreementDto {
    color;
    status;
    recordCategory;
    startsAt;
    endsAt;
    option;
    totalLossPenaltyPercentage;
    buyOutTotalNet;
    buyOutTotalTax;
    buyOutTotalGross;
    odometerUnit;
    yearlyOdometerLimit;
    yearlyOdometerLimitPenaltyPerUnit;
    tireReplacementFrequency;
    vehicleId;
    customerId;
    leaseOrderId;
    valuationId;
    pricingItems;
    drivers;
    tags;
}
exports.CreateLeaseAgreementDto = CreateLeaseAgreementDto;
__decorate([
    (0, shared_1.TransformDate)(),
    __metadata("design:type", Date)
], CreateLeaseAgreementDto.prototype, "startsAt", void 0);
__decorate([
    (0, shared_1.TransformDate)(),
    __metadata("design:type", Date)
], CreateLeaseAgreementDto.prototype, "endsAt", void 0);
__decorate([
    (0, shared_1.TransformFloat)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "totalLossPenaltyPercentage", void 0);
__decorate([
    (0, shared_1.TransformFloat)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "buyOutTotalNet", void 0);
__decorate([
    (0, shared_1.TransformFloat)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "buyOutTotalTax", void 0);
__decorate([
    (0, shared_1.TransformFloat)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "buyOutTotalGross", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "yearlyOdometerLimit", void 0);
__decorate([
    (0, shared_1.TransformFloat)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "yearlyOdometerLimitPenaltyPerUnit", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "tireReplacementFrequency", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "vehicleId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "customerId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "leaseOrderId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateLeaseAgreementDto.prototype, "valuationId", void 0);
