"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformDate = void 0;
const class_transformer_1 = require("class-transformer");
function TransformDate({ toClassOnly = true } = {}) {
    return (0, class_transformer_1.Transform)(({ value }) => (value ? new Date(value) : value), {
        toClassOnly,
    });
}
exports.TransformDate = TransformDate;
