"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordColorEnum = void 0;
var RecordColorEnum;
(function (RecordColorEnum) {
    RecordColorEnum["DEFAULT"] = "um-neutral";
    RecordColorEnum["SLATE"] = "slate";
    RecordColorEnum["GRAY"] = "gray";
    RecordColorEnum["ZINC"] = "zinc";
    RecordColorEnum["NEUTRAL"] = "neutral";
    RecordColorEnum["STONE"] = "stone";
    RecordColorEnum["RED"] = "red";
    RecordColorEnum["ORANGE"] = "orange";
    RecordColorEnum["AMBER"] = "amber";
    RecordColorEnum["YELLOW"] = "yellow";
    RecordColorEnum["LIME"] = "lime";
    RecordColorEnum["GREEN"] = "green";
    RecordColorEnum["EMERALD"] = "emerald";
    RecordColorEnum["TEAL"] = "teal";
    RecordColorEnum["CYAN"] = "cyan";
    RecordColorEnum["SKY"] = "sky";
    RecordColorEnum["BLUE"] = "blue";
    RecordColorEnum["INDIGO"] = "indigo";
    RecordColorEnum["VIOLET"] = "violet";
    RecordColorEnum["PURPLE"] = "purple";
    RecordColorEnum["FUCHSIA"] = "fuchsia";
    RecordColorEnum["PINK"] = "pink";
    RecordColorEnum["ROSE"] = "rose";
})(RecordColorEnum || (exports.RecordColorEnum = RecordColorEnum = {}));
