"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiErrorCodeEnum = void 0;
var ApiErrorCodeEnum;
(function (ApiErrorCodeEnum) {
    ApiErrorCodeEnum[ApiErrorCodeEnum["OK"] = 0] = "OK";
    ApiErrorCodeEnum[ApiErrorCodeEnum["PASSWORD_CHANGE_REQUIRED"] = 1] = "PASSWORD_CHANGE_REQUIRED";
    ApiErrorCodeEnum[ApiErrorCodeEnum["USER_LOCKED_OUT"] = 2] = "USER_LOCKED_OUT";
    ApiErrorCodeEnum[ApiErrorCodeEnum["UNKNOWN"] = Number.MAX_SAFE_INTEGER] = "UNKNOWN";
})(ApiErrorCodeEnum || (exports.ApiErrorCodeEnum = ApiErrorCodeEnum = {}));
