"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageItemSeverityEnum = void 0;
var VehicleDamageItemSeverityEnum;
(function (VehicleDamageItemSeverityEnum) {
    VehicleDamageItemSeverityEnum["MINOR"] = "minor";
    VehicleDamageItemSeverityEnum["MODERATE"] = "moderate";
    VehicleDamageItemSeverityEnum["MAJOR"] = "major";
    VehicleDamageItemSeverityEnum["REQUIRES_REPLACEMENT"] = "requires_replacement";
})(VehicleDamageItemSeverityEnum || (exports.VehicleDamageItemSeverityEnum = VehicleDamageItemSeverityEnum = {}));
