"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingProfileListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class PricingProfileListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.PRICING_PROFILE;
    }
    code;
    status;
    recordCategory;
    name;
    defaultUnits;
    unitPrice;
    tags;
    avatarFileUrl;
    mobileTitle = () => {
        return `${this.code}${this.name ? ' - ' + this.name : ''}`;
    };
    mobileSubtitle = () => {
        return `${this.defaultUnits} x ${this.unitPrice}`;
    };
}
exports.PricingProfileListDto = PricingProfileListDto;
