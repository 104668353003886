import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ActionBarMediatorService {
	public clearRowSelections: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor() {
	}
}
