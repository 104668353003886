"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordTab = void 0;
class RecordTab {
    displayOrder;
    isSelectable;
    isVisible;
    isSelected;
    name;
    description;
    supportsTimeMachine;
}
exports.RecordTab = RecordTab;
