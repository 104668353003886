import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { MatIconModule } from '@angular/material/icon';
import { UltraButtonComponent } from '../ultra-button/ultra-button.component';

@Component({
	selector: 'app-um-confirmation-popup',
	templateUrl: 'um-confirmation-popup.component.html',
	standalone: true,
	animations: fuseAnimations,
	imports: [MatIconModule, UltraButtonComponent],
})
export class UmConfirmationPopupComponent {
	private _onConfirm$: Subject<boolean> = new Subject();
	get onConfirm$() {
		return this._onConfirm$.asObservable();
	}

	cancellation() {
		this._onConfirm$.next(false);
	}

	confirmation() {
		this._onConfirm$.next(true);
	}
}
