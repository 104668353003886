"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpirePasswordsPeriodEnum = void 0;
var ExpirePasswordsPeriodEnum;
(function (ExpirePasswordsPeriodEnum) {
    ExpirePasswordsPeriodEnum["ONE_MONTH"] = "one_month";
    ExpirePasswordsPeriodEnum["TWO_MONTHS"] = "two_months";
    ExpirePasswordsPeriodEnum["THREE_MONTHS"] = "three_months";
    ExpirePasswordsPeriodEnum["SIX_MONTHS"] = "six_months";
})(ExpirePasswordsPeriodEnum || (exports.ExpirePasswordsPeriodEnum = ExpirePasswordsPeriodEnum = {}));
