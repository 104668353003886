import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'removeUnderscores',
	standalone: true,
})
export class RemoveUnderscoresPipe implements PipeTransform {
	transform(value: string): string {
		if (!value) return value;
		if (!value.includes('_')) return value;
		return value.replace(/_/g, ' ');
	}
}
