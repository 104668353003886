import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { NotificationsService } from 'app/core/notifications/notifications.service';
import { AppNotification } from 'app/core/notifications/notifications.types';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subject, finalize, take, takeUntil } from 'rxjs';
import { ApiResponse } from 'ultramarine-shared';

@Component({
	selector: 'notifications',
	templateUrl: './notifications.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'notifications',
})
export class NotificationsComponent implements OnInit, OnDestroy {
	// ------------------------------------------------------------------------
	// @ Accessors
	// ------------------------------------------------------------------------
	@ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
	@ViewChild('notificationsPanel')
	private _notificationsPanel: TemplateRef<any>;

	// ------------------------------------------------------------------------
	// @ Public variables
	// ------------------------------------------------------------------------
	listData$: BehaviorSubject<AppNotification[]> = new BehaviorSubject<
		AppNotification[]
	>([]);
	listCount: number = 0;
	listUnreadCount: number = 0;

	// ------------------------------------------------------------------------
	// @ Private variables
	// ------------------------------------------------------------------------
	private _overlayRef: OverlayRef;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _isAllRead: boolean = false;

	/**
	 * Constructor
	 */
	constructor(
		private _logger: NGXLogger,
		private _cd: ChangeDetectorRef,
		private _notificationsService: NotificationsService,
		private _overlay: Overlay,
		private _viewContainerRef: ViewContainerRef,
	) {}

	// ------------------------------------------------------------------------
	// @ Lifecycle hooks
	// ------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to notification changes
		this._notificationsService.list$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((response: ApiResponse<AppNotification[]>) => {
				// Log
				this._logger.info('_notificationsService.list > changed:', response);

				// Load the notifications
				this.listData$.next(response.data);

				this.listCount = response.data.length;

				this.listUnreadCount = response.data.filter(
					(notification) => !notification.isRead,
				).length;

				// Mark for check
				this._cd.markForCheck();
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();

		// Dispose the overlay
		if (this._overlayRef) {
			this._overlayRef.dispose();
		}
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Open the notifications panel
	 */
	openPanel(): void {
		// Return if the notifications panel or its origin is not defined
		if (!this._notificationsPanel || !this._notificationsOrigin) {
			return;
		}

		// Create the overlay if it doesn't exist
		if (!this._overlayRef) {
			this._createOverlay();
		}

		// Attach the portal to the overlay
		this._overlayRef.attach(
			new TemplatePortal(this._notificationsPanel, this._viewContainerRef),
		);
	}

	/**
	 * Close the notifications panel
	 */
	closePanel(): void {
		this._overlayRef.detach();
	}

	/**
	 * Toggle read status of all the notifications
	 */
	toggleAllRead(): void {
		this._isAllRead = !this._isAllRead;

		// Log
		this._logger.info('toggleAllRead > called with:', this._isAllRead);

		// Mark all as read
		this._notificationsService
			.toggleAllRead(this._isAllRead)
			.pipe(
				take(1),
				finalize(() => {
					// Log
					this._logger.info('_notificationsService.toggleAllRead > completed');
				}),
			)
			.subscribe();
	}

	/**
	 * Toggle read status of the notification
	 */
	toggleRead(notification: AppNotification): void {
		// Toggle the read status
		notification.isRead = !notification.isRead;

		// Update the notification
		this._notificationsService
			.updateRecord(notification)
			.pipe(
				take(1),
				finalize(() => {
					// Log
					this._logger.info('_notificationsService.updateRecord > completed');

					// Check if all notifications are read
					this._isAllRead = this.listData$.value.every(
						(_notification) => _notification.isRead,
					);
				}),
			)
			.subscribe();
	}

	/**
	 * Delete the notification
	 */
	delete(notification: AppNotification): void {
		// Delete the notification
		this._notificationsService
			.deleteRecord(notification.id)
			.pipe(
				take(1),
				finalize(() => {
					// Log
					this._logger.info('_notificationsService.deleteRecord > completed');

					// Check if all notifications are read
					this._isAllRead = this.listData$.value.every(
						(_notification) => _notification.isRead,
					);
				}),
			)
			.subscribe();
	}

	/**
	 * Track by function for ngFor loops
	 *
	 * @param index
	 * @param item
	 */
	trackByFn(index: number, item: any): any {
		return item.id || index;
	}

	// ------------------------------------------------------------------------
	// @ Private methods
	// ------------------------------------------------------------------------

	/**
	 * Create the overlay
	 */
	private _createOverlay(): void {
		// Create the overlay
		this._overlayRef = this._overlay.create({
			hasBackdrop: true,
			backdropClass: 'fuse-backdrop-on-mobile',
			scrollStrategy: this._overlay.scrollStrategies.block(),
			positionStrategy: this._overlay
				.position()
				.flexibleConnectedTo(
					this._notificationsOrigin._elementRef.nativeElement,
				)
				.withLockedPosition(true)
				.withPush(true)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
					},
					{
						originX: 'start',
						originY: 'top',
						overlayX: 'start',
						overlayY: 'bottom',
					},
					{
						originX: 'end',
						originY: 'bottom',
						overlayX: 'end',
						overlayY: 'top',
					},
					{
						originX: 'end',
						originY: 'top',
						overlayX: 'end',
						overlayY: 'bottom',
					},
				]),
		});

		// Detach the overlay from the portal on backdrop click
		this._overlayRef.backdropClick().subscribe(() => {
			this._overlayRef.detach();
		});
	}
}
