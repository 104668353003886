import { Component, OnInit, OnDestroy, inject, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBar,
	MatSnackBarModule,
	MatSnackBarRef,
} from '@angular/material/snack-bar';
import { FuseAlertComponent, FuseAlertModule } from '@fuse/components/alert';
import { Subject } from 'rxjs';

@Component({
	selector: 'success-alert',
	standalone: true,
	imports: [MatSnackBarModule, FuseAlertModule, MatIconModule],
	templateUrl: './success-alert.component.html',
	styleUrls: ['./success-alert.component.scss'],
})
export class SuccessAlertComponent implements OnInit, OnDestroy {
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _snackBarRef: MatSnackBarRef<any>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
	) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
