"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleMaintenanceRecordCategoryEnum = void 0;
var VehicleMaintenanceRecordCategoryEnum;
(function (VehicleMaintenanceRecordCategoryEnum) {
    VehicleMaintenanceRecordCategoryEnum["VEHICLE"] = "vehicle";
    VehicleMaintenanceRecordCategoryEnum["LEASE_AGREEMENT"] = "lease_agreement";
    VehicleMaintenanceRecordCategoryEnum["RENTAL_AGREEMENT"] = "rental_agreement";
    VehicleMaintenanceRecordCategoryEnum["VEHICLE_DAMAGE"] = "vehicle_damage";
    VehicleMaintenanceRecordCategoryEnum["VEHICLE_ROAD_WORTHY_CHECK"] = "vehicle_road_worthy_check";
})(VehicleMaintenanceRecordCategoryEnum || (exports.VehicleMaintenanceRecordCategoryEnum = VehicleMaintenanceRecordCategoryEnum = {}));
