"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.staffStatusToColor = void 0;
const staff_status_enum_1 = require("../../staff/enums/staff-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function staffStatusToColor(status) {
    switch (status) {
        case staff_status_enum_1.StaffStatusEnum.ACTIVE:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case staff_status_enum_1.StaffStatusEnum.INACTIVE:
            return record_color_enum_1.RecordColorEnum.SLATE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.staffStatusToColor = staffStatusToColor;
