<fuse-alert class="max-w-sm" [type]="'error'" [appearance]="'outline'" class="w-full">
	<span fuseAlertTitle>{{ data.title }}</span>
	<p fuseAlertMessage>{{ data.message }}</p>

	<mat-accordion>
		<mat-expansion-panel
			class="mdc-elevation--z24"
			[expanded]="panelOpenState"
			hideToggle
			(opened)="panelOpenState = true"
			(closed)="panelOpenState = false">
			<mat-expansion-panel-header>
				<div class="flex flex-row justify-center items-center">
					<mat-panel-title class="text-xs"> Show details </mat-panel-title>
				</div>
			</mat-expansion-panel-header>

			<div class="relative">
				<div class="flex flex-col justify-center items-start">
					<p class="text-xs leading-tight">
						{{ data.error }}
					</p>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</fuse-alert>
