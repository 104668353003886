"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canPerformAction = void 0;
const set_status_schema_1 = require("../../../shared/validators/schemas/set-status.schema");
const rental_agreement_action_enum_1 = require("../../enums/rental-agreement-action.enum");
const rental_agreement_status_enum_1 = require("../../enums/rental-agreement-status.enum");
const canPerformAction = (record, action, abortEarly = false) => {
    if (!record)
        throw new Error('record is required.');
    let validationResult;
    const fixedStatuses = [
        rental_agreement_status_enum_1.RentalAgreementStatusEnum.COMPLETED,
        rental_agreement_status_enum_1.RentalAgreementStatusEnum.APPROVED,
        rental_agreement_status_enum_1.RentalAgreementStatusEnum.PENDING,
        rental_agreement_status_enum_1.RentalAgreementStatusEnum.CANCELLED,
        rental_agreement_status_enum_1.RentalAgreementStatusEnum.REJECTED,
    ];
    switch (action) {
        case rental_agreement_action_enum_1.RentalAgreementActionEnum.COMPLETE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_agreement_status_enum_1.RentalAgreementStatusEnum.COMPLETED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_agreement_action_enum_1.RentalAgreementActionEnum.APPROVE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_agreement_status_enum_1.RentalAgreementStatusEnum.APPROVED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_agreement_action_enum_1.RentalAgreementActionEnum.CANCEL:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_agreement_status_enum_1.RentalAgreementStatusEnum.CANCELLED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_agreement_action_enum_1.RentalAgreementActionEnum.PENDING:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_agreement_status_enum_1.RentalAgreementStatusEnum.PENDING, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_agreement_action_enum_1.RentalAgreementActionEnum.REJECT:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_agreement_status_enum_1.RentalAgreementStatusEnum.REJECTED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_agreement_action_enum_1.RentalAgreementActionEnum.CREATE_MAINTENANCE:
            break;
        case rental_agreement_action_enum_1.RentalAgreementActionEnum.CREATE_DAMAGE:
            break;
        default:
            throw new Error(`Unknown action: ${action}`);
    }
    if (validationResult.error) {
        return {
            isValid: false,
            errors: validationResult.error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.canPerformAction = canPerformAction;
