import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { fuseAnimations } from '../../../../../@fuse/animations';

@Component({
	selector: 'div[ultra-menu-item-expandable]',
	templateUrl: 'ultra-menu-item-expandable.component.html',
	standalone: true,
	imports: [
		MatIconModule,
		NgIf,
		MatTooltipModule,
		TranslocoModule,
		NgClass,
		NgIf,
	],
	animations: fuseAnimations,
})
export class UltraMenuItemExpandableComponent {
	@Input() disabled: boolean | null = null;
	@Input() svgIcon?: string;
	@Input() type?: string;
	@Input() content?: string;
	@Input() iconClass?: string;
	public showSection: boolean;

	onShowSection() {
		this.showSection = !this.showSection;
	}
}
