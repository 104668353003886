import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TimeMachineService {
	public today = new Date();
	public resetControls: BehaviorSubject<null> = new BehaviorSubject(null);
	public isEditMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public timeMachineDate: BehaviorSubject<Date> = new BehaviorSubject(
		new Date(),
	);
	public isPresent$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	get isPresent() {
		return this.isPresent$.asObservable();
	}

	resetTimeMachine() {
		this.timeMachineDate.next(this.today);
		this.resetControls.next(null);
		this.handlePresentState(this.timeMachineDate.value, this.today);
	}

	public handlePresentState(date: Date, today: Date) {
		if (date.getDate() === today.getDate()) {
			this.isPresent$.next(true);
		} else {
			this.isPresent$.next(false);
		}
	}
}
