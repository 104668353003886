"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    vin;
    licensePlate;
    status;
    statusReason;
    recordCategory;
    registeredAt;
    deregisteredAt;
    makeId;
    modelId;
    year;
    series;
    trim;
    manufacturedAt;
    exteriorColorId;
    interiorColorId;
    bodyType;
    type;
    steeringPosition;
    doors;
    transmissionType;
    driveTrainType;
    odometerUnit;
    odometerValue;
    primaryPowerPlantType;
    secondaryPowerPlantType;
    engineSizeUnit;
    engineSize;
    engineOutput;
    engineTurbine;
    averageEmissionsCO2;
    emissionsUnitCO2;
    averageFuelConsumption;
    fuelConsumptionUnit;
    fuelTankCapacity;
    fuelTankCapacityUnit;
    averageElectricConsumption;
    electricBatteryCapacity;
    electricBatteryVoltage;
    electricBatteryType;
    electricMotorOutput;
    electricMotorRangeFrom;
    electricMotorRangeTo;
    allocation;
    locationId;
    categoryId;
    valuationId;
    notes;
    tags;
    avatarFile;
    files;
}
exports.VehicleRecordDto = VehicleRecordDto;
