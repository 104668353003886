"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformInt = void 0;
const class_transformer_1 = require("class-transformer");
function TransformInt({ toClassOnly = true } = {}) {
    return (0, class_transformer_1.Transform)(({ value }) => {
        if (Array.isArray(value)) {
            return value.map((val) => {
                if (val === null || val === '' || isNaN(Number(val))) {
                    return null;
                }
                return parseInt(val, 10);
            });
        }
        else {
            if (value === null || value === '' || isNaN(Number(value))) {
                return null;
            }
            return parseInt(value, 10);
        }
    }, { toClassOnly });
}
exports.TransformInt = TransformInt;
