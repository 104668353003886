"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCurrencyAmount = void 0;
function toCurrencyAmount(amount, currencyIsoCode, locale, decimalPlaces) {
    locale = locale || 'en-UK';
    amount = amount || 0;
    decimalPlaces = decimalPlaces || 2;
    return currencyIsoCode
        ? new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyIsoCode,
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        }).format(amount)
        : new Intl.NumberFormat(locale, {
            style: 'decimal',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        }).format(amount);
}
exports.toCurrencyAmount = toCurrencyAmount;
