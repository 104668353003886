import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FuseConfigModule } from '@fuse/services/config';
import { LayoutComponent } from 'app/layout/layout.component';
import { DenseLayoutModule } from 'app/layout/layouts/vertical/dense/dense.module';
import { SharedModule } from 'app/shared/shared.module';

import { EmptyLayoutModule } from './layouts/empty/empty.module';
import { ThinLayoutModule } from './layouts/vertical/thin/thin.module';

const layoutModules = [EmptyLayoutModule, DenseLayoutModule, ThinLayoutModule];

@NgModule({
	declarations: [LayoutComponent],
	imports: [
		//this inmported here so snackbars can work all over the app
		MatSnackBarModule,
		SharedModule,
		FuseConfigModule,
		...layoutModules,
	],
	exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule {}
