"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseAgreementRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class LeaseAgreementRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.LEASE_AGREEMENT;
    }
    documentNumber;
    qrCode;
    status;
    statusReason;
    recordCategory;
    startsAt;
    endsAt;
    option;
    totalLossPenaltyPercentage;
    buyOutTotalNet;
    buyOutTotalTax;
    buyOutTotalGross;
    odometerUnit;
    yearlyOdometerLimit;
    yearlyOdometerExcessPerUnit;
    tireReplacementFrequency;
    vehicleId;
    customerId;
    leaseOrderId;
    checkOuts;
    checkIns;
    pricingItems;
    drivers;
    tags;
    avatarFile;
    files;
}
exports.LeaseAgreementRecordDto = LeaseAgreementRecordDto;
