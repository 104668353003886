"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleSearchDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_search_dto_1 = require("../../shared/dtos/base-search.dto");
class VehicleSearchDto extends base_search_dto_1.BaseSearchDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    vin;
    status;
    recordCategory;
    licensePlate;
    makeFull;
    seriesTrimFull;
    type;
    transmissionType;
    exteriorColorName;
    interiorColorName;
    categoryName;
    locationCode;
    avatarFileUrl;
    displayValue() {
        return `${this.licensePlate}${this.makeFull ? ` - ${this.makeFull}` : ''}`;
    }
    shortDisplayValue() {
        return `${this.licensePlate}`;
    }
    extraDisplayValue() {
        return null;
    }
    pillDisplayValue() {
        return `${this.status}`;
    }
}
exports.VehicleSearchDto = VehicleSearchDto;
