"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleReminderEventEnum = void 0;
var VehicleReminderEventEnum;
(function (VehicleReminderEventEnum) {
    VehicleReminderEventEnum["ROAD_TAX"] = "road_tax";
    VehicleReminderEventEnum["INSURANCE"] = "insurance";
    VehicleReminderEventEnum["MAINTENANCE"] = "maintenance";
    VehicleReminderEventEnum["OTHER"] = "other";
})(VehicleReminderEventEnum || (exports.VehicleReminderEventEnum = VehicleReminderEventEnum = {}));
