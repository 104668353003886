import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { BooleanFilterEnum } from 'ultramarine-shared';

@Injectable({
	providedIn: 'root',
})
export class QueryParamsStateService {
	constructor(private router: Router) {}

	public navigateToModuleWithQueryParams(
		moduleToNavigate: string,
		queryParamsToNavigate: string,
	) {
		this.router.navigate(['admin', moduleToNavigate], {
			queryParams: this._constructParamsFromString(queryParamsToNavigate),
		});
	}

	public updateQueryParams(filters: any): void {
		const queryParamsString = this._constructQueryParamsString(filters);
		// Get the current URL
		let currentUrl = window.location.href;
		// Remove existing query parameters
		const indexOfQueryParams = currentUrl.indexOf('?');
		if (indexOfQueryParams !== -1) {
			currentUrl = currentUrl.substring(0, indexOfQueryParams);
		}
		// Append the new query parameters string
		currentUrl += '?' + queryParamsString;
		// Update the URL without reloading the page
		window.history.replaceState({}, '', currentUrl);
	}

	private _constructQueryParamsString(filters: any): string {
		// Iterate through each key in filters
		for (const key in filters) {
			if (filters.hasOwnProperty(key)) {
				// Check if the value is null or undefined
				if (filters[key] === null || filters[key] === undefined) {
					// Replace null or undefined with an empty string
					filters[key] = '';
				}
			}
		}
		let queryString!: string;
		queryString = new URLSearchParams(filters).toString();
		return queryString;
	}

	private _constructParamsFromString(str: string): Params {
		const params = {};
		if (str) {
			const pairs = str.split('&');
			pairs.forEach((pair) => {
				const keyValue = pair.split('=');
				const key = keyValue[0];
				params[key] = keyValue[1];
			});
		}
		return params;
	}

	public castStringToBoolean(flag: string): boolean {
		if (!flag) {
			return;
		}
		if (flag === 'true') {
			return true;
		}
		if (flag === 'false') {
			return false;
		}
		return false;
	}

	public castDeleted(input: string): BooleanFilterEnum | null {
		if (!input) {
			return;
		}
		let returnString: string;
		if (Object.values(BooleanFilterEnum).includes(input as BooleanFilterEnum)) {
			returnString = input;
		} else {
			returnString = null;
		}
		return returnString as BooleanFilterEnum | null;
	}

	public castStringToNumbersArray(input: string): number[] {
		if (!input) {
			return;
		}
		const stringArray = input.split(',');
		const numbersArray: number[] = [];
		stringArray.forEach((item) => {
			numbersArray.push(Number(item));
		});
		return numbersArray;
	}

	public castStatuses<T>(input: string, acceptedValues: string[]): T[] {
		if (!input) {
			return;
		}
		const statusesArray: string[] = input.split(',');
		// check if items in statusesArray is in the acceptedValues
		const filteredArray: string[] = statusesArray.filter((status) =>
			acceptedValues.includes(status.trim()),
		);
		return filteredArray as T[];
	}
}
