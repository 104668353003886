import { AvailableWidget } from 'app/modules/admin/dashboard/components/widget-controls/widget-controls.types';
import { Dashboard } from 'app/modules/admin/dashboard/dashboard.types';

export const availableWidgets: AvailableWidget[] = [
	//compare-value-fleet
	{
		label: 'Compare Value',
		key: 'compare-value-fleet',
		shortLabel: 'Compare Value',
		availableDatasets: [
			{
				label: 'Available Cars',
				key: 'available-cars',
				apiEndpoint:
					'api/widgets/value/compare/fleet/available-cars/properties',
			},
		],
		availableSizes: [
			{
				label: 'Small',
				grid: {
					desktop: { cols: 4, rows: 1 },
					mobile: { cols: 4, rows: 2 },
				},
			},
			{
				label: 'Medium',
				grid: {
					desktop: { cols: 8, rows: 1 },
					mobile: { cols: 4, rows: 2 },
				},
			},
		],
	},
	//range-fleet
	{
		label: 'Range',
		key: 'range-fleet',
		shortLabel: 'Range',
		availableDatasets: [
			{
				label: 'Customers',
				key: 'customers',
				apiEndpoint: 'api/widgets/range/fleet/customers/properties',
			},
			{
				label: 'RPU',
				key: 'rental-percentage-utilization',
				apiEndpoint:
					'api/widgets/fleet/range/rental-percentage-utilization/properties',
			},
			{
				label: 'ARD',
				key: 'average-rental-duration',
				apiEndpoint:
					'api/widgets/fleet/range/average-rental-duration/properties',
			},
		],
		availableSizes: [
			{
				label: 'Small',
				grid: {
					desktop: { cols: 4, rows: 2 },
					mobile: { cols: 4, rows: 2 },
				},
			},
			{
				label: 'Medium',
				grid: {
					desktop: { cols: 8, rows: 2 },
					mobile: { cols: 4, rows: 2 },
				},
			},
		],
	},
	//value-fleet
	{
		label: 'Value',
		key: 'value-fleet',
		shortLabel: 'Value',
		availableDatasets: [
			{
				label: 'Staff Utilization',
				key: 'staff-utilization',
				apiEndpoint: 'api/widgets/fleet/staff-utilization/properties',
			},
			{
				label: 'RPU',
				key: 'rpu',
				apiEndpoint:
					'api/widgets/fleet/value/rental-percentage-utilization/properties',
			},
			{
				label: 'ARD',
				key: 'ard',
				apiEndpoint:
					'api/widgets/fleet/value/average-rental-duration/properties',
			},
		],
		availableSizes: [
			{
				label: 'Small',
				grid: {
					desktop: { cols: 2, rows: 1 },
					mobile: { cols: 4, rows: 1 },
				},
			},
		],
	},
	//compare-range-fleet
	{
		label: 'Compare Range',
		key: 'compare-range-fleet',
		shortLabel: 'Compare Range',
		availableDatasets: [
			{
				label: 'Damaged Cars',
				key: 'damaged-cars',
				apiEndpoint: 'api/widgets/range/compare/fleet/damaged-cars/properties',
			},
		],
		availableSizes: [
			{
				label: 'Large',
				grid: {
					desktop: { cols: 8, rows: 2 },
					mobile: { cols: 4, rows: 2 },
				},
			},
		],
	},
];

export const dashboards: Dashboard[] = [
	{
		label: 'new dashboard',
		key: 'new-dasbhoard',
		widgets: [],
	},
	// {
	//     label: 'Work',
	//     key: 'work',
	//     widgets: [
	// {
	//     widgetKey: 'compare-value-fleet',
	//     widgetId: 'q4fefv',
	//     datasetKey: 'available-cars',
	//     apiEndpoint:'api/widgets/value/compare/fleet/available-cars/properties',
	//     theme: 'green',
	//     grid: {
	//         desktop: { cols: 4, rows: 1 },
	//         mobile: { cols: 4, rows: 2 },
	//     },
	// },
	// {
	//     widgetKey: 'compare-value-fleet',
	//     widgetId: 'q4fefv',
	//     datasetKey: 'available-cars',
	//     apiEndpoint:'api/widgets/value/compare/fleet/available-cars/properties',
	//     theme: 'green',
	//     grid: {
	//         desktop: { cols: 4, rows: 1 },
	//         mobile: { cols: 4, rows: 2 },
	//     },
	// },
	// {
	//     widgetKey: 'value-fleet',
	//     widgetId: 'asfd23',
	//     datasetKey: 'staff-utilization',
	//     theme: 'amber',
	//     apiEndpoint: 'api/widgets/fleet/value/rental-percentage-utilization/properties',
	//     grid: {
	//         desktop: { cols: 2, rows: 1 },
	//         mobile: { cols: 2, rows: 1 },
	//     },
	// },
	// {
	//     widgetKey: 'value-fleet',
	//     widgetId: 'asfd043',
	//     datasetKey: 'staff-utilization',
	//     theme: 'amber',
	//     apiEndpoint: 'api/widgets/fleet/value/rental-percentage-utilization/properties',
	//     grid: {
	//         desktop: { cols: 2, rows: 1 },
	//         mobile: { cols: 2, rows: 1 },
	//     },
	// },
	// {
	//     widgetKey: 'value-fleet',
	//     widgetId: 'asfed043',
	//     datasetKey: 'staff-utilization',
	//     theme: 'amber',
	//     apiEndpoint: 'api/widgets/fleet/value/rental-percentage-utilization/properties',
	//     grid: {
	//         desktop: { cols: 2, rows: 1 },
	//         mobile: { cols: 2, rows: 1 },
	//     },
	// },
	// {
	//     widgetKey: 'value-fleet',
	//     widgetId: 'asfed2043',
	//     datasetKey: 'staff-utilization',
	//     theme: 'amber',
	//     apiEndpoint: 'api/widgets/fleet/value/rental-percentage-utilization/properties',
	//     grid: {
	//         desktop: { cols: 2, rows: 1 },
	//         mobile: { cols: 2, rows: 1 },
	//     },
	// },
	// {
	//     widgetKey: 'value-fleet',
	//     widgetId: 'asfed0423',
	//     datasetKey: 'staff-utilization',
	//     theme: 'amber',
	//     apiEndpoint: 'api/widgets/fleet/value/rental-percentage-utilization/properties',
	//     grid: {
	//         desktop: { cols: 2, rows: 1 },
	//         mobile: { cols: 2, rows: 1 },
	//     },
	// },
	// {
	//     widgetKey: 'value-fleet',
	//     widgetId: 'eqewaf',
	//     datasetKey: 'staff-utilization',
	//     theme: 'amber',
	//     apiEndpoint: 'api/widgets/fleet/staff-utilization/properties',
	//     grid: { cols: 2, rows: 1 },
	// },
	// {
	//     widgetKey: 'range-fleet',
	//     widgetId: '1342sd',
	//     datasetKey: 'customers',
	//     apiEndpoint: 'api/widgets/range/fleet/customers/properties',
	//     theme: 'green',
	//     grid: { cols: 4, rows: 2, x: 4, y: 0 },
	// },
	// {
	//     widgetKey: 'compare-range-fleet',
	//     widgetId: 'q34sdfv',
	//     datasetKey: 'damaged-cars',
	//     apiEndpoint:
	//         'api/widgets/range/compare/fleet/damaged-cars/properties',
	//     theme: 'green',
	//     grid: { cols: 8, rows: 2, x: 0, y: 4 },
	// },
	// {
	//     widgetKey: 'summary-fleet',
	//     widgetId: 'okowqef',
	//     datasetKey: 'damaged-cars',
	//     apiEndpoint:
	//         'api/widgets/range/compare/fleet/damaged-cars/properties',
	//     theme: 'green',
	//     grid: { cols: 8, rows: 4, x: 0, y: 4 },
	// },
	// ],
	// },
];
