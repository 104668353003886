import { Injectable } from '@angular/core';
import { PureAbility } from '@casl/ability';
import { UserActionEnum } from 'ultramarine-shared';

@Injectable({
	providedIn: 'root',
})
export class AuthzService {
	action: UserActionEnum;
	constructor(private _pureAbility: PureAbility) {
		this.action = this._pureAbility.rules[0].action as UserActionEnum;
	}

	allowDelete(): boolean {
		if (
			this.action === UserActionEnum.MANAGE ||
			this.action === UserActionEnum.DELETE
		) {
			return true;
		}
		return false;
	}

	allowCreate(): boolean {
		if (
			this.action === UserActionEnum.MANAGE ||
			this.action === UserActionEnum.DELETE ||
			this.action === UserActionEnum.CREATE
		) {
			return true;
		}
		return false;
	}

	allowEdit(): boolean {
		if (
			this.action === UserActionEnum.MANAGE ||
			this.action === UserActionEnum.DELETE ||
			this.action === UserActionEnum.CREATE ||
			this.action === UserActionEnum.UPDATE
		) {
			return true;
		} else {
			return false;
		}
	}
}
