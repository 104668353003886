"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapSubjectAbilitiesToRules = void 0;
function mapSubjectAbilitiesToRules(subjectAbilities) {
    return subjectAbilities.flatMap((subjectAbility) => subjectAbility.abilities.map((ability) => ({
        subject: subjectAbility.subject,
        action: ability.action,
        inverted: !ability.allow,
    })));
}
exports.mapSubjectAbilitiesToRules = mapSubjectAbilitiesToRules;
