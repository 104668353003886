"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchResultsDto = void 0;
class SearchResultsDto {
    locations;
    customers;
    suppliers;
    users;
    staff;
    tags;
    vehicles;
    rentalReservations;
    rentalAgreements;
}
exports.SearchResultsDto = SearchResultsDto;
