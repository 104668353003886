import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';

import { AuthService } from './core/auth/auth.service';
import { TimeMachineService } from './shared/components/time-machine/time-machine.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: fuseAnimations,
})
export class AppComponent implements OnInit {
	isPresent = this._timeMachineService.isPresent;
	constructor(
		private _logger: NGXLogger,
		private _cookieService: CookieService,
		private _router: Router,
		private _authService: AuthService,
		private _timeMachineService: TimeMachineService,
	) {}

	public ngOnInit(): void {
		// Checks whether the intended route include admin to exclude no auth routes and landing page routes from the check
		// if it does, it checks whether the rememberMe cookie is set to false.
		if (location.pathname.includes('admin')) {
			if (
				this._cookieService.get('rememberMe') === 'false' ||
				this._cookieService.get('rememberMe') === undefined ||
				this._cookieService.get('rememberMe') === null ||
				this._cookieService.get('rememberMe') === ''
			) {
				this._authService.signOut().subscribe({
					next: (res) => {
						this._logger.info('AuthGuard > signOut > next', res);

						this._router.navigate(['/authentication/sign-in']);
					},
				});
			}
		}
	}
}
