"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDriveTrainTypeEnum = void 0;
var VehicleDriveTrainTypeEnum;
(function (VehicleDriveTrainTypeEnum) {
    VehicleDriveTrainTypeEnum["FRONT_WHEEL_DRIVE"] = "front_wheel_drive";
    VehicleDriveTrainTypeEnum["FOUR_BY_FOUR"] = "four_by_four";
    VehicleDriveTrainTypeEnum["REAR_WHEEL_DRIVE"] = "rear_wheel_drive";
    VehicleDriveTrainTypeEnum["ALL_WHEEL_DRIVE"] = "all_wheel_drive";
    VehicleDriveTrainTypeEnum["NON_STANDARDIZED"] = "non_standardized";
})(VehicleDriveTrainTypeEnum || (exports.VehicleDriveTrainTypeEnum = VehicleDriveTrainTypeEnum = {}));
