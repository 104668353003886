"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadTaxListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleRoadTaxListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_ROAD_TAX;
    }
    code;
    externalReference;
    startsAt;
    endsAt;
    status;
    recordCategory;
    vehicle;
    totalNet;
    totalTax;
    totalGross;
    tags;
    avatarFileUrl;
    mobileTitle = () => {
        return `${this.code}` + ` / ${this.startsAt}-${this.endsAt}`;
    };
    mobileSubtitle = () => {
        const vehicle = this.vehicle?.licensePlate
            ? this.vehicle.licensePlate
            : this.vehicle?.vin
                ? this.vehicle.vin
                : '';
        return (`${vehicle}` + `${this.totalGross ? ' / ' + this.totalGross + ` €` : ''}`);
    };
}
exports.VehicleRoadTaxListDto = VehicleRoadTaxListDto;
