"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidCustomerContact = void 0;
const customers_1 = require("../../../customers");
const update_company_customer_contact_schema_1 = require("../schemas/update-company-customer-contact.schema");
const update_individual_customer_contact_schema_1 = require("../schemas/update-individual-customer-contact.schema");
const isValidCustomerContact = (contact, customerRecordCategory, legalAge, abortEarly = false) => {
    if (!contact)
        throw new Error('contact is required');
    switch (customerRecordCategory) {
        case customers_1.CustomerRecordCategoryEnum.INDIVIDUAL: {
            if (legalAge === null) {
                throw new Error(`legalAge is required for customer with type ${customerRecordCategory}`);
            }
            const { error } = (0, update_individual_customer_contact_schema_1.updateIndividualCustomerContactSchema)(legalAge).validate(contact, {
                abortEarly,
            });
            if (error) {
                return {
                    isValid: false,
                    errors: error.details?.map((e) => e.message),
                };
            }
            break;
        }
        case customers_1.CustomerRecordCategoryEnum.COMPANY: {
            const { error } = (0, update_company_customer_contact_schema_1.updateCompanyCustomerContactSchema)().validate(contact, {
                abortEarly,
            });
            if (error) {
                return {
                    isValid: false,
                    errors: error.details?.map((e) => e.message),
                };
            }
            break;
        }
    }
    return { isValid: true };
};
exports.isValidCustomerContact = isValidCustomerContact;
