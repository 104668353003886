"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuppliersFilters = void 0;
const api_request_filters_1 = require("../../shared/types/api-request-filters");
class SuppliersFilters extends api_request_filters_1.ApiRequestFilters {
    countries;
    statuses;
    recordCategories;
    draft;
    deleted;
    tags;
}
exports.SuppliersFilters = SuppliersFilters;
