"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehiclesStatsDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_stats_dto_1 = require("../../shared/dtos/base-stats.dto");
class VehiclesStatsDto extends base_stats_dto_1.BaseStatsDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    inPark;
    outOfService;
    sold;
    scrapped;
    reserved;
    rented;
    ordered;
    leased;
    inMaintenance;
}
exports.VehiclesStatsDto = VehiclesStatsDto;
