"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unitPricingToTotalTax = void 0;
function unitPricingToTotalTax(pricingItem) {
    if (!pricingItem) {
        return 0;
    }
    return (pricingItem.units *
        (pricingItem.unitPrice * (pricingItem.unitTaxRate / 100)));
}
exports.unitPricingToTotalTax = unitPricingToTotalTax;
