<fuse-alert [type]="'success'" [appearance]="'outline'">


	<span fuseAlertTitle >{{data.title}}</span>
	<p fuseAlertMessage >{{data.message}}</p>



</fuse-alert>






