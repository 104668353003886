"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleMaintenanceRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleMaintenanceRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_MAINTENANCE;
    }
    code;
    externalReference;
    recordCategory;
    status;
    statusReason;
    scheduledAt;
    estimatedCost;
    serviceCenter;
    description;
    vehicleId;
    supplierId;
    rentalAgreementId;
    leaseAgreementId;
    vehicleRoadWorthyCheckId;
    vehicleDamageId;
    checkOuts;
    checkIns;
    maintenanceItems;
    tags;
    avatarFile;
    files;
}
exports.VehicleMaintenanceRecordDto = VehicleMaintenanceRecordDto;
