"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleModelRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleModelRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    name;
    externalReference;
    makeId;
    mobileTitle = () => {
        return `${this.name}`;
    };
    mobileSubtitle = () => {
        return '';
    };
}
exports.VehicleModelRecordDto = VehicleModelRecordDto;
