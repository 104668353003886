"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListColumnDto = void 0;
class ListColumnDto {
    displayOrder;
    isVisibleInPicker;
    isSelectableInPicker;
    isVisible;
    isSelected;
    name;
    orderByColumnName;
    header;
    headerClass;
    cellClass;
    breakpointsExclude;
}
exports.ListColumnDto = ListColumnDto;
