"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehiclePowerPlantTypeEnum = void 0;
var VehiclePowerPlantTypeEnum;
(function (VehiclePowerPlantTypeEnum) {
    VehiclePowerPlantTypeEnum["UNLEADED"] = "unleaded";
    VehiclePowerPlantTypeEnum["DIESEL"] = "diesel";
    VehiclePowerPlantTypeEnum["HYDROGEN"] = "hydrogen";
    VehiclePowerPlantTypeEnum["ELECTRIC"] = "electric";
    VehiclePowerPlantTypeEnum["ETHANOL"] = "ethanol";
    VehiclePowerPlantTypeEnum["NEAT_ETHANOL"] = "neat_ethanol";
    VehiclePowerPlantTypeEnum["METHANOL"] = "methanol";
    VehiclePowerPlantTypeEnum["NEAT_METHANOL"] = "neat_methanol";
    VehiclePowerPlantTypeEnum["CNG"] = "cng";
    VehiclePowerPlantTypeEnum["LNG"] = "lng";
    VehiclePowerPlantTypeEnum["LPG"] = "lpg";
    VehiclePowerPlantTypeEnum["NATURAL_GAS"] = "natural_gas";
})(VehiclePowerPlantTypeEnum || (exports.VehiclePowerPlantTypeEnum = VehiclePowerPlantTypeEnum = {}));
