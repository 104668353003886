"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FuelLevelEnum = void 0;
var FuelLevelEnum;
(function (FuelLevelEnum) {
    FuelLevelEnum["EMPTY"] = "empty";
    FuelLevelEnum["ONE_EIGHTH"] = "one_eighth";
    FuelLevelEnum["TWO_EIGHTHS"] = "two_eighths";
    FuelLevelEnum["THREE_EIGHTHS"] = "three_eighths";
    FuelLevelEnum["HALF"] = "half";
    FuelLevelEnum["FIVE_EIGHTHS"] = "five_eighths";
    FuelLevelEnum["SIX_EIGHTHS"] = "six_eighths";
    FuelLevelEnum["SEVEN_EIGHTHS"] = "seven_eighths";
    FuelLevelEnum["FULL"] = "full";
    FuelLevelEnum["UNKNOWN"] = "unknown";
})(FuelLevelEnum || (exports.FuelLevelEnum = FuelLevelEnum = {}));
