"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRoleEnum = void 0;
var UserRoleEnum;
(function (UserRoleEnum) {
    UserRoleEnum["SYSTEM"] = "system";
    UserRoleEnum["ADMIN"] = "admin";
    UserRoleEnum["BASIC"] = "basic";
    UserRoleEnum["STAKEHOLDER"] = "stakeholder";
})(UserRoleEnum || (exports.UserRoleEnum = UserRoleEnum = {}));
