"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unitPricingToTotalNet = void 0;
function unitPricingToTotalNet(pricingItem) {
    if (!pricingItem) {
        return 0;
    }
    return pricingItem.units * pricingItem.unitPrice;
}
exports.unitPricingToTotalNet = unitPricingToTotalNet;
