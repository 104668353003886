"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamagesFilters = void 0;
const transform_date_decorator_1 = require("../../shared/decorators/transform-date.decorator");
const api_request_filters_1 = require("../../shared/types/api-request-filters");
class VehicleDamagesFilters extends api_request_filters_1.ApiRequestFilters {
    faults;
    severities;
    reportedAtFrom;
    reportedAtTo;
    estimatedMaintenanceCostFrom;
    estimatedMaintenanceCostTo;
    vehicles;
    leaseAgreements;
    rentalAgreements;
    customers;
    statuses;
    recordCategories;
    draft;
    deleted;
    tags;
}
exports.VehicleDamagesFilters = VehicleDamagesFilters;
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], VehicleDamagesFilters.prototype, "reportedAtFrom", void 0);
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], VehicleDamagesFilters.prototype, "reportedAtTo", void 0);
