import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard
	implements CanDeactivate<CanComponentDeactivate>
{
	constructor(private _logger: NGXLogger) {}

	canDeactivate(
		component: CanComponentDeactivate,
	): Observable<boolean> | Promise<boolean> | boolean {
		// Check if the component we're trying to leave implements CanComponentDeactivate interface
		if (component.canDeactivate) {
			this._logger.info('component:canDeactivate > called');

			// Call the component's canDeactivate method
			return component.canDeactivate();
		} else {
			this._logger.info('component:canDeactivate > returning default value');

			// If the component doesn't implement the CanComponentDeactivate interface, allow the deactivation
			return true;
		}
	}
}
