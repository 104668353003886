"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerActionEnum = void 0;
var CustomerActionEnum;
(function (CustomerActionEnum) {
    CustomerActionEnum["ACTIVATE"] = "activate";
    CustomerActionEnum["CLOSE"] = "close";
    CustomerActionEnum["PUT_ON_HOLD"] = "put_on_hold";
    CustomerActionEnum["CREATE_RESERVATION"] = "create_reservation";
    CustomerActionEnum["CREATE_RENTAL_AGREEMENT"] = "create_rental_agreement";
    CustomerActionEnum["CREATE_LEASE_AGREEMENT"] = "create_lease_agreement";
})(CustomerActionEnum || (exports.CustomerActionEnum = CustomerActionEnum = {}));
