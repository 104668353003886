"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordTabDto = void 0;
class RecordTabDto {
    displayOrder;
    isSelectable;
    isVisible;
    isSelected;
    name;
    description;
    supportsTimeMachine;
}
exports.RecordTabDto = RecordTabDto;
