"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountRecordCategoryEnum = void 0;
var AccountRecordCategoryEnum;
(function (AccountRecordCategoryEnum) {
    AccountRecordCategoryEnum["TRIAL"] = "trial";
    AccountRecordCategoryEnum["PERPETUAL_LICENSE"] = "perpetual-license";
    AccountRecordCategoryEnum["SELF_HOSTED_LICENSE"] = "self-hosted-license";
    AccountRecordCategoryEnum["MONTHLY_SUBSCRIPTION"] = "monthly-subscription";
    AccountRecordCategoryEnum["ANNUAL_SUBSCRIPTION"] = "annual-subscription";
})(AccountRecordCategoryEnum || (exports.AccountRecordCategoryEnum = AccountRecordCategoryEnum = {}));
