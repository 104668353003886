"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehicleStatusToColor = void 0;
const vehicle_status_enum_1 = require("../../vehicles/enums/vehicle-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function vehicleStatusToColor(status) {
    switch (status) {
        case vehicle_status_enum_1.VehicleStatusEnum.IN_PARK:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case vehicle_status_enum_1.VehicleStatusEnum.OUT_OF_SERVICE:
            return record_color_enum_1.RecordColorEnum.RED;
        case vehicle_status_enum_1.VehicleStatusEnum.SCRAPPED:
            return record_color_enum_1.RecordColorEnum.SLATE;
        case vehicle_status_enum_1.VehicleStatusEnum.SOLD:
            return record_color_enum_1.RecordColorEnum.SLATE;
        case vehicle_status_enum_1.VehicleStatusEnum.RESERVED:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        case vehicle_status_enum_1.VehicleStatusEnum.ORDERED:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        case vehicle_status_enum_1.VehicleStatusEnum.LEASED:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case vehicle_status_enum_1.VehicleStatusEnum.RENTED:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case vehicle_status_enum_1.VehicleStatusEnum.IN_MAINTENANCE:
            return record_color_enum_1.RecordColorEnum.AMBER;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.vehicleStatusToColor = vehicleStatusToColor;
