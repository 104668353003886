"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class TagListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.TAG;
    }
    name;
    recordCategory;
    description;
    mobileTitle = () => {
        return `${this.name}`;
    };
    mobileSubtitle = () => {
        return `${this.description || ''}`?.trim().replace(/\s+/g, ' ');
    };
}
exports.TagListDto = TagListDto;
