"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountsStatsDto = void 0;
const base_stats_dto_1 = require("../../shared/dtos/base-stats.dto");
class AccountsStatsDto extends base_stats_dto_1.BaseStatsDto {
    active;
    overdue;
    onHold;
    suspended;
    closed;
}
exports.AccountsStatsDto = AccountsStatsDto;
