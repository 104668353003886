"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleInsuranceStatusEnum = void 0;
var VehicleInsuranceStatusEnum;
(function (VehicleInsuranceStatusEnum) {
    VehicleInsuranceStatusEnum["PENDING"] = "pending";
    VehicleInsuranceStatusEnum["ACTIVE"] = "active";
    VehicleInsuranceStatusEnum["EXPIRED"] = "expired";
    VehicleInsuranceStatusEnum["CANCELLED"] = "cancelled";
})(VehicleInsuranceStatusEnum || (exports.VehicleInsuranceStatusEnum = VehicleInsuranceStatusEnum = {}));
