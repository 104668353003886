import { Injectable } from '@angular/core';
import { PureAbility } from '@casl/ability';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import {
	ApiResponse,
	BaseApiResponse,
	createAbility,
	UserRecordDto,
} from 'ultramarine-shared';

import { FuseLoadingService } from '../../../@fuse/services/loading';
import { UltramarineApiService } from '../api/ultramarine-api.service';

@Injectable()
export class AuthService {
	// ------------------------------------------------------------------------
	// @ Private properties
	// ------------------------------------------------------------------------
	private _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(
		null,
	);
	private _user$: BehaviorSubject<UserRecordDto> = new BehaviorSubject(null);

	constructor(
		private _pureAbility: PureAbility,
		private _logger: NGXLogger,
		private _apiService: UltramarineApiService,
		private _fuseLoadingService: FuseLoadingService,
		private _cookieService: CookieService,
	) {}

	// ------------------------------------------------------------------------
	// @ Accessors
	// ------------------------------------------------------------------------

	public get isAuthenticated$(): Observable<boolean> {
		return this._isAuthenticated$.asObservable();
	}

	public get isAuthenticated(): boolean {
		return this._isAuthenticated$.value;
	}

	public get user$(): Observable<UserRecordDto> {
		return this._user$.asObservable();
	}

	public get userId(): number {
		return this._user$.value.id;
	}

	public get username(): string {
		return this._user$.value.username;
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Forgot password
	 *
	 * @param email
	 */
	forgotPassword(email: string): Observable<any> {
		return null; //this._httpClient.post('api/auth/forgot-password', email);
	}

	/**
	 * Reset password
	 *
	 * @param password
	 */
	resetPassword(password: string): Observable<any> {
		return null; //this._httpClient.post('api/auth/reset-password', password);
	}

	/**
	 * Sign in
	 *
	 */
	signIn(
		username: string,
		password: string,
		rememberMe: boolean,
	): Observable<ApiResponse<UserRecordDto>> {
		// Show the loading indicator
		this._fuseLoadingService.show();

		// Store remember me value as a cookie
		this._cookieService.set('rememberMe', rememberMe ? 'true' : 'false');

		// Log
		this._logger.info('signIn > called');

		return this._apiService
			.post<ApiResponse<UserRecordDto>>(`auth/sign-in`, {
				username: username,
				password: password,
			})
			.pipe(
				tap((response: ApiResponse<UserRecordDto>) => {
					// Log
					this._logger.info('signIn > response: ', response);

					// Check if the response contains data
					if (response.data) {
						// Set the authenticated flag to true
						this._isAuthenticated$.next(true);

						// Store the user
						this._user$.next(response.data);

						// Set a cookie to skip the validateSession call on the Auth guard
						this._cookieService.set('skipValidateSession', 'true');

						// Update the user's abilities
						this.updateAbility(response.data.userRolePermissions);
					} else {
						// Set the authenticated flag to false
						this._isAuthenticated$.next(false);

						// Set the user to null
						this._user$.next(null);
					}

					// Hide the loading indicator
					this._fuseLoadingService.hide();
				}),

				catchError((error: any) => {
					// Log
					this._logger.error('signIn > error: ', error);

					// Hide the loading indicator
					this._fuseLoadingService.hide();

					// Re-throw the error
					return throwError(
						() =>
							new Error(
								'An error occurred while fetching data from the server',
							),
					);
				}),
			);
	}

	/**
	 * Sign out
	 */
	signOut(): Observable<BaseApiResponse> {
		// Log
		this._logger.info('signOut > called');

		// Show the loading indicator
		this._fuseLoadingService.show();

		// Return the observable
		return this._apiService.post(`auth/sign-out`, {}).pipe(
			tap((response: BaseApiResponse) => {
				// Log
				this._logger.info('signOut > response: ', response);

				// Set the authenticated flag to false
				this._isAuthenticated$.next(false);

				// Set the user to null
				this._user$.next(null);

				// Hide the loading indicator
				this._fuseLoadingService.hide();
			}),

			catchError((error: any) => {
				// Log
				this._logger.error('signOut > error: ', error);

				// Hide the loading indicator
				this._fuseLoadingService.hide();

				// Re-throw the error
				return throwError(
					() =>
						new Error('An error occurred while fetching data from the server'),
				);
			}),
		);
	}

	/**
	 * Check if an HTTP only session cookie is set that is valid (i.e. not expired) and return
	 *
	 */
	public validateSession(): Observable<ApiResponse<UserRecordDto>> {
		// Log
		this._logger.info('validateSession > called');

		return this._apiService
			.get<ApiResponse<UserRecordDto>>(`auth/validate-session`)
			.pipe(
				tap((response: ApiResponse<UserRecordDto>) => {
					// Log
					this._logger.info('validateSession > response: ', response);

					if (response.data) {
						// Set the authenticated flag to true
						this._isAuthenticated$.next(true);

						// Store the user
						this._user$.next(response.data);

						// Update the user's abilities
						this.updateAbility(response.data.userRolePermissions);
					} else {
						// Set the authenticated flag to false
						this._isAuthenticated$.next(false);

						// Set the user to null
						this._user$.next(null);
					}

					// Hide the loading indicator
					this._fuseLoadingService.hide();
				}),

				catchError((error: any) => {
					// Log
					this._logger.error('validateSession > error: ', error);

					// Hide the loading indicator
					this._fuseLoadingService.hide();

					// Re-throw the error
					return throwError(
						() =>
							new Error(
								'An error occurred while fetching data from the server',
							),
					);
				}),
			);
	}

	/**
	 * Update the user's abilities based on the user's role that it receives from the api
	 */
	updateAbility(userPermissions) {
		// Log
		this._logger.info('updateAbility > called');
		this._logger.info('updateAbility > userPermissions: ', userPermissions);

		const ability = createAbility(userPermissions);

		//Log
		this._logger.info('updateAbility > ability: ', ability);

		// --->>> This is the line that is causing the error

		this._pureAbility.update(ability.rules);
	}
}
