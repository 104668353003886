"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tagCategoryToColor = void 0;
const tag_record_category_enum_1 = require("../../tags/enums/tag-record-category.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function tagCategoryToColor(category) {
    switch (category) {
        case tag_record_category_enum_1.TagRecordCategoryEnum.CUSTOMER:
        case tag_record_category_enum_1.TagRecordCategoryEnum.LOCATION:
        case tag_record_category_enum_1.TagRecordCategoryEnum.STAFF:
        case tag_record_category_enum_1.TagRecordCategoryEnum.SUPPLIER:
        case tag_record_category_enum_1.TagRecordCategoryEnum.USER:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case tag_record_category_enum_1.TagRecordCategoryEnum.SHARED:
            return record_color_enum_1.RecordColorEnum.SKY;
        case tag_record_category_enum_1.TagRecordCategoryEnum.TRANSACTION:
        case tag_record_category_enum_1.TagRecordCategoryEnum.PRICING_PROFILE:
            return record_color_enum_1.RecordColorEnum.AMBER;
        case tag_record_category_enum_1.TagRecordCategoryEnum.LEASE_ORDER:
        case tag_record_category_enum_1.TagRecordCategoryEnum.LEASE_AGREEMENT:
            return record_color_enum_1.RecordColorEnum.FUCHSIA;
        case tag_record_category_enum_1.TagRecordCategoryEnum.RENTAL_RESERVATION:
        case tag_record_category_enum_1.TagRecordCategoryEnum.RENTAL_AGREEMENT:
            return record_color_enum_1.RecordColorEnum.INDIGO;
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_DAMAGE:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_INSURANCE:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_INSURANCE_CLAIM:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_MAINTENANCE:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_PURCHASE:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_ROAD_TAX:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_ROAD_WORTHY_CHECK:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_SALE:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_SCRAP:
        case tag_record_category_enum_1.TagRecordCategoryEnum.VEHICLE_VALUATION:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.tagCategoryToColor = tagCategoryToColor;
