"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageActionEnum = void 0;
var VehicleDamageActionEnum;
(function (VehicleDamageActionEnum) {
    VehicleDamageActionEnum["APPROVE"] = "approve";
    VehicleDamageActionEnum["REJECT"] = "reject";
    VehicleDamageActionEnum["REPAIR"] = "repair";
    VehicleDamageActionEnum["IGNORE"] = "ignore";
    VehicleDamageActionEnum["PENDING"] = "make_pending";
    VehicleDamageActionEnum["ESTIMATE"] = "estimate";
    VehicleDamageActionEnum["CREATE_MAINTENANCE"] = "create_maintenance";
})(VehicleDamageActionEnum || (exports.VehicleDamageActionEnum = VehicleDamageActionEnum = {}));
