"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleDamageRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_DAMAGE;
    }
    code;
    status;
    statusReason;
    recordCategory;
    reportedAt;
    type;
    severity;
    fault;
    policeReportFiled;
    policeReportExternalReference;
    estimatedMaintenanceCost;
    notes;
    vehicleId;
    rentalAgreementId;
    leaseAgreementId;
    staffId;
    scrapId;
    thirdPartyContactId;
    thirdPartyInsuranceId;
    insuranceClaimId;
    damageItems;
    tags;
    avatarFile;
    files;
}
exports.VehicleDamageRecordDto = VehicleDamageRecordDto;
