"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class LocationRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.LOCATION;
    }
    code;
    name;
    status;
    statusReason;
    recordCategory;
    contact;
    tags;
    avatarFile;
    files;
}
exports.LocationRecordDto = LocationRecordDto;
