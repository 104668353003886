/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const COMPACT_NAVIGATION: FuseNavigationItem[] = [
	{
		id: 'home',
		title: 'Home',
		tooltip: 'Home',
		type: 'basic',
		icon: 'home',
		link: 'home',
	},
	{
		id: 'account',
		title: 'Account',
		tooltip: 'Account',
		type: 'basic',
		icon: 'business',
		link: 'account',
	},
	{
		id: 'administration',
		title: 'Administration',
		tooltip: 'Administration',
		type: 'aside',
		icon: 'all_inbox',
		children: [
			{
				id: 'administration.users',
				title: 'Users',
				type: 'basic',
				icon: 'person_pin',
				link: 'users',
			},
			{
				id: 'administration.staff',
				title: 'Staff',
				type: 'basic',
				icon: 'perm_contact_calendar',
				link: 'staff',
			},
			{
				id: 'administration.locations',
				title: 'Locations',
				type: 'basic',
				icon: 'pin_drop',
				link: 'locations',
			},
			{
				type: 'divider',
			},
			{
				id: 'administration.customers',
				title: 'Customers',
				type: 'basic',
				icon: 'groups',
				link: 'customers',
			},
			{
				id: 'administration.loyalty-programs',
				title: 'Loyalty Programs',
				type: 'basic',
				icon: 'card_giftcard',
				link: 'loyalty-programs',
				disabled: true,
			},
			{
				id: 'administration.suppliers',
				title: 'Suppliers',
				type: 'basic',
				icon: 'business_center',
				link: 'suppliers',
				disabled: true,
			},
			{
				type: 'divider',
			},
			{
				id: 'administration.tags',
				title: 'Tags',
				type: 'basic',
				icon: 'label',
				link: 'tags',
				disabled: true,
			},
			{
				id: 'administration.settings',
				title: 'Settings',
				type: 'basic',
				icon: 'settings',
				link: 'settings',
			},
		],
	},
	{
		id: 'fleet',
		title: 'Fleet',
		tooltip: 'Fleet',
		type: 'aside',
		icon: 'directions_boat',
		children: [
			{
				id: 'fleet.vehicles',
				title: 'Vehicles',
				type: 'basic',
				icon: 'directions_car',
				link: 'vehicles',
			},
			{
				type: 'divider',
			},
			{
				id: 'fleet.vehicle-road-taxes',
				title: 'Road Taxes',
				type: 'basic',
				icon: 'account_balance',
				link: 'vehicle-road-taxes',
				disabled: false,
			},
			{
				id: 'fleet.vehicle-road-worthy-checks',
				title: 'Road Worthy Checks',
				type: 'basic',
				icon: 'inventory',
				link: 'vehicle-road-worthy-checks',
				disabled: false,
			},
			{
				id: 'fleet.vehicle-insurances',
				title: 'Insurances',
				type: 'basic',
				icon: 'shield',
				link: 'vehicle-insurances',
				disabled: false,
			},
			{
				id: 'fleet.vehicle-maintenances',
				title: 'Maintenances',
				type: 'basic',
				icon: 'car_repair',
				link: 'vehicle-maintenances',
			},
			{
				id: 'fleet.vehicle-damages',
				title: 'Damages',
				type: 'basic',
				icon: 'taxi_alert',
				link: 'vehicle-damages',
				disabled: false,
			},
			{
				id: 'fleet.vehicle-damages',
				title: 'Damages',
				type: 'basic',
				icon: 'flash_on',
				link: 'vehicle-damages',
				disabled: false,
			},
			{
				type: 'divider',
			},
			{
				id: 'fleet.purchases',
				title: 'Purchases',
				type: 'basic',
				icon: 'shopping_cart',
				link: 'purchases',
				disabled: true,
			},
			{
				id: 'fleet.sales',
				title: 'Sales',
				type: 'basic',
				icon: 'storefront',
				link: 'sales',
				disabled: true,
			},
			{
				id: 'fleet.scraps',
				title: 'Scraps',
				type: 'basic',
				icon: 'delete_forever',
				link: 'scraps',
				disabled: true,
			},
			{
				id: 'fleet.valuations',
				title: 'Valuations',
				type: 'basic',
				icon: 'area_chart',
				link: 'valuations',
				disabled: true,
			},
			{
				type: 'divider',
			},
			{
				id: 'fleet.vehicle-categories',
				title: 'Categories',
				type: 'basic',
				icon: 'category',
				link: 'vehicle-categories',
				disabled: true,
			},
			{
				id: 'fleet.vehicle-makes-models',
				title: 'Makes & Models',
				type: 'basic',
				icon: 'assistant',
				link: 'vehicle-makes-models',
				disabled: true,
			},
			{
				id: 'fleet.vehicle-colors',
				title: 'Colors',
				type: 'basic',
				icon: 'palette',
				link: 'vehicle-colors',
				disabled: true,
			},
		],
	},
	{
		id: 'operations',
		title: 'Operations',
		tooltip: 'Operations',
		type: 'aside',
		icon: 'work_outline',
		children: [
			{
				id: 'operations.rental-reservations',
				title: 'Rental Reservations',
				type: 'basic',
				icon: 'hail',
				link: 'rental-reservations',
			},
			{
				id: 'operations.rental-agreements',
				title: 'Rental Agreements',
				type: 'basic',
				icon: 'car_rental',
				link: 'rental-agreements',
			},
			{
				type: 'divider',
			},
			{
				id: 'operations.lease-orders',
				title: 'Lease Orders',
				type: 'basic',
				icon: 'content_paste',
				link: 'lease-orders',
				disabled: true,
			},
			{
				id: 'operations.lease-agreements',
				title: 'Lease Agreements',
				type: 'basic',
				icon: 'receipt_long',
				link: 'lease-agreements',
				disabled: true,
			},
			{
				type: 'divider',
			},
			{
				id: 'operations.pricing-profiles',
				title: 'Pricing Profiles',
				type: 'basic',
				icon: 'price_change',
				link: 'pricing-profiles',
			},
			{
				id: 'operations.transactions',
				title: 'Transactions',
				type: 'basic',
				icon: 'monetization_on',
				link: 'transactions',
				disabled: true,
			},
		],
	},
	{
		id: 'integrations',
		title: 'Integrations',
		tooltip: 'Integrations',
		type: 'aside',
		icon: 'settings_ethernet',
		children: [
			{
				id: 'integrations.api-keys',
				title: 'API Keys',
				type: 'basic',
				icon: 'vpn_key',
				link: 'api-keys',
				disabled: true,
			},
			{
				id: 'integrations.webhooks',
				title: 'Webhooks',
				type: 'basic',
				icon: 'cast_connected',
				link: 'webhooks',
				disabled: true,
			},
			{
				id: 'integrations.hub',
				title: 'Integrations Hub',
				type: 'basic',
				icon: 'settings_input_hdmi',
				link: 'integrations-hub',
				disabled: true,
			},
			{
				type: 'divider',
			},
			{
				id: 'integrations.data-import',
				title: 'Data Import',
				type: 'basic',
				icon: 'cloud_upload',
				link: 'integrations-data-import',
				disabled: true,
			},
			{
				id: 'integrations.data-export',
				title: 'Data Export',
				type: 'basic',
				icon: 'cloud_download',
				link: 'integrations-data-export',
				disabled: true,
			},
		],
	},
	{
		id: 'reports',
		title: 'Reports',
		tooltip: 'Reports',
		type: 'aside',
		icon: 'insert_chart_outlined',
		children: [
			{
				id: 'reports.administration-reports',
				title: 'Administration Reports',
				type: 'basic',
				icon: 'all_inbox',
				link: 'administration-reports',
				disabled: true,
			},
			{
				id: 'reports.fleet-reports',
				title: 'Fleet Reports',
				type: 'basic',
				icon: 'directions_boat',
				link: 'fleet-reports',
				disabled: true,
			},
			{
				id: 'reports.operations-reports',
				title: 'Operations Reports',
				type: 'basic',
				icon: 'work_outline',
				link: 'operations-reports',
				disabled: true,
			},
			{
				type: 'divider',
			},
			{
				id: 'reports.usage-audit-logs',
				title: 'Usage & Audit Logs',
				type: 'basic',
				icon: 'history',
				link: 'usage-audit-logs',
				disabled: true,
			},
			{
				id: 'reports.account-billing-reports',
				title: 'Account & Billing Reports',
				type: 'basic',
				icon: 'account_balance_wallet',
				link: 'account-billing-reports',
				disabled: true,
			},
			{
				type: 'divider',
			},
			{
				id: 'reports.integrations-reports',
				title: 'Integrations Reports',
				type: 'basic',
				icon: 'settings_ethernet',
				link: 'integrations-reports',
				disabled: true,
			},
		],
	},
	{
		id: 'platform',
		title: 'Platform',
		tooltip: 'Platform',
		type: 'aside',
		icon: 'cloud',
		children: [
			{
				id: 'platform.help-center',
				title: 'Help Center',
				type: 'basic',
				icon: 'support_agent',
				link: 'support',
				disabled: true,
			},
			{
				type: 'divider',
			},
			{
				id: 'platform.privacy-policy',
				title: 'Privacy Policy',
				type: 'basic',
				icon: 'privacy_tip',
				link: 'privacy-policy',
				disabled: true,
			},
			{
				id: 'platform.terms-of-service',
				title: 'Terms of Service',
				type: 'basic',
				icon: 'description',
				link: 'terms-of-service',
				disabled: true,
			},
			{
				id: 'platform.licenses',
				title: 'Licenses',
				type: 'basic',
				icon: 'verified',
				link: 'licenses',
				disabled: true,
			},
			{
				type: 'divider',
			},
			{
				id: 'platform.release-notes',
				title: 'Release Notes',
				type: 'basic',
				icon: 'rss_feed',
				link: 'release-notes',
				disabled: true,
			},
			{
				id: 'platform.about',
				title: 'About',
				type: 'basic',
				icon: 'info',
				link: 'about',
				disabled: true,
			},
		],
	},
];
