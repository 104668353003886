"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEnum = void 0;
function parseEnum(enumObj, value) {
    if (Object.values(enumObj).includes(value)) {
        return value;
    }
    return undefined;
}
exports.parseEnum = parseEnum;
