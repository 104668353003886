"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalReservationActionEnum = void 0;
var RentalReservationActionEnum;
(function (RentalReservationActionEnum) {
    RentalReservationActionEnum["CONFIRM"] = "confirm";
    RentalReservationActionEnum["COMPLETE"] = "complete";
    RentalReservationActionEnum["PENDING"] = "make_pending";
    RentalReservationActionEnum["CANCEL"] = "cancel";
    RentalReservationActionEnum["NO_SHOW"] = "no_show";
    RentalReservationActionEnum["CREATE_RENTAL_AGREEMENT"] = "create_rental_agreement";
})(RentalReservationActionEnum || (exports.RentalReservationActionEnum = RentalReservationActionEnum = {}));
