"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountStatusEnum = void 0;
var AccountStatusEnum;
(function (AccountStatusEnum) {
    AccountStatusEnum["ACTIVE"] = "active";
    AccountStatusEnum["OVERDUE"] = "overdue";
    AccountStatusEnum["ON_HOLD"] = "on_hold";
    AccountStatusEnum["SUSPENDED"] = "suspended";
    AccountStatusEnum["CLOSED"] = "closed";
})(AccountStatusEnum || (exports.AccountStatusEnum = AccountStatusEnum = {}));
