import { Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, ReplaySubject, of, tap } from 'rxjs';
import { MENU_NAVIGATION_DATA } from './menu-bar.data';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private _navigation: ReplaySubject<Navigation> =
		new ReplaySubject<Navigation>(1);
	get navigation$(): Observable<Navigation> {
		return this._navigation.asObservable();
	}
	loadNavigation(): Observable<any> {
		return of(
			this._navigation.next({ default: MENU_NAVIGATION_DATA } as Navigation),
		);
	}
}
