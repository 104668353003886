"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userStatusToColor = void 0;
const user_status_enum_1 = require("../../users/enums/user-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function userStatusToColor(status) {
    switch (status) {
        case user_status_enum_1.UserStatusEnum.ACTIVE:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case user_status_enum_1.UserStatusEnum.INACTIVE:
            return record_color_enum_1.RecordColorEnum.SLATE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.userStatusToColor = userStatusToColor;
