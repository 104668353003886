"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTagDto = void 0;
class CreateTagDto {
    color;
    name;
    recordCategory;
    description;
}
exports.CreateTagDto = CreateTagDto;
