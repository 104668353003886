"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataExportFormatEnum = void 0;
var DataExportFormatEnum;
(function (DataExportFormatEnum) {
    DataExportFormatEnum["JSON"] = "json";
    DataExportFormatEnum["CSV"] = "csv";
    DataExportFormatEnum["XLSX"] = "xlsx";
    DataExportFormatEnum["PDF"] = "pdf";
    DataExportFormatEnum["HTML"] = "html";
})(DataExportFormatEnum || (exports.DataExportFormatEnum = DataExportFormatEnum = {}));
