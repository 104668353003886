import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { SettingsService } from './modules/admin/settings/services/settings.service';

@Injectable({
	providedIn: 'root',
})
export class InitialDataResolver {
	constructor(
		private _logger: NGXLogger,
		private _settingsService: SettingsService,
	) {}

	/**
	 * Use this resolver to resolve initial data for the application
	 */
	public resolve(): Observable<any> {
		// Log
		this._logger.info('resolve > called');
		return this._settingsService.load$();
	}
}
