"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleOdometerHistoryDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleOdometerHistoryDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    vehicleId;
    changeReason;
    description;
    date;
    odometerValue;
    referenceRecordId;
    staffId;
    staff;
}
exports.VehicleOdometerHistoryDto = VehicleOdometerHistoryDto;
