"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingProfilePricingPropertyDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class PricingProfilePricingPropertyDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.PRICING_PROFILE;
    }
    pricingProfileId;
    calculationType;
    value;
    property;
}
exports.PricingProfilePricingPropertyDto = PricingProfilePricingPropertyDto;
