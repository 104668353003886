"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalReservationRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class RentalReservationRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.RENTAL_RESERVATION;
    }
    documentNumber;
    qrCode;
    status;
    statusReason;
    recordCategory;
    checkOutAt;
    checkInAt;
    expiresAt;
    vehicleId;
    customerId;
    checkOutLocationId;
    rentalAgreementId;
    pricingItems;
    tags;
    avatarFile;
    files;
}
exports.RentalReservationRecordDto = RentalReservationRecordDto;
