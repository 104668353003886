"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DayOfWeekEnum = void 0;
var DayOfWeekEnum;
(function (DayOfWeekEnum) {
    DayOfWeekEnum["MONDAY"] = "monday";
    DayOfWeekEnum["TUESDAY"] = "tuesday";
    DayOfWeekEnum["WEDNESDAY"] = "wednesday";
    DayOfWeekEnum["THURSDAY"] = "thursday";
    DayOfWeekEnum["FRIDAY"] = "friday";
    DayOfWeekEnum["SATURDAY"] = "saturday";
    DayOfWeekEnum["SUNDAY"] = "sunday";
})(DayOfWeekEnum || (exports.DayOfWeekEnum = DayOfWeekEnum = {}));
