"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unitPricingToTotalGross = void 0;
const unit_pricing_to_total_net_function_1 = require("./unit-pricing-to-total-net.function");
const unit_pricing_to_total_tax_function_1 = require("./unit-pricing-to-total-tax.function");
function unitPricingToTotalGross(pricingItem) {
    if (!pricingItem) {
        return 0;
    }
    return ((0, unit_pricing_to_total_net_function_1.unitPricingToTotalNet)(pricingItem) + (0, unit_pricing_to_total_tax_function_1.unitPricingToTotalTax)(pricingItem));
}
exports.unitPricingToTotalGross = unitPricingToTotalGross;
