"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
const contact_to_short_name_function_1 = require("../../shared/functions/contact-to-short-name.function");
class CustomerListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.CUSTOMER;
    }
    code;
    recordCategory;
    status;
    contact;
    driver;
    tags;
    avatarFileUrl;
    mobileTitle = () => {
        const contactName = (0, contact_to_short_name_function_1.contactToShortName)(this.contact);
        return `${this.code}${contactName ? ' - ' + contactName : ''}`;
    };
    mobileSubtitle = () => {
        const email = this.contact?.email;
        const phoneMobile = this.contact?.phoneMobile;
        return phoneMobile
            ? `${phoneMobile}${email ? ' / ' + email : ''}`
            : email
                ? `${email}`
                : '';
    };
}
exports.CustomerListDto = CustomerListDto;
