"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleEngineOutputUnitEnum = void 0;
var VehicleEngineOutputUnitEnum;
(function (VehicleEngineOutputUnitEnum) {
    VehicleEngineOutputUnitEnum["HP"] = "hp";
    VehicleEngineOutputUnitEnum["BHP"] = "bhp";
    VehicleEngineOutputUnitEnum["KW"] = "kw";
    VehicleEngineOutputUnitEnum["PS"] = "ps";
})(VehicleEngineOutputUnitEnum || (exports.VehicleEngineOutputUnitEnum = VehicleEngineOutputUnitEnum = {}));
