"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDatesDifferenceInDays = void 0;
const to_date_without_time_function_1 = require("./to-date-without-time.function");
function toDatesDifferenceInDays(dateFrom, dateTo) {
    dateFrom = (0, to_date_without_time_function_1.toDateWithoutTime)(dateFrom);
    dateTo = (0, to_date_without_time_function_1.toDateWithoutTime)(dateTo);
    if (isNaN(dateFrom.getTime()) || isNaN(dateTo.getTime())) {
        return 0;
    }
    const timeDifference = dateTo.getTime() - dateFrom.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return Math.max(1, Math.abs(daysDifference));
}
exports.toDatesDifferenceInDays = toDatesDifferenceInDays;
