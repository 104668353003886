import { Injectable } from '@angular/core';
import {
	FuseNavigationItem,
	FuseNavigationService,
} from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { COMPACT_NAVIGATION } from 'app/mock-api/navigation/data';
import { cloneDeep } from 'lodash-es';

@Injectable({
	providedIn: 'root',
})
export class SearchMockApi {
	private readonly _defaultNavigation: FuseNavigationItem[] =
		COMPACT_NAVIGATION;
	// TODO: Refactor this to use a new Location class that is specifically for the UI
	private readonly _locations: any[] = [];

	/**
	 * Constructor
	 */
	constructor(
		private _fuseMockApiService: FuseMockApiService,
		private _fuseNavigationService: FuseNavigationService,
	) {
		// Register Mock API handlers
		this.registerHandlers();
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Register Mock API handlers
	 */
	registerHandlers(): void {
		// Get the flat navigation and store it
		const flatNavigation = this._fuseNavigationService.getFlatNavigation(
			this._defaultNavigation,
		);

		// ------------------------------------------------------------------------
		// @ Search results - GET
		// ------------------------------------------------------------------------
		this._fuseMockApiService.onPost('api/search').reply(({ request }) => {
			// Get the search query
			const query = cloneDeep(request.body.query.toLowerCase());

			// If the search query is an empty string, return an empty array
			if (query === '') {
				return [200, { results: [] }];
			}

			// Filter the contacts
			const locationsResults = cloneDeep(this._locations).filter(
				(location) =>
					location.name.toLowerCase().includes(query) ||
					(location.address1
						? location.address1.toLowerCase().includes(query)
						: false) ||
					(location.address2
						? location.address1.toLowerCase().includes(query)
						: false),
			);

			// Filter the navigation
			const pagesResults = cloneDeep(flatNavigation).filter(
				(page) =>
					page.title?.toLowerCase().includes(query) ||
					(page.subtitle && page.subtitle.includes(query)),
			);

			// Prepare the results array
			const results = [];

			// If there are locations results...
			if (locationsResults.length > 0) {
				// Normalize the results
				locationsResults.forEach((result) => {
					// Add a link
					result.link = '/locations/' + result.id;

					// Add the name as the value
					result.value = result.name;
				});

				// Add to the results
				results.push({
					id: 'locations',
					label: 'Locations',
					results: locationsResults,
				});
			}

			// If there are page results...
			if (pagesResults.length > 0) {
				// Normalize the results
				pagesResults.forEach((result: any) => {
					// Add the page title as the value
					result.value = result.title;
				});

				// Add to the results
				results.push({
					id: 'pages',
					label: 'Pages',
					results: pagesResults,
				});
			}

			// Return the response
			return [200, results];
		});
	}
}
