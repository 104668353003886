import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep, forEach } from 'lodash';
import { NGXLogger } from 'ngx-logger';

import {
	availableCarsData,
	availableCarsProperties,
	averageRentalDurationRangeData,
	averageRentalDurationRangeProperties,
	averageRentalDurationValueData,
	averageRentalDurationValueProperties,
	carSummaryData,
	carSummaryProperties,
	customersData,
	customersProperties,
	damagedCarsData,
	damagedCarsProperties,
	rentalPercentageUtilizationRangeData,
	rentalPercentageUtilizationRangeProperties,
	rentalPercentageUtilizationValueData,
	rentalPercentageUtilizationValueProperties,
	staffUtilizationData,
	staffUtilizationProperties,
} from './widgets-data';
import { randomizeAvailableCarsdata } from './widgets-utils';

@Injectable({
	providedIn: 'root',
})
export class WidgetsMockApi {
	/////////
	private _availableCarsProperties = availableCarsProperties;
	private _availableCarsData = availableCarsData;
	private _staffUtilizationProperties = staffUtilizationProperties;
	private _staffUtilizatioData = staffUtilizationData;
	private _customersData = customersData;
	private _customersProperties = customersProperties;
	private _damagedCarsProperties = damagedCarsProperties;
	private _damagedCarsData = damagedCarsData;
	private _carSummaryProperties = carSummaryProperties;
	private _carSummaryData = carSummaryData;

	//#region _RPU
	private _rentalPercentageUtilizationValueProperties =
		rentalPercentageUtilizationValueProperties;
	private _rentalPercentageUtilizationValueData =
		rentalPercentageUtilizationValueData;
	private _rentalPercentageUtilizationRangeProperties =
		rentalPercentageUtilizationRangeProperties;
	private _rentalPercentageUtilizationRangeData =
		rentalPercentageUtilizationRangeData;
	//#endregion
	//#region _ARD
	private _averageRentalDurationValueProperties =
		averageRentalDurationValueProperties;
	private _averageRentalDurationValueData = averageRentalDurationValueData;
	private _averageRentalDurationRangeProperties =
		averageRentalDurationRangeProperties;
	private _averageRentaulDurationRangeData = averageRentalDurationRangeData;
	//#endregion

	constructor(
		private _fuseMockApiService: FuseMockApiService,
		private logger: NGXLogger,
	) {
		this.registerHandlers();
	}

	registerHandlers(): void {
		this._fuseMockApiService
			.onGet('api/widgets/fleet/staff-utilization/properties', 1000)
			.reply(({ request }) => {
				// const response: ApiResponse<WidgetValueFleetProperties> = {
				//     data: cloneDeep(this._staffUtilizationProperties),
				// }
				return [200, cloneDeep(this._staffUtilizationProperties)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/fleet/staff-utilization/data', 750)
			.reply(({ request }) => {
				const dropDownOption = request.params.get('option');
				let staffUtilization;
				switch (dropDownOption) {
					case 'yesterday':
						{
							staffUtilization = this._staffUtilizatioData.yesterday;
						}
						break;
					case 'last7Days':
						{
							staffUtilization = this._staffUtilizatioData.last7Days;
						}
						break;
					case 'last30Days':
						{
							staffUtilization = this._staffUtilizatioData.last30Days;
						}
						break;
					case 'last90Days':
						{
							staffUtilization = this._staffUtilizatioData.last90Days;
						}
						break;
					default: {
						// log error
						this.logger.error('age not found');
					}
				}
				return [200, cloneDeep(staffUtilization)];
			});

		//#region RPU Aggegate
		this._fuseMockApiService
			.onGet(
				'api/widgets/fleet/value/rental-percentage-utilization/properties',
				1000,
			)
			.reply(({ request }) => {
				// const response: ApiResponse<WidgetValueFleetProperties> = {
				//     data: cloneDeep(this._staffUtilizationProperties),
				// }
				return [
					200,
					cloneDeep(this._rentalPercentageUtilizationValueProperties),
				];
			});

		this._fuseMockApiService
			.onGet('api/widgets/fleet/value/rental-percentage-utilization/data', 750)
			.reply(({ request }) => {
				const dropDownOption = request.params.get('option');
				let rentalPercentageUtilization;
				switch (dropDownOption) {
					case 'all':
						{
							rentalPercentageUtilization =
								this._rentalPercentageUtilizationValueData.all;
						}
						break;
					case 'nicosia':
						{
							rentalPercentageUtilization =
								this._rentalPercentageUtilizationValueData.nicosia;
						}
						break;
					case 'limassol':
						{
							rentalPercentageUtilization =
								this._rentalPercentageUtilizationValueData.limassol;
						}
						break;
					default: {
						// log error
						this.logger.error('not found');
					}
				}
				return [200, cloneDeep(rentalPercentageUtilization)];
			});

		this._fuseMockApiService
			.onGet(
				'api/widgets/fleet/range/rental-percentage-utilization/properties',
				300,
			)
			.reply(({ request }) => {
				return [
					200,
					cloneDeep(this._rentalPercentageUtilizationRangeProperties),
				];
			});

		this._fuseMockApiService
			.onGet('api/widgets/fleet/range/rental-percentage-utilization/data')
			.reply(({ request }) => {
				let rentalPercentageUtilization;
				const age = request.params.get('age');
				switch (age) {
					case 'last-month':
						{
							rentalPercentageUtilization =
								this._rentalPercentageUtilizationRangeData.lastMonth;
						}
						break;
					case 'last-three-months':
						{
							rentalPercentageUtilization =
								this._rentalPercentageUtilizationRangeData.lastThreeMonths;
						}
						break;
					case 'last-six-months':
						{
							rentalPercentageUtilization =
								this._rentalPercentageUtilizationRangeData.lastSixMonths;
						}
						break;
					case 'last-year':
						{
							rentalPercentageUtilization =
								this._rentalPercentageUtilizationRangeData.lastYear;
						}
						break;
					default: {
						// log error
						this.logger.error('age not found');
					}
				}
				return [200, cloneDeep(rentalPercentageUtilization)];
			});
		//#endregion

		//#region ARD Aggegate
		this._fuseMockApiService
			.onGet('api/widgets/fleet/value/average-rental-duration/properties', 1000)
			.reply(({ request }) => {
				// const response: ApiResponse<WidgetValueFleetProperties> = {
				//     data: cloneDeep(this._staffUtilizationProperties),
				// }
				return [200, cloneDeep(this._averageRentalDurationValueProperties)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/fleet/value/average-rental-duration/data', 750)
			.reply(({ request }) => {
				const dropDownOption = request.params.get('option');
				let averageRentalDuration;
				switch (dropDownOption) {
					case 'all':
						{
							averageRentalDuration = this._averageRentalDurationValueData.all;
						}
						break;
					case 'nicosia':
						{
							averageRentalDuration =
								this._averageRentalDurationValueData.nicosia;
						}
						break;
					case 'limassol':
						{
							averageRentalDuration =
								this._averageRentalDurationValueData.limassol;
						}
						break;
					default: {
						// log error
						this.logger.error('not found');
					}
				}
				return [200, cloneDeep(averageRentalDuration)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/fleet/range/average-rental-duration/properties', 300)
			.reply(({ request }) => {
				return [200, cloneDeep(this._averageRentalDurationRangeProperties)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/fleet/range/average-rental-duration/data')
			.reply(({ request }) => {
				let averageRentalDuration;
				const age = request.params.get('age');
				switch (age) {
					case 'last-month':
						{
							averageRentalDuration =
								this._averageRentaulDurationRangeData.lastMonth;
						}
						break;
					case 'last-three-months':
						{
							averageRentalDuration =
								this._averageRentaulDurationRangeData.lastThreeMonths;
						}
						break;
					case 'last-six-months':
						{
							averageRentalDuration =
								this._averageRentaulDurationRangeData.lastSixMonths;
						}
						break;
					case 'last-year':
						{
							averageRentalDuration =
								this._averageRentaulDurationRangeData.lastYear;
						}
						break;
					default: {
						// log error
						this.logger.error('age not found');
					}
				}
				return [200, cloneDeep(averageRentalDuration)];
			});
		//#endregion

		this._fuseMockApiService
			.onGet('api/widgets/value/compare/fleet/available-cars/properties', 1100)
			.reply(({ request }) => {
				return [200, cloneDeep(this._availableCarsProperties)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/value/fleet/available-cars/data', 650)
			.reply(({ request }) => {
				this._availableCarsData = randomizeAvailableCarsdata(
					request.params,
					this._availableCarsData,
				);
				return [200, cloneDeep(this._availableCarsData)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/range/fleet/customers/properties', 400)
			.reply(({ request }) => {
				return [200, cloneDeep(this._customersProperties)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/range/fleet/customers/data', 500)
			.reply(({ request }) => {
				let customersData;
				const age = request.params.get('age');
				switch (age) {
					case 'last-month':
						{
							customersData = this._customersData.lastMonth;
						}
						break;
					case 'last-three-months':
						{
							customersData = this._customersData.lastThreeMonths;
						}
						break;
					case 'last-six-months':
						{
							customersData = this._customersData.lastSixMonths;
						}
						break;
					case 'last-year':
						{
							customersData = this._customersData.lastYear;
						}
						break;
					default: {
						// log error
						this.logger.error('age not found');
					}
				}
				return [200, cloneDeep(customersData)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/range/compare/fleet/damaged-cars/properties', 300)
			.reply(({ request }) => {
				return [200, cloneDeep(this._damagedCarsProperties)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/range/compare/fleet/damaged-cars/data', 800)
			.reply(({ request }) => {
				let damagedCarsData;
				let returnData;

				const age = request.params.get('age');
				const secondaryOptions = request.params.get('secondaryOptions');

				switch (age) {
					case 'last-month':
						{
							damagedCarsData = this._damagedCarsData.lastMonth;
							returnData = cloneDeep(damagedCarsData);
							const selections = [];

							const selectedSecondaryOptions = secondaryOptions.split(',');
							for (let i = 0; i < damagedCarsData.data.length; i++) {
								const item = damagedCarsData.data[i];
								if (selectedSecondaryOptions.includes(item.name)) {
									selections.push(item);
								}
							}
							returnData.data = selections;
						}
						break;
					case 'last-three-months':
						{
							damagedCarsData = this._damagedCarsData.lastThreeMonths;
							returnData = cloneDeep(damagedCarsData);
							const selections = [];

							const selectedSecondaryOptions = secondaryOptions.split(',');
							for (let i = 0; i < damagedCarsData.data.length; i++) {
								const item = damagedCarsData.data[i];
								if (selectedSecondaryOptions.includes(item.name)) {
									selections.push(item);
								}
							}
							returnData.data = selections;
						}
						break;
					case 'last-six-months':
						{
							damagedCarsData = this._damagedCarsData.lastSixMonths;
							returnData = cloneDeep(damagedCarsData);
							const selections = [];

							const selectedSecondaryOptions = secondaryOptions.split(',');
							for (let i = 0; i < damagedCarsData.data.length; i++) {
								const item = damagedCarsData.data[i];
								if (selectedSecondaryOptions.includes(item.name)) {
									selections.push(item);
								}
							}
							returnData.data = selections;
						}
						break;
					case 'last-year':
						{
							damagedCarsData = this._damagedCarsData.lastYear;
							returnData = cloneDeep(damagedCarsData);
							const selections = [];

							const selectedSecondaryOptions = secondaryOptions.split(',');
							for (let i = 0; i < damagedCarsData.data.length; i++) {
								const item = damagedCarsData.data[i];
								if (selectedSecondaryOptions.includes(item.name)) {
									selections.push(item);
								}
							}
							returnData.data = selections;
						}
						break;
				}

				return [200, cloneDeep(returnData)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/summary/fleet/car-summary/properties', 300)
			.reply(({ request }) => {
				return [200, cloneDeep(this._carSummaryProperties)];
			});

		this._fuseMockApiService
			.onGet('api/widgets/summary/fleet/car-summary/data', 300)
			.reply(({ request }) => {
				return [200, cloneDeep(this._carSummaryData)];
			});
	}
}
