"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateVehicleColorDto = void 0;
class CreateVehicleColorDto {
    color;
    name;
    type;
    externalReference;
}
exports.CreateVehicleColorDto = CreateVehicleColorDto;
