"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchUpdateFilesDto = void 0;
const base_batch_update_dto_1 = require("../../shared/dtos/base-batch-update.dto");
class BatchUpdateFilesDto extends base_batch_update_dto_1.BaseBatchUpdateDto {
    status;
    sensitivity;
    tags;
}
exports.BatchUpdateFilesDto = BatchUpdateFilesDto;
