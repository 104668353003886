"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleDamageListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_DAMAGE;
    }
    code;
    status;
    recordCategory;
    reportedAt;
    type;
    fault;
    severity;
    policeReportFiled;
    policeReportExternalReference;
    estimatedMaintenanceCost;
    vehicle;
    rentalAgreement;
    leaseAgreement;
    staff;
    scrap;
    thirdPartyContact;
    thirdPartyInsurance;
    insuranceClaim;
    tags;
    avatarFileUrl;
    mobileTitle = () => {
        return `${this.code}` + `${this.fault ? ' - ' + this.fault : ''}`;
    };
    mobileSubtitle = () => {
        const vehicle = this.vehicle?.licensePlate
            ? this.vehicle.licensePlate
            : this.vehicle?.vin
                ? this.vehicle.vin
                : '';
        const period = `${this.reportedAt}`;
        const estimatedMaintenanceCost = this.estimatedMaintenanceCost
            ? `${this.estimatedMaintenanceCost} €`
            : '';
        return (`${vehicle}` +
            `${period ? ' / ' + period : ''}` +
            `${estimatedMaintenanceCost ? ' / ' + estimatedMaintenanceCost : ''}`);
    };
}
exports.VehicleDamageListDto = VehicleDamageListDto;
