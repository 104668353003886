import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '../../../../../../../@fuse/animations';
import { MatIconModule } from '@angular/material/icon';
import { UltraMenuItemComponent } from '../../../../ultramarine-ui/ultra-menu-item/ultra-menu-item.component';
import { UltraMenuItemWrapperComponent } from '../../../../ultramarine-ui/ultra-menu-item-wrapper/ultra-menu-item-wrapper.component';
import { HeaderDataModel } from '../../record-header.model';

@Component({
	selector: 'app-record-header-menu',
	templateUrl: 'record-header-menu.component.html',
	standalone: true,
	animations: fuseAnimations,
	imports: [
		MatIconModule,
		UltraMenuItemComponent,
		UltraMenuItemWrapperComponent,
	],
})
export class RecordHeaderMenuComponent<T> {
	public headerData: HeaderDataModel<T>;
	@Input() set setHeaderData(headerData: HeaderDataModel<T>) {
		this.headerData = headerData;
	}
	@Output() onDeleteClicked: EventEmitter<null> = new EventEmitter(null);
	@Output() onMarkAsReadyClicked: EventEmitter<null> = new EventEmitter(null);
}
