"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class FileRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.FILE;
    }
    name;
    size;
    mimeType;
    status;
    sensitivity;
    subject;
    url;
    statusReason;
    tags;
}
exports.FileRecordDto = FileRecordDto;
