"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageTypeEnum = void 0;
var VehicleDamageTypeEnum;
(function (VehicleDamageTypeEnum) {
    VehicleDamageTypeEnum["IN_PARK"] = "in_park";
    VehicleDamageTypeEnum["COLLISION"] = "collision";
    VehicleDamageTypeEnum["VANDALISM"] = "vandalism";
    VehicleDamageTypeEnum["THEFT"] = "theft";
    VehicleDamageTypeEnum["OTHER"] = "other";
})(VehicleDamageTypeEnum || (exports.VehicleDamageTypeEnum = VehicleDamageTypeEnum = {}));
