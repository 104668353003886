import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import {
	BehaviorSubject,
	catchError,
	forkJoin,
	Observable,
	tap,
	throwError,
} from 'rxjs';
import {
	ApiResponse,
	CountryDto,
	CurrencyDto,
	LanguageDto,
	SettingsDto,
} from 'ultramarine-shared';

import { FuseLoadingService } from '../../../../../@fuse/services/loading';
import { UltramarineApiService } from '../../../../core/api/ultramarine-api.service';
@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	// ------------------------------------------------------------------------
	// @ Private variables
	// ------------------------------------------------------------------------
	private _settings$: BehaviorSubject<ApiResponse<SettingsDto>> =
		new BehaviorSubject(null);

	private _countries$: BehaviorSubject<ApiResponse<CountryDto[]>> =
		new BehaviorSubject(null);

	private _currencies$: BehaviorSubject<ApiResponse<CurrencyDto[]>> =
		new BehaviorSubject(null);

	private _languages$: BehaviorSubject<ApiResponse<LanguageDto[]>> =
		new BehaviorSubject(null);

	constructor(
		private _logger: NGXLogger,
		private _fuseLoadingService: FuseLoadingService,
		private _apiService: UltramarineApiService,
	) {}

	// ------------------------------------------------------------------------
	// @ Accessors
	// ------------------------------------------------------------------------

	/**
	 * Getter for settings
	 */
	get settings$(): Observable<ApiResponse<SettingsDto>> {
		return this._settings$.asObservable();
	}

	get settings(): SettingsDto {
		if (!this._settings$.value) {
			return null;
		}

		// Return a copy of the data
		return { ...this._settings$.value.data };
	}

	/**
	 * Getter for countries
	 */
	get countries$(): Observable<ApiResponse<CountryDto[]>> {
		return this._countries$.asObservable();
	}

	get countries(): CountryDto[] {
		if (!this._countries$.value) {
			return null;
		}

		// Return a copy of the data
		return { ...this._countries$.value.data };
	}

	/**
	 * Getter for currencies
	 */
	get currencies$(): Observable<ApiResponse<CurrencyDto[]>> {
		return this._currencies$.asObservable();
	}

	get currencies(): CurrencyDto[] {
		if (!this._currencies$.value) {
			return null;
		}

		// Return a copy of the data
		return { ...this._currencies$.value.data };
	}

	/**
	 * Getter for languages
	 */
	get languages$(): Observable<ApiResponse<LanguageDto[]>> {
		return this._languages$.asObservable();
	}

	get languages(): LanguageDto[] {
		if (!this._languages$.value) {
			return null;
		}

		// Return a copy of the data
		return { ...this._languages$.value.data };
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Load all settings
	 */
	load$(): Observable<any> {
		// Show the loading indicator
		this._fuseLoadingService.show();

		// Log
		this._logger.info('load$ > called');

		const settings$ = this._apiService.get(`settings`).pipe(
			tap((response: ApiResponse<SettingsDto>) => {
				// Log
				this._logger.info('apiService.get:settings > response: ', response);

				// Store the data
				this._settings$.next(response);
			}),
		);

		const countries$ = this._apiService.get(`settings/countries/list`).pipe(
			tap((response: ApiResponse<CountryDto[]>) => {
				// Log
				this._logger.info('apiService.get:countries > response: ', response);

				// Store the data
				this._countries$.next(response);
			}),
		);

		const currencies$ = this._apiService.get(`settings/currencies/list`).pipe(
			tap((response: ApiResponse<CurrencyDto[]>) => {
				// Log
				this._logger.info('apiService.get:currencies > response: ', response);

				// Store the data
				this._currencies$.next(response);
			}),
		);

		const languages$ = this._apiService.get(`settings/languages/list`).pipe(
			tap((response: ApiResponse<LanguageDto[]>) => {
				// Log
				this._logger.info('apiService.get:languages > response: ', response);

				// Store the data
				this._languages$.next(response);
			}),
		);

		return forkJoin({
			settings: settings$,
			languages: languages$,
			currencies: currencies$,
			countries: countries$,
		}).pipe(
			tap(() => {
				// Hide the loading indicator
				this._fuseLoadingService.hide();
			}),
			catchError((error: any) => {
				// Log
				this._logger.error('load$ > error: ', error);

				// Hide the loading indicator
				this._fuseLoadingService.hide();

				// Re-throw the error
				return throwError(
					() =>
						new Error('An error occurred while fetching data from the server'),
				);
			}),
		);
	}

	/**
	 * Get Country Name
	 * @param countryId
	 * @returns string
	 */
	getCountryName(countryId: number): string | null {
		// Log
		this._logger.info('getCountryName > called with countryId: ', countryId);

		// Check
		if (!countryId) {
			return null;
		}

		// Get countries list
		const countries = this._countries$.value.data;

		// If there are no countries, return null
		if (!countries) {
			return null;
		}

		// Find the country
		const country = countries.find((item) => item.id === countryId);

		// If there is no country, return null
		if (!country) {
			return null;
		}

		// Return the name
		return country.name;
	}
}
