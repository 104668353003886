"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidPhone = void 0;
const phone_schema_1 = require("../schemas/phone.schema");
const isValidPhone = (phone, abortEarly = false) => {
    const { error } = (0, phone_schema_1.phoneSchema)().validate(phone, {
        abortEarly,
    });
    if (error) {
        return {
            isValid: false,
            errors: error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.isValidPhone = isValidPhone;
