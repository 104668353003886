import { ReportsHome } from 'app/modules/admin/reports/pages/home/home.types';

export const reportsHome: ReportsHome = {
	title: 'REPORTS',
	description:
		'Here you will be able to view all the reports that have been generated by the system.',
	subDescription:
		'Please select a report from the list below to view the details.',
	reportsList: [
		{
			id: '',
			title: 'Rental Percentage Utilisation',
			slug: 'rental-percentage-utilisation',
			description: 'Introductory to rental percentage utilisation',
			category: 'fleet',
			link: 'rental-percentage-utilisation',
		},
		{
			id: '',
			title: 'Average Rental Duration',
			slug: 'average-rental-duration',
			description: 'Introductory to average rental duration',
			category: 'fleet',
			link: 'average-rental-duration',
		},
	],
};
