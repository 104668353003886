"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleEngineLayoutEnum = void 0;
var VehicleEngineLayoutEnum;
(function (VehicleEngineLayoutEnum) {
    VehicleEngineLayoutEnum["FRONT_LONGITUDINAL"] = "front-longitudinal";
    VehicleEngineLayoutEnum["FRONT_TRANSVERSE"] = "front-transverse";
    VehicleEngineLayoutEnum["MIDDLE_LONGITUDINAL"] = "middle-longitudinal";
    VehicleEngineLayoutEnum["MIDDLE_TRANSVERSE"] = "middle-transverse";
    VehicleEngineLayoutEnum["REAR_LONGITUDINAL"] = "rear-longitudinal";
    VehicleEngineLayoutEnum["REAR_TRANSVERSE"] = "rear-transverse";
})(VehicleEngineLayoutEnum || (exports.VehicleEngineLayoutEnum = VehicleEngineLayoutEnum = {}));
