"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleActionEnum = void 0;
var VehicleActionEnum;
(function (VehicleActionEnum) {
    VehicleActionEnum["PARK"] = "park";
    VehicleActionEnum["OUT_OF_SERVICE"] = "out_of_service";
    VehicleActionEnum["SCRAP"] = "scrap";
    VehicleActionEnum["SELL"] = "sell";
    VehicleActionEnum["UPDATE_ODOMETER"] = "update_odometer";
    VehicleActionEnum["CREATE_RENTAL_RESERVATION"] = "create_rental_reservation";
    VehicleActionEnum["CREATE_RENTAL_AGREEMENT"] = "create_rental_agreement";
    VehicleActionEnum["CREATE_LEASE_AGREEMENT"] = "create_lease_agreement";
    VehicleActionEnum["CREATE_ROAD_TAX"] = "create_road_tax";
    VehicleActionEnum["CREATE_ROAD_WORTHY_CHECK"] = "create_road_worthy_check";
    VehicleActionEnum["CREATE_MAINTENANCE"] = "create_maintenance";
    VehicleActionEnum["CREATE_DAMAGE"] = "create_damage";
})(VehicleActionEnum || (exports.VehicleActionEnum = VehicleActionEnum = {}));
