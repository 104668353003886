import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { fuseAnimations } from '@fuse/animations';
import { async } from 'rxjs';

import { TimeMachineService } from '../time-machine/time-machine.service';

@Component({
	selector: 'app-time-traveling-badge',
	templateUrl: 'time-traveling-badge.component.html',
	standalone: true,
	imports: [MatIconModule, MatTooltipModule, NgIf, AsyncPipe],
	animations: fuseAnimations,
})
export class TimeTravelingBadgeComponent {
	isPresent = this._timeMachineService.isPresent;

	constructor(private _timeMachineService: TimeMachineService) {}

	protected readonly async = async;
}
