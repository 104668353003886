import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable } from '@angular/core';
import { RecordHeaderMenuComponent } from '../components/headers/record-header/components/record-header-menu/record-header-menu.component';
import { UmConfirmationPopupComponent } from '../components/ultramarine-ui/um-confirmation-popup/um-confirmation-popup.component';
import { Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PortalService {
	overlayRef: OverlayRef;
	componentRef: ComponentRef<any>;
	componentPortal: ComponentPortal<any>;

	constructor(private _overlay: Overlay) {}

	showConfirmation(): Observable<boolean> {
		this.componentPortal = new ComponentPortal(UmConfirmationPopupComponent);
		const positionStrategy = this._overlay
			.position()
			.global()
			.bottom('10px')
			.right('10px');
		this.overlayRef = this._overlay.create({ positionStrategy });
		const componentRef = this.overlayRef.attach(this.componentPortal);
		const outsidesub = this.overlayRef.outsidePointerEvents().subscribe(() => {
			this.overlayRef.detach();
			outsidesub.unsubscribe();
		});
		return componentRef.instance.onConfirm$;
	}

	hide() {
		this.overlayRef.detach();
	}
}
