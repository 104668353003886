"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleInsuranceClaimStatusEnum = void 0;
var VehicleInsuranceClaimStatusEnum;
(function (VehicleInsuranceClaimStatusEnum) {
    VehicleInsuranceClaimStatusEnum["PENDING"] = "pending";
    VehicleInsuranceClaimStatusEnum["APPROVED"] = "approved";
    VehicleInsuranceClaimStatusEnum["REJECTED"] = "rejected";
    VehicleInsuranceClaimStatusEnum["SETTLED"] = "settled";
})(VehicleInsuranceClaimStatusEnum || (exports.VehicleInsuranceClaimStatusEnum = VehicleInsuranceClaimStatusEnum = {}));
