"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supplierStatusToColor = void 0;
const supplier_status_enum_1 = require("../../suppliers/enums/supplier-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function supplierStatusToColor(status) {
    switch (status) {
        case supplier_status_enum_1.SupplierStatusEnum.ACTIVE:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case supplier_status_enum_1.SupplierStatusEnum.OVERDUE:
            return record_color_enum_1.RecordColorEnum.RED;
        case supplier_status_enum_1.SupplierStatusEnum.CLOSED:
            return record_color_enum_1.RecordColorEnum.SLATE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.supplierStatusToColor = supplierStatusToColor;
