"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactDto = void 0;
const transform_date_decorator_1 = require("../../shared/decorators/transform-date.decorator");
const transform_int_decorator_1 = require("../../shared/decorators/transform-int.decorator");
const contact_title_enum_1 = require("../enums/contact-title.enum");
class ContactDto {
    id;
    companyName;
    companyRegNumber;
    companyTaxNumber;
    companyVatNumber;
    firstName;
    middleNames;
    lastName;
    title = contact_title_enum_1.ContactTitleEnum.NONE;
    dateOfBirth;
    email;
    address1;
    address2;
    phoneLandline;
    phoneMobile;
    city;
    postCode;
    poBox;
    county;
    state;
    countryId;
    country;
    website;
    facebook;
    instagram;
    twitter_x;
    slack;
    whatsApp;
    youtube;
    linkedIn;
    fullName = () => {
        return this.companyName
            ? `${this.companyName}`
            : `${this.firstName ? this.firstName : ''}${this.middleNames ? ' ' + this.middleNames : ''}${this.lastName ? ' ' + this.lastName : ''}`;
    };
    shortName = () => {
        return this.companyName
            ? `${this.companyName}`
            : `${this.firstName ? this.firstName : ''}${this.lastName ? ' ' + this.lastName : ''}`;
    };
}
exports.ContactDto = ContactDto;
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], ContactDto.prototype, "id", void 0);
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], ContactDto.prototype, "dateOfBirth", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], ContactDto.prototype, "countryId", void 0);
