"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleEngineCylindersLayoutEnum = void 0;
var VehicleEngineCylindersLayoutEnum;
(function (VehicleEngineCylindersLayoutEnum) {
    VehicleEngineCylindersLayoutEnum["BOXER"] = "boxer";
    VehicleEngineCylindersLayoutEnum["INLINE"] = "inline";
    VehicleEngineCylindersLayoutEnum["ROTARY"] = "rotary";
    VehicleEngineCylindersLayoutEnum["V"] = "v";
    VehicleEngineCylindersLayoutEnum["VR"] = "vr";
    VehicleEngineCylindersLayoutEnum["W"] = "w";
})(VehicleEngineCylindersLayoutEnum || (exports.VehicleEngineCylindersLayoutEnum = VehicleEngineCylindersLayoutEnum = {}));
