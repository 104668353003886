"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageItemTypeEnum = void 0;
var VehicleDamageItemTypeEnum;
(function (VehicleDamageItemTypeEnum) {
    VehicleDamageItemTypeEnum["BODY_HOOD"] = "hood";
    VehicleDamageItemTypeEnum["BODY_TRUNK"] = "trunk";
    VehicleDamageItemTypeEnum["BODY_ROOF"] = "roof";
    VehicleDamageItemTypeEnum["BODY_FRONT_BUMPER"] = "front_bumper";
    VehicleDamageItemTypeEnum["BODY_REAR_BUMPER"] = "rear_bumper";
    VehicleDamageItemTypeEnum["BODY_LEFT_FRONT_FENDER"] = "left_front_fender";
    VehicleDamageItemTypeEnum["BODY_RIGHT_FRONT_FENDER"] = "right_front_fender";
    VehicleDamageItemTypeEnum["BODY_LEFT_REAR_FENDER"] = "left_rear_fender";
    VehicleDamageItemTypeEnum["BODY_RIGHT_REAR_FENDER"] = "right_rear_fender";
    VehicleDamageItemTypeEnum["BODY_FRONT_LEFT_DOOR"] = "front_left_door";
    VehicleDamageItemTypeEnum["BODY_FRONT_RIGHT_DOOR"] = "front_right_door";
    VehicleDamageItemTypeEnum["BODY_REAR_LEFT_DOOR"] = "rear_left_door";
    VehicleDamageItemTypeEnum["BODY_REAR_RIGHT_DOOR"] = "rear_right_door";
    VehicleDamageItemTypeEnum["BODY_LEFT_SIDE_MIRROR"] = "left_side_mirror";
    VehicleDamageItemTypeEnum["BODY_RIGHT_SIDE_MIRROR"] = "right_side_mirror";
    VehicleDamageItemTypeEnum["LIGHT_LEFT_HEADLIGHT"] = "left_headlight";
    VehicleDamageItemTypeEnum["LIGHT_RIGHT_HEADLIGHT"] = "right_headlight";
    VehicleDamageItemTypeEnum["LIGHT_LEFT_TAILLIGHT"] = "left_taillight";
    VehicleDamageItemTypeEnum["LIGHT_RIGHT_TAILLIGHT"] = "right_taillight";
    VehicleDamageItemTypeEnum["LIGHT_LEFT_TURN_SIGNAL"] = "left_turn_signal";
    VehicleDamageItemTypeEnum["LIGHT_RIGHT_TURN_SIGNAL"] = "right_turn_signal";
    VehicleDamageItemTypeEnum["LIGHT_FRONT_LEFT_FOG_LIGHT"] = "front_left_fog_light";
    VehicleDamageItemTypeEnum["LIGHT_FRONT_RIGHT_FOG_LIGHT"] = "front_right_fog_light";
    VehicleDamageItemTypeEnum["LIGHT_REAR_LEFT_FOG_LIGHT"] = "rear_left_fog_light";
    VehicleDamageItemTypeEnum["LIGHT_REAR_RIGHT_FOG_LIGHT"] = "rear_right_fog_light";
    VehicleDamageItemTypeEnum["INTERIOR_FRONT_LEFT_SEAT"] = "front_left_seat";
    VehicleDamageItemTypeEnum["INTERIOR_FRONT_RIGHT_SEAT"] = "front_right_seat";
    VehicleDamageItemTypeEnum["INTERIOR_REAR_LEFT_SEAT"] = "rear_left_seat";
    VehicleDamageItemTypeEnum["INTERIOR_REAR_RIGHT_SEAT"] = "rear_right_seat";
    VehicleDamageItemTypeEnum["INTERIOR_REAR_CENTER_SEAT"] = "rear_center_seat";
    VehicleDamageItemTypeEnum["INTERIOR_DASHBOARD"] = "interior_dashboard";
    VehicleDamageItemTypeEnum["INTERIOR_STEERING_WHEEL"] = "interior_steering_wheel";
    VehicleDamageItemTypeEnum["INTERIOR_CONSOLE"] = "interior_console";
    VehicleDamageItemTypeEnum["INTERIOR_REAR_VIEW_MIRROR"] = "rear_view_mirror";
    VehicleDamageItemTypeEnum["INTERIOR_HEADLINER"] = "headliner";
    VehicleDamageItemTypeEnum["INTERIOR_CARPETS"] = "interior_carpet";
    VehicleDamageItemTypeEnum["GLASS_WINDSHIELD"] = "windshield";
    VehicleDamageItemTypeEnum["GLASS_REAR_WINDOW"] = "rear_window";
    VehicleDamageItemTypeEnum["GLASS_LEFT_FRONT_WINDOW"] = "left_front_window";
    VehicleDamageItemTypeEnum["GLASS_RIGHT_FRONT_WINDOW"] = "right_front_window";
    VehicleDamageItemTypeEnum["GLASS_LEFT_REAR_WINDOW"] = "left_rear_window";
    VehicleDamageItemTypeEnum["GLASS_RIGHT_REAR_WINDOW"] = "right_rear_window";
    VehicleDamageItemTypeEnum["MECHANICAL_FRONT_SUSPENSION"] = "front_suspension";
    VehicleDamageItemTypeEnum["MECHANICAL_REAR_SUSPENSION"] = "rear_suspension";
    VehicleDamageItemTypeEnum["MECHANICAL_ENGINE"] = "engine";
    VehicleDamageItemTypeEnum["MECHANICAL_TRANSMISSION"] = "transmission";
    VehicleDamageItemTypeEnum["MECHANICAL_EXHAUST"] = "exhaust";
    VehicleDamageItemTypeEnum["WHEEL_FRONT_LEFT"] = "front_left_wheel";
    VehicleDamageItemTypeEnum["WHEEL_FRONT_RIGHT"] = "front_right_wheel";
    VehicleDamageItemTypeEnum["WHEEL_REAR_LEFT"] = "rear_left_wheel";
    VehicleDamageItemTypeEnum["WHEEL_REAR_RIGHT"] = "rear_right_wheel";
    VehicleDamageItemTypeEnum["TIRE_FRONT_LEFT"] = "front_left_tire";
    VehicleDamageItemTypeEnum["TIRE_FRONT_RIGHT"] = "front_right_tire";
    VehicleDamageItemTypeEnum["TIRE_REAR_LEFT"] = "rear_left_tire";
    VehicleDamageItemTypeEnum["TIRE_REAR_RIGHT"] = "rear_right_tire";
    VehicleDamageItemTypeEnum["OTHER"] = "other";
})(VehicleDamageItemTypeEnum || (exports.VehicleDamageItemTypeEnum = VehicleDamageItemTypeEnum = {}));
