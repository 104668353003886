<div
	class="whitespace-nowrap w-full flex items-center justify-between h-10 min-h-10 px-1 rounded-md cursor-pointer hover:bg-hover"
	(click)="onShowSection()">
	<div class="flex items-center">
		<mat-icon
			[ngClass]="iconClass"
			*ngIf="svgIcon"
			class="icon-size-5 mr-1.5"
			[svgIcon]="svgIcon"></mat-icon>
		<span *ngIf="content" class="text-md"> {{ content }}</span>
	</div>

	<mat-icon
		*ngIf="type === 'aside'"
		class="icon-size-4 bg-gray-100 rounded-md"
		[svgIcon]="
			showSection ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
		"></mat-icon>
</div>

<div
	*ngIf="showSection && type === 'aside'"
	class="flex flex-col items-start w-full overflow-y-auto mt-0.5 gap-0.5 bg-gray-50 border border-gray-200 rounded-md">
	<ng-content></ng-content>
</div>
