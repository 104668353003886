"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerSearchDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_search_dto_1 = require("../../shared/dtos/base-search.dto");
class CustomerSearchDto extends base_search_dto_1.BaseSearchDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.CUSTOMER;
    }
    code;
    recordCategory;
    status;
    contactShortName;
    driverId;
    driverIdentityDocumentType;
    driverIdentityDocumentNumber;
    driverIdentityDocumentExpiryDate;
    driverDrivingLicenseNumber;
    driverDrivingLicenseCategory;
    driverDrivingLicenseExpiryDate;
    avatarFileUrl;
    displayValue() {
        return `${this.code}${this.contactShortName ? ` ${this.contactShortName}` : ''}`;
    }
    shortDisplayValue() {
        return this.code;
    }
    extraDisplayValue() {
        return null;
    }
    pillDisplayValue() {
        return this.status;
    }
}
exports.CustomerSearchDto = CustomerSearchDto;
