"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehicleRoadTaxStatusToColor = void 0;
const vehicle_road_tax_status_enum_1 = require("../../vehicle-road-taxes/enums/vehicle-road-tax-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function vehicleRoadTaxStatusToColor(status) {
    switch (status) {
        case vehicle_road_tax_status_enum_1.VehicleRoadTaxStatusEnum.PENDING:
            return record_color_enum_1.RecordColorEnum.AMBER;
        case vehicle_road_tax_status_enum_1.VehicleRoadTaxStatusEnum.APPROVED:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case vehicle_road_tax_status_enum_1.VehicleRoadTaxStatusEnum.REJECTED:
            return record_color_enum_1.RecordColorEnum.ROSE;
        case vehicle_road_tax_status_enum_1.VehicleRoadTaxStatusEnum.PAID:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        case vehicle_road_tax_status_enum_1.VehicleRoadTaxStatusEnum.EXPIRED:
            return record_color_enum_1.RecordColorEnum.RED;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.vehicleRoadTaxStatusToColor = vehicleRoadTaxStatusToColor;
