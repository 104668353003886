"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehicleRoadWorthyCheckStatusToColor = void 0;
const vehicle_road_worthy_check_status_enum_1 = require("../../vehicle-road-worthy-checks/enums/vehicle-road-worthy-check-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function vehicleRoadWorthyCheckStatusToColor(status) {
    switch (status) {
        case vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.PENDING:
            return record_color_enum_1.RecordColorEnum.AMBER;
        case vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.APPROVED:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.REJECTED:
            return record_color_enum_1.RecordColorEnum.ROSE;
        case vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.SCHEDULED:
            return record_color_enum_1.RecordColorEnum.TEAL;
        case vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.IN_PROGRESS:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.PAID:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        case vehicle_road_worthy_check_status_enum_1.VehicleRoadWorthyCheckStatusEnum.EXPIRED:
            return record_color_enum_1.RecordColorEnum.RED;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.vehicleRoadWorthyCheckStatusToColor = vehicleRoadWorthyCheckStatusToColor;
