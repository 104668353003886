"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageFaultEnum = void 0;
var VehicleDamageFaultEnum;
(function (VehicleDamageFaultEnum) {
    VehicleDamageFaultEnum["DRIVER"] = "driver";
    VehicleDamageFaultEnum["THIRD_PARTY"] = "third_party";
    VehicleDamageFaultEnum["SHARED"] = "shared";
    VehicleDamageFaultEnum["VEHICLE_MALFUNCTION"] = "vehicle_malfunction";
    VehicleDamageFaultEnum["STAFF"] = "staff";
    VehicleDamageFaultEnum["ENVIRONMENTAL"] = "environmental";
    VehicleDamageFaultEnum["UNKNOWN"] = "unknown";
})(VehicleDamageFaultEnum || (exports.VehicleDamageFaultEnum = VehicleDamageFaultEnum = {}));
