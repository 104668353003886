"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseSearchDto = void 0;
class BaseSearchDto {
    id;
    color;
    avatarFile;
    isDeleted;
    isDraft;
}
exports.BaseSearchDto = BaseSearchDto;
