"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canPerformAction = void 0;
const set_status_schema_1 = require("../../../shared/validators/schemas/set-status.schema");
const vehicle_maintenance_action_enum_1 = require("../../enums/vehicle-maintenance-action.enum");
const vehicle_maintenance_status_enum_1 = require("../../enums/vehicle-maintenance-status.enum");
const canPerformAction = (record, action, abortEarly = false) => {
    if (!record)
        throw new Error('record is required.');
    let validationResult;
    const fixedStatuses = [
        vehicle_maintenance_status_enum_1.VehicleMaintenanceStatusEnum.APPROVED,
        vehicle_maintenance_status_enum_1.VehicleMaintenanceStatusEnum.COMPLETED,
        vehicle_maintenance_status_enum_1.VehicleMaintenanceStatusEnum.PENDING,
        vehicle_maintenance_status_enum_1.VehicleMaintenanceStatusEnum.REJECTED,
    ];
    switch (action) {
        case vehicle_maintenance_action_enum_1.VehicleMaintenanceActionEnum.APPROVE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_maintenance_status_enum_1.VehicleMaintenanceStatusEnum.APPROVED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_maintenance_action_enum_1.VehicleMaintenanceActionEnum.REJECT:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_maintenance_status_enum_1.VehicleMaintenanceStatusEnum.REJECTED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_maintenance_action_enum_1.VehicleMaintenanceActionEnum.COMPLETE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_maintenance_status_enum_1.VehicleMaintenanceStatusEnum.COMPLETED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_maintenance_action_enum_1.VehicleMaintenanceActionEnum.PENDING:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_maintenance_status_enum_1.VehicleMaintenanceStatusEnum.PENDING, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_maintenance_action_enum_1.VehicleMaintenanceActionEnum.CHECK_OUT:
            break;
        case vehicle_maintenance_action_enum_1.VehicleMaintenanceActionEnum.CHECK_IN:
            break;
        case vehicle_maintenance_action_enum_1.VehicleMaintenanceActionEnum.SCHEDULE:
            break;
        default:
            throw new Error(`Unknown action: ${action}`);
    }
    if (validationResult.error) {
        return {
            isValid: false,
            errors: validationResult.error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.canPerformAction = canPerformAction;
