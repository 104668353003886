"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataExportResultDto = void 0;
class DataExportResultDto {
    requestId;
    dateRequested;
    dateGenerated;
    filename;
    contentType;
    content;
}
exports.DataExportResultDto = DataExportResultDto;
