"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidCustomerDriver = void 0;
const customers_1 = require("../../../customers");
const update_company_customer_driver_schema_1 = require("../schemas/update-company-customer-driver.schema");
const update_individual_customer_driver_schema_1 = require("../schemas/update-individual-customer-driver.schema");
const isValidCustomerDriver = (driver, customerRecordCategory, abortEarly = false) => {
    if (!driver)
        throw new Error('driver is required');
    switch (customerRecordCategory) {
        case customers_1.CustomerRecordCategoryEnum.INDIVIDUAL: {
            const { error } = (0, update_individual_customer_driver_schema_1.updateIndividualCustomerDriverSchema)().validate(driver, {
                abortEarly,
            });
            if (error) {
                return {
                    isValid: false,
                    errors: error.details?.map((e) => e.message),
                };
            }
            break;
        }
        case customers_1.CustomerRecordCategoryEnum.COMPANY: {
            const { error } = (0, update_company_customer_driver_schema_1.updateCompanyCustomerDriverSchema)().validate(driver, {
                abortEarly,
            });
            if (error) {
                return {
                    isValid: false,
                    errors: error.details?.map((e) => e.message),
                };
            }
            break;
        }
    }
    return { isValid: true };
};
exports.isValidCustomerDriver = isValidCustomerDriver;
