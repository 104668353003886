"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canPerformAction = void 0;
const set_status_schema_1 = require("../../../shared/validators/schemas/set-status.schema");
const vehicle_damage_action_enum_1 = require("../../enums/vehicle-damage-action.enum");
const vehicle_damage_status_enum_1 = require("../../enums/vehicle-damage-status.enum");
const canPerformAction = (record, action, abortEarly = false) => {
    if (!record)
        throw new Error('record is required.');
    let validationResult;
    const fixedStatuses = [
        vehicle_damage_status_enum_1.VehicleDamageStatusEnum.APPROVED,
        vehicle_damage_status_enum_1.VehicleDamageStatusEnum.PENDING,
        vehicle_damage_status_enum_1.VehicleDamageStatusEnum.REJECTED,
        vehicle_damage_status_enum_1.VehicleDamageStatusEnum.IGNORED,
        vehicle_damage_status_enum_1.VehicleDamageStatusEnum.REPAIRED,
    ];
    switch (action) {
        case vehicle_damage_action_enum_1.VehicleDamageActionEnum.APPROVE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_damage_status_enum_1.VehicleDamageStatusEnum.APPROVED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_damage_action_enum_1.VehicleDamageActionEnum.REJECT:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_damage_status_enum_1.VehicleDamageStatusEnum.REJECTED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_damage_action_enum_1.VehicleDamageActionEnum.IGNORE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_damage_status_enum_1.VehicleDamageStatusEnum.IGNORED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_damage_action_enum_1.VehicleDamageActionEnum.PENDING:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_damage_status_enum_1.VehicleDamageStatusEnum.PENDING, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_damage_action_enum_1.VehicleDamageActionEnum.REPAIR:
            validationResult = (0, set_status_schema_1.setStatusSchema)(vehicle_damage_status_enum_1.VehicleDamageStatusEnum.REPAIRED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case vehicle_damage_action_enum_1.VehicleDamageActionEnum.CREATE_MAINTENANCE:
            break;
        case vehicle_damage_action_enum_1.VehicleDamageActionEnum.ESTIMATE:
            break;
        default:
            throw new Error(`Unknown action: ${action}`);
    }
    if (validationResult.error) {
        return {
            isValid: false,
            errors: validationResult.error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.canPerformAction = canPerformAction;
