"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingProfilePricingTagDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class PricingProfilePricingTagDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.PRICING_PROFILE;
    }
    pricingProfileId;
    tagId;
    calculationType;
    value;
}
exports.PricingProfilePricingTagDto = PricingProfilePricingTagDto;
