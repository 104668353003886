"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordSectionDto = void 0;
class RecordSectionDto {
    displayOrder;
    isCollapsible;
    isVisible;
    isCollapsed;
    name;
    description;
    supportsTimeMachine;
}
exports.RecordSectionDto = RecordSectionDto;
