"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toFilenameDate = void 0;
function toFilenameDate(date) {
    if (!date) {
        date = new Date();
    }
    return date.toISOString().split('.')[0].replace('T', '_').replace(/:/g, '-');
}
exports.toFilenameDate = toFilenameDate;
