"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadWorthyCheckStatusEnum = void 0;
var VehicleRoadWorthyCheckStatusEnum;
(function (VehicleRoadWorthyCheckStatusEnum) {
    VehicleRoadWorthyCheckStatusEnum["PENDING"] = "pending";
    VehicleRoadWorthyCheckStatusEnum["APPROVED"] = "approved";
    VehicleRoadWorthyCheckStatusEnum["REJECTED"] = "rejected";
    VehicleRoadWorthyCheckStatusEnum["SCHEDULED"] = "scheduled";
    VehicleRoadWorthyCheckStatusEnum["IN_PROGRESS"] = "in_progress";
    VehicleRoadWorthyCheckStatusEnum["PAID"] = "paid";
    VehicleRoadWorthyCheckStatusEnum["EXPIRED"] = "expired";
})(VehicleRoadWorthyCheckStatusEnum || (exports.VehicleRoadWorthyCheckStatusEnum = VehicleRoadWorthyCheckStatusEnum = {}));
