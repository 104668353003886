"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierRecordCategoryEnum = void 0;
var SupplierRecordCategoryEnum;
(function (SupplierRecordCategoryEnum) {
    SupplierRecordCategoryEnum["VEHICLES"] = "vehicles";
    SupplierRecordCategoryEnum["SPARE_PARTS"] = "spare_parts";
    SupplierRecordCategoryEnum["TIRES"] = "tires";
    SupplierRecordCategoryEnum["ACCESSORIES"] = "accessories";
    SupplierRecordCategoryEnum["MAINTENANCE"] = "maintenance";
    SupplierRecordCategoryEnum["INSURANCE"] = "insurance";
    SupplierRecordCategoryEnum["OTHER"] = "other";
})(SupplierRecordCategoryEnum || (exports.SupplierRecordCategoryEnum = SupplierRecordCategoryEnum = {}));
