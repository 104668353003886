import { BooleanInput } from '@angular/cdk/coercion';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subject, takeUntil } from 'rxjs';
import { UserRecordDto } from 'ultramarine-shared';

import { AuthService } from '../../../core/auth/auth.service';

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
	// ------------------------------------------------------------------------
	// @ Input properties
	// ------------------------------------------------------------------------
	static ngAcceptInputType_showAvatar: BooleanInput;
	@Input() showAvatar: boolean = true;

	// ------------------------------------------------------------------------
	// @ Public properties
	// ------------------------------------------------------------------------
	user: UserRecordDto;

	// ------------------------------------------------------------------------
	// @ Private properties
	// ------------------------------------------------------------------------
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _logger: NGXLogger,
		private _changeDetectorRef: ChangeDetectorRef,
		private _router: Router,
		private _authService: AuthService,
	) {}

	// ------------------------------------------------------------------------
	// @ Lifecycle hooks
	// ------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to services
		this._authService.user$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((user: UserRecordDto) => {
				// Log
				this._logger.debug('_userService.user$ > user:', user);

				this.user = user;

				// Mark for check
				this._changeDetectorRef.markForCheck();
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Show user
	 */
	showUser(): void {
		this._router.navigate([`admin/users/${this.user.id}`]);
	}

	/**
	 * Sign out
	 */
	signOut(): void {
		this._router.navigate(['admin-authentication/sign-out']);
	}
}
