"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rentalReservationStatusToColor = void 0;
const rental_reservation_status_enum_1 = require("../../rental-reservations/enums/rental-reservation-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function rentalReservationStatusToColor(status) {
    switch (status) {
        case rental_reservation_status_enum_1.RentalReservationStatusEnum.PENDING:
            return record_color_enum_1.RecordColorEnum.AMBER;
        case rental_reservation_status_enum_1.RentalReservationStatusEnum.CONFIRMED:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case rental_reservation_status_enum_1.RentalReservationStatusEnum.CANCELLED:
            return record_color_enum_1.RecordColorEnum.SLATE;
        case rental_reservation_status_enum_1.RentalReservationStatusEnum.COMPLETED:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case rental_reservation_status_enum_1.RentalReservationStatusEnum.NO_SHOW:
            return record_color_enum_1.RecordColorEnum.SLATE;
        case rental_reservation_status_enum_1.RentalReservationStatusEnum.EXPIRED:
            return record_color_enum_1.RecordColorEnum.RED;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.rentalReservationStatusToColor = rentalReservationStatusToColor;
