import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	FuseNavigationService,
	FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { UltraButtonComponent } from '../../../../shared/components/ultramarine-ui/ultra-button/ultra-button.component';
import { UserPortalComponent } from '../../../common/user-portal/user-portal.component';
import { UltraNavigationComponent } from '../../../../shared/components/ultramarine-ui/ultra-navigation/ultra-navigation.component';

@Component({
	selector: 'thin-layout',
	templateUrl: './thin.component.html',
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class ThinLayoutComponent implements OnInit, OnDestroy {
	isScreenSmall: boolean;
	navigation: Navigation;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	showFooter: boolean;
	showSideMenu: boolean = true;
	public drawerMode: 'over' | 'side';
	/**
	 * Constructor
	 */
	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _navigationService: NavigationService,
		private _fuseMediaWatcherService: FuseMediaWatcherService,
		private _fuseNavigationService: FuseNavigationService,
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for current year
	 */
	get currentYear(): number {
		return new Date().getFullYear();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to navigation data
		this._navigationService.navigation$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((navigation: Navigation) => {
				this.navigation = navigation;
			});

		// Subscribe to media changes
		this._fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Check if the screen is small
				this.isScreenSmall = !matchingAliases.includes('md');
				if (this.isScreenSmall) {
					this.showSideMenu = false;
					this.drawerMode = 'over';
				} else {
					this.showSideMenu = true;
					this.drawerMode = 'side';
				}
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	/**
	 * Toggle navigation
	 *
	 * @param name
	 */
	toggleNavigation(name: string): void {
		// Get the navigation
		const navigation =
			this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				name,
			);

		if (navigation) {
			// Toggle the opened status
			navigation.toggle();
		}
	}

	toggleSideMenu() {
		this.showSideMenu = !this.showSideMenu;
	}
}
