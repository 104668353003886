"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileListDto = void 0;
const filesize_1 = require("filesize");
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class FileListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.FILE;
    }
    name;
    size;
    mimeType;
    status;
    sensitivity;
    subject;
    url;
    tags;
    mobileTitle = () => {
        return `${this.name} ${(0, filesize_1.filesize)(this.size)}`;
    };
    mobileSubtitle = () => {
        return '';
    };
}
exports.FileListDto = FileListDto;
