"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehicleDamageStatusToColor = void 0;
const vehicle_damage_status_enum_1 = require("../../vehicle-damages/enums/vehicle-damage-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function vehicleDamageStatusToColor(status) {
    switch (status) {
        case vehicle_damage_status_enum_1.VehicleDamageStatusEnum.PENDING:
            return record_color_enum_1.RecordColorEnum.AMBER;
        case vehicle_damage_status_enum_1.VehicleDamageStatusEnum.APPROVED:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case vehicle_damage_status_enum_1.VehicleDamageStatusEnum.REJECTED:
            return record_color_enum_1.RecordColorEnum.ROSE;
        case vehicle_damage_status_enum_1.VehicleDamageStatusEnum.ESTIMATED:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case vehicle_damage_status_enum_1.VehicleDamageStatusEnum.REPAIRED:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        case vehicle_damage_status_enum_1.VehicleDamageStatusEnum.IGNORED:
            return record_color_enum_1.RecordColorEnum.SLATE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.vehicleDamageStatusToColor = vehicleDamageStatusToColor;
