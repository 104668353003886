"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordSection = void 0;
class RecordSection {
    displayOrder;
    isCollapsible;
    isVisible;
    isCollapsed;
    name;
    description;
    supportsTimeMachine;
}
exports.RecordSection = RecordSection;
