"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidCustomer = void 0;
const rental_reservation_customer_schema_1 = require("../schemas/rental-reservation-customer.schema");
const isValidCustomer = (customer, abortEarly = false) => {
    if (!customer)
        throw new Error('customer is required');
    const { error } = (0, rental_reservation_customer_schema_1.rentalReservationCustomerSchema)().validate(customer, {
        abortEarly,
    });
    if (error) {
        return {
            isValid: false,
            errors: error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.isValidCustomer = isValidCustomer;
