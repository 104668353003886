"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseOrderRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class LeaseOrderRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.LEASE_ORDER;
    }
    documentNumber;
    qrCode;
    status;
    statusReason;
    recordCategory;
    orderedAt;
    vehicleId;
    customerId;
    pricingItems;
    tags;
    avatarFile;
    files;
}
exports.LeaseOrderRecordDto = LeaseOrderRecordDto;
