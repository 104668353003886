"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiPaginatedResponse = void 0;
const api_response_1 = require("./api-response");
class ApiPaginatedResponse extends api_response_1.ApiResponse {
    page;
    pageSize;
    totalCount;
    totalPages;
    hasPreviousPage;
    hasNextPage;
}
exports.ApiPaginatedResponse = ApiPaginatedResponse;
