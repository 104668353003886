"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiRequestFilters = void 0;
class ApiRequestFilters {
    query;
    queryIds;
    queryCaseSensitive;
    queryMatchExact;
}
exports.ApiRequestFilters = ApiRequestFilters;
