"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryDto = void 0;
const base_dto_1 = require("../../shared/dtos/base.dto");
class CountryDto extends base_dto_1.BaseDto {
    name;
    isoCode;
    isVisible;
}
exports.CountryDto = CountryDto;
