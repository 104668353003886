"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class AccountRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.ACCOUNT;
    }
    accountId;
    schemaName;
    subdomain;
    status;
    statusReason;
    recordCategory;
    authTwoFactorEnabled;
    authTwoFactorMethod;
    authTwoFactorPhoneMobile;
    authTwoFactorEmail;
    authTwoFactorSecretKey;
    authTwoFactorRecoveryCodes;
    authExpirePasswords;
    authExpirePasswordsPeriod;
    contact;
    avatarFile;
    files;
}
exports.AccountRecordDto = AccountRecordDto;
