"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingProfilePricingPropertyEnum = void 0;
var PricingProfilePricingPropertyEnum;
(function (PricingProfilePricingPropertyEnum) {
    PricingProfilePricingPropertyEnum["LOCATION"] = "location";
    PricingProfilePricingPropertyEnum["CUSTOMER_TYPE"] = "customer_type";
    PricingProfilePricingPropertyEnum["VEHICLE_TYPE"] = "vehicle_type";
    PricingProfilePricingPropertyEnum["VEHICLE_CATEGORY"] = "vehicle_category";
    PricingProfilePricingPropertyEnum["VEHICLE_BODY_TYPE"] = "vehicle_body_type";
    PricingProfilePricingPropertyEnum["VEHICLE_TRANSMISSION_TYPE"] = "vehicle_transmission_type";
    PricingProfilePricingPropertyEnum["VEHICLE_FUEL_TYPE"] = "vehicle_fuel_type";
    PricingProfilePricingPropertyEnum["VEHICLE_DRIVE_TRAIN_TYPE"] = "vehicle_drive_train_type";
    PricingProfilePricingPropertyEnum["VEHICLE_POWER_PLANT_TYPE"] = "vehicle_power_plant_type";
})(PricingProfilePricingPropertyEnum || (exports.PricingProfilePricingPropertyEnum = PricingProfilePricingPropertyEnum = {}));
