"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadWorthyChecksAppOptionsDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_app_options_dto_1 = require("../../shared/dtos/base-app-options.dto");
class VehicleRoadWorthyChecksAppOptionsDto extends base_app_options_dto_1.BaseAppOptionsDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_ROAD_WORTHY_CHECK;
    }
    listPageSize;
    listPageSizes;
    listDefaultSort;
    listColumns;
    recordSections;
    recordTabs;
}
exports.VehicleRoadWorthyChecksAppOptionsDto = VehicleRoadWorthyChecksAppOptionsDto;
