"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerStatusToColor = void 0;
const customer_status_enum_1 = require("../../customers/enums/customer-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function customerStatusToColor(status) {
    switch (status) {
        case customer_status_enum_1.CustomerStatusEnum.ACTIVE:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case customer_status_enum_1.CustomerStatusEnum.ON_HOLD:
            return record_color_enum_1.RecordColorEnum.RED;
        case customer_status_enum_1.CustomerStatusEnum.CLOSED:
            return record_color_enum_1.RecordColorEnum.SLATE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.customerStatusToColor = customerStatusToColor;
