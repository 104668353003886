"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canCreateOrUpdate = void 0;
const vehicle_maintenance_item_schema_1 = require("../schemas/vehicle-maintenance-item.schema");
const canCreateOrUpdate = (record, abortEarly = false) => {
    if (!record)
        throw new Error('record is required.');
    const { error } = (0, vehicle_maintenance_item_schema_1.vehicleMaintenanceItemSchema)().validate(record, {
        abortEarly,
    });
    if (error) {
        return {
            isValid: false,
            errors: error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.canCreateOrUpdate = canCreateOrUpdate;
