<div
	@slideInBottom
	class="p-4 border border-gray-300 rounded-2xl shadow-md bg-white">
	<div class="flex gap-6 items-center">
		<span class="flex items-center gap-1">
			<mat-icon class="icon-size-5" [svgIcon]="'approval'"></mat-icon>
			<span>Confrim this action</span></span
		>
		<div class="flex gap-1 items-center">
			<button [content]="'No'" ultra-button (click)="cancellation()"></button>
			<span [content]="'Yes'" ultra-button (click)="confirmation()"></span>
		</div>
	</div>
</div>
