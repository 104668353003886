<button
	ultra-button
	[svgIcon]="'account_circle'"
	#anchor
	(click)="showUserMenu()"
	class="cursor-pointer hover:bg-gray-200"></button>

<ng-template #templateRef>
	<app-user-menu></app-user-menu>
</ng-template>
