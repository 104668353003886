"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleColorSearchDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_search_dto_1 = require("../../shared/dtos/base-search.dto");
class VehicleColorSearchDto extends base_search_dto_1.BaseSearchDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    name;
    type;
    externalReference;
    displayValue() {
        return `${this.name}`;
    }
    shortDisplayValue() {
        return `${this.name}`;
    }
    extraDisplayValue() {
        return null;
    }
    pillDisplayValue() {
        return `${this.type}`;
    }
}
exports.VehicleColorSearchDto = VehicleColorSearchDto;
