import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash';

import { rentalPercentageUtilisationDetails } from './data';

@Injectable({
	providedIn: 'root',
})
export class ReportDetailsApi {
	private _rentalPercentageUtilisationDetails =
		rentalPercentageUtilisationDetails;

	constructor(private _fuseMockApiService: FuseMockApiService) {
		this.registerHandlers();
	}

	registerHandlers(): void {
		this._fuseMockApiService
			.onGet('api/reports/rental-percentage-utilisation')
			.reply(({ request }) => {
				return [200, cloneDeep(this._rentalPercentageUtilisationDetails)];
			});
	}
}
