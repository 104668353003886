"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseAgreementStatusEnum = void 0;
var LeaseAgreementStatusEnum;
(function (LeaseAgreementStatusEnum) {
    LeaseAgreementStatusEnum["PENDING"] = "pending";
    LeaseAgreementStatusEnum["APPROVED"] = "approved";
    LeaseAgreementStatusEnum["REJECTED"] = "rejected";
    LeaseAgreementStatusEnum["IN_PROGRESS"] = "in_progress";
    LeaseAgreementStatusEnum["CANCELLED"] = "cancelled";
    LeaseAgreementStatusEnum["COMPLETED"] = "completed";
})(LeaseAgreementStatusEnum || (exports.LeaseAgreementStatusEnum = LeaseAgreementStatusEnum = {}));
