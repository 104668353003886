"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationRecordCategoryEnum = void 0;
var LocationRecordCategoryEnum;
(function (LocationRecordCategoryEnum) {
    LocationRecordCategoryEnum["OFFICE"] = "office";
    LocationRecordCategoryEnum["WORKSHOP"] = "workshop";
    LocationRecordCategoryEnum["BODYSHOP"] = "bodyshop";
    LocationRecordCategoryEnum["PARKING_LOT"] = "parking_lot";
    LocationRecordCategoryEnum["DROP_OFF_PICK_UP"] = "drop_off_pick_up";
})(LocationRecordCategoryEnum || (exports.LocationRecordCategoryEnum = LocationRecordCategoryEnum = {}));
