"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class LocationListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.LOCATION;
    }
    code;
    name;
    status;
    recordCategory;
    contact;
    tags;
    avatarFileUrl;
    mobileTitle = () => {
        return `${this.code}` + `${this.name ? ' - ' + this.name : ''}`;
    };
    mobileSubtitle = () => {
        const email = this.contact?.email;
        const phoneMobile = this.contact?.phoneMobile;
        return (`${phoneMobile ? phoneMobile : ''}` + `${email ? ' / ' + email : ''}`);
    };
}
exports.LocationListDto = LocationListDto;
