"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageSeverityEnum = void 0;
var VehicleDamageSeverityEnum;
(function (VehicleDamageSeverityEnum) {
    VehicleDamageSeverityEnum["MINOR"] = "minor";
    VehicleDamageSeverityEnum["MODERATE"] = "moderate";
    VehicleDamageSeverityEnum["MAJOR"] = "major";
    VehicleDamageSeverityEnum["TOTAL_LOSS"] = "total_loss";
})(VehicleDamageSeverityEnum || (exports.VehicleDamageSeverityEnum = VehicleDamageSeverityEnum = {}));
