"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalAgreementListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
const contact_to_short_name_function_1 = require("../../shared/functions/contact-to-short-name.function");
class RentalAgreementListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.RENTAL_AGREEMENT;
    }
    documentNumber;
    qrCode;
    status;
    recordCategory;
    startsAt;
    endsAt;
    option;
    vehicle;
    customer;
    rentalReservation;
    tags;
    avatarFileUrl;
    computedPeriod;
    computedPeriodOverdue;
    computedTotalNet;
    computedTotalTax;
    computedTotalGross;
    mobileTitle = () => {
        const contactName = (0, contact_to_short_name_function_1.contactToShortName)(this.customer?.contact);
        return `${this.documentNumber}${contactName ? ' - ' + contactName : ''}`;
    };
    mobileSubtitle = () => {
        const vehicle = this.vehicle?.licensePlate
            ? this.vehicle.licensePlate
            : this.vehicle?.vin
                ? this.vehicle.vin
                : '';
        const period = this.computedPeriod ? `${this.computedPeriod}` : '';
        const totalGross = this.computedTotalGross
            ? `${this.computedTotalGross} €`
            : '';
        return (`${vehicle}` +
            `${period ? ' / ' + period : ''}` +
            `${totalGross ? ' / ' + totalGross : ''}`);
    };
}
exports.RentalAgreementListDto = RentalAgreementListDto;
