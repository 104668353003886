import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { COMPACT_NAVIGATION } from 'app/mock-api/navigation/data';
import { cloneDeep } from 'lodash-es';

@Injectable({
	providedIn: 'root',
})
export class NavigationMockApi {
	private readonly _compactNavigation: FuseNavigationItem[] =
		COMPACT_NAVIGATION;

	/**
	 * Constructor
	 */
	constructor(private _fuseMockApiService: FuseMockApiService) {
		// Register Mock API handlers
		this.registerHandlers();
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Register Mock API handlers
	 */
	registerHandlers(): void {
		// ------------------------------------------------------------------------
		// @ Navigation - GET
		// ------------------------------------------------------------------------
		this._fuseMockApiService.onGet('api/navigation').reply(() => {
			// Return the response
			return [
				200,
				{
					default: cloneDeep(this._compactNavigation),
				},
			];
		});
	}
}
