export function randomizeAvailableCarsdata(queryParams, availableCarsData) {
	const randomizedAvailableCarsData = {
		currentDate: queryParams.get('date'),
		currentValue: Math.floor(Math.random() * 10000),
		previousDate: new Date(2022, 0, 1),
		previousValue: Math.floor(Math.random() * 10000),
		differenceValue: Math.floor(Math.random() * 100),
	};
	return randomizedAvailableCarsData;
}

export function CompareRangeReturnData() {}
