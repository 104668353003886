"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleOdometerChangeReasonEnum = void 0;
var VehicleOdometerChangeReasonEnum;
(function (VehicleOdometerChangeReasonEnum) {
    VehicleOdometerChangeReasonEnum["INITIAL"] = "initial";
    VehicleOdometerChangeReasonEnum["ADJUSTMENT"] = "adjustment";
    VehicleOdometerChangeReasonEnum["MAINTENANCE"] = "maintenance";
    VehicleOdometerChangeReasonEnum["RENTAL"] = "rental";
    VehicleOdometerChangeReasonEnum["LEASE"] = "lease";
    VehicleOdometerChangeReasonEnum["TRANSFER"] = "transfer";
    VehicleOdometerChangeReasonEnum["AUDIT"] = "audit";
})(VehicleOdometerChangeReasonEnum || (exports.VehicleOdometerChangeReasonEnum = VehicleOdometerChangeReasonEnum = {}));
