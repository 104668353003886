import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppNotification } from 'app/core/notifications/notifications.types';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { ApiResponse } from 'ultramarine-shared';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	private _list$: BehaviorSubject<ApiResponse<AppNotification[]>> =
		new BehaviorSubject<ApiResponse<AppNotification[]>>(null);

	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private _logger: NGXLogger,
	) {}

	// ------------------------------------------------------------------------
	// @ Accessors
	// ------------------------------------------------------------------------

	/**
	 * Getter for notifications
	 */
	get list$(): Observable<ApiResponse<AppNotification[]>> {
		return this._list$.asObservable();
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Load all notifications
	 */
	load$(): Observable<any> {
		return this._httpClient.get<AppNotification[]>('api/notifications').pipe(
			tap((notifications) => {
				this._list$.next(notifications);
			}),
		);
	}

	/**
	 * Create a notification
	 */
	createRecord(
		record: AppNotification,
	): Observable<ApiResponse<AppNotification>> {
		// Log
		this._logger.info('createRecord > called with record: ', record);

		return this._httpClient
			.post<ApiResponse<AppNotification>>(`api/notifications`, record)
			.pipe(
				tap((response: ApiResponse<AppNotification>) => {
					// Log
					this._logger.info('createRecord > response: ', response);

					// Store the data
					const notifications = this._list$.getValue();

					notifications.data.push(response.data);

					this._list$.next(notifications);
				}),
				catchError((error: any) => {
					// Log
					this._logger.error('createRecord > error: ', error);

					// Re-throw the error
					return throwError(
						() =>
							new Error(
								'An error occurred while fetching data from the server',
							),
					);
				}),
			);
	}

	/**
	 * Update a notification
	 *
	 * @param record
	 */
	updateRecord(
		record: AppNotification,
	): Observable<ApiResponse<AppNotification>> {
		// Log
		this._logger.info('updateRecord > called with record:', record);

		return this._httpClient
			.patch<
				ApiResponse<AppNotification>
			>(`api/notifications/${record.id}`, record)
			.pipe(
				tap((response: ApiResponse<AppNotification>) => {
					// Log
					this._logger.info('updateRecord > response: ', response);

					// Update the data
					const notifications = this._list$.getValue();

					const index = notifications.data.findIndex(
						(notification) => notification.id === record.id,
					);

					if (index !== -1) {
						notifications.data[index] = response.data;
					}

					this._list$.next(notifications);
				}),
				catchError((error: any) => {
					// Log
					this._logger.error('updateRecord > error: ', error);

					// Re-throw the error
					return throwError(
						() =>
							new Error(
								'An error occurred while fetching data from the server',
							),
					);
				}),
			);
	}

	/**
	 * Delete a notification
	 *
	 * @param id
	 */
	deleteRecord(id: number): Observable<ApiResponse<AppNotification>> {
		// Log
		this._logger.info('deleteRecord > called with id: ', id);

		return this._httpClient
			.delete<ApiResponse<AppNotification>>(`api/notifications/${id}`)
			.pipe(
				tap((response: ApiResponse<AppNotification>) => {
					// Log
					this._logger.info('deleteRecord > response: ', response);

					// Delete the data
					const notifications = this._list$.getValue();

					const updatedNotifications = notifications.data.filter(
						(notification) => {
							return notification.id !== id;
						},
					);

					this._list$.next({ ...notifications, data: updatedNotifications });
				}),
				catchError((error: any) => {
					// Log
					this._logger.error('deleteRecord > error: ', error);

					// Re-throw the error
					return throwError(
						() =>
							new Error(
								'An error occurred while fetching data from the server',
							),
					);
				}),
			);
	}

	/**
	 * Mark all notifications as read
	 */
	toggleAllRead(flag: boolean): Observable<ApiResponse<boolean>> {
		// Log
		this._logger.info('toggleAllRead > called');

		return this._httpClient
			.patch<ApiResponse<boolean>>('api/notifications/toggle-all-read', flag)
			.pipe(
				tap((response) => {
					// Log
					this._logger.info('toggleAllRead > response: ', response);

					// Update the data
					const notifications = this._list$.getValue();

					const updatedNotifications = notifications.data.map(
						(notification) => {
							notification.isRead = flag;
							return notification;
						},
					);

					this._list$.next({ ...notifications, data: updatedNotifications });
				}),
				catchError((error: any) => {
					// Log
					this._logger.error('toggleAllRead > error: ', error);

					// Re-throw the error
					return throwError(
						() =>
							new Error(
								'An error occurred while fetching data from the server',
							),
					);
				}),
			);
	}
}
