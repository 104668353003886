"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalAgreementActionEnum = void 0;
var RentalAgreementActionEnum;
(function (RentalAgreementActionEnum) {
    RentalAgreementActionEnum["COMPLETE"] = "complete";
    RentalAgreementActionEnum["APPROVE"] = "approve";
    RentalAgreementActionEnum["REJECT"] = "reject";
    RentalAgreementActionEnum["PENDING"] = "make_pending";
    RentalAgreementActionEnum["CANCEL"] = "cancel";
    RentalAgreementActionEnum["CREATE_MAINTENANCE"] = "create_maintenance";
    RentalAgreementActionEnum["CREATE_DAMAGE"] = "create_damage";
    RentalAgreementActionEnum["CHECK_IN"] = "check_in";
    RentalAgreementActionEnum["CHECK_OUT"] = "check_out";
})(RentalAgreementActionEnum || (exports.RentalAgreementActionEnum = RentalAgreementActionEnum = {}));
