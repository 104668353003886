import { Injectable } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable, map, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DialogsService {
	constructor(
		private _formBuilder: FormBuilder,
		private _fuseConfirmationService: FuseConfirmationService,
	) {}

	// ------------------------------------------------------------------------
	// @ Delete Record
	// ------------------------------------------------------------------------

	/**
	 * Opens Delete Record dialog
	 * @param actions
	 * @example
	 * this.dialogService.openDeleteRecordDialog({
	 *    confirmed: () => {
	 *       // Do something
	 *    },
	 *    cancelled: () => {
	 *      // Do something
	 *    }
	 *    undefined: () => {
	 *      // Do something
	 *    }
	 * });
	 */
	openDeleteRecordDialog(actions: {
		confirmed?: () => void;
		cancelled?: () => void;
		undefined?: () => void;
	}): void {
		// Open the dialog
		// this._fuseConfirmationService
		// 	.open(this.createDeleteRecordDialogForm().value)
		// 	.afterClosed()
		// 	.subscribe((result) => {
		// 		if (result === 'confirmed' && actions.confirmed) {
		// 			actions.confirmed();
		// 		} else if (result === 'cancelled' && actions.cancelled) {
		// 			actions.cancelled();
		// 		}
		// 	});
		// Open the native browser confirmation dialog
		const result = window.confirm(
			'Are you sure you want to delete? This action cannot be undone.',
		);

		if (result && actions.confirmed) {
			actions.confirmed();
		} else if (!result && actions.cancelled) {
			actions.cancelled();
		}
	}

	/**
	 * Opens Delete Record dialog and returns an observable
	 * @returns Observable<boolean>
	 * @example
	 * this.dialogService.confirmDeleteRecordAction().subscribe((result) => {
	 *   if (result) {
	 *      // Do something
	 *   }
	 * });
	 */
	confirmDeleteRecordAction(): Observable<boolean> {
		// Open the dialog
		return this._fuseConfirmationService
			.open(this.createDeleteRecordDialogForm().value)
			.afterClosed()
			.pipe(map((result) => result === 'confirmed'));
	}

	private createDeleteRecordDialogForm(): UntypedFormGroup {
		// Build the dialog form
		return this._formBuilder.group({
			title: 'Delete record...',
			message:
				'</br><span class="font-medium">Deleting this record will remove it from all tables, allocations, most reports and KPI calculations.</span></br></br><strong>This cannot be undone.</strong></br></br>Do you want to proceed?',
			icon: this._formBuilder.group({
				show: true,
				name: 'priority_high',
				color: 'warn',
			}),
			actions: this._formBuilder.group({
				confirm: this._formBuilder.group({
					show: true,
					label: 'Yes',
					color: 'warn',
				}),
				cancel: this._formBuilder.group({
					show: true,
					label: 'No',
				}),
			}),
			dismissible: true,
		});
	}

	// ------------------------------------------------------------------------
	// @ Delete Records
	// ------------------------------------------------------------------------

	/**
	 * Opens Delete Records dialog
	 * @param actions
	 * @example
	 * this.dialogService.openDeleteRecordsDialog({
	 *    confirmed: () => {
	 *       // Do something
	 *    },
	 *    cancelled: () => {
	 *      // Do something
	 *    }
	 *    undefined: () => {
	 *      // Do something
	 *    }
	 * });
	 */
	openDeleteRecordsDialog(actions: {
		confirmed?: () => void;
		cancelled?: () => void;
		undefined?: () => void;
	}): void {
		// Open the dialog
		this._fuseConfirmationService
			.open(this.createDeleteRecordsDialogForm().value)
			.afterClosed()
			.subscribe((result) => {
				if (result === 'confirmed' && actions.confirmed) {
					actions.confirmed();
				} else if (result === 'cancelled' && actions.cancelled) {
					actions.cancelled();
				}
			});
	}

	/**
	 * Opens Delete Records dialog and returns an observable
	 * @returns Observable<boolean>
	 * @example
	 * this.dialogService.confirmDeleteRecordsAction().subscribe((result) => {
	 *   if (result) {
	 *      // Do something
	 *   }
	 * });
	 */
	confirmDeleteRecordsAction(): Observable<boolean> {
		// Open the dialog
		return this._fuseConfirmationService
			.open(this.createDeleteRecordsDialogForm().value)
			.afterClosed()
			.pipe(map((result) => result === 'confirmed'));
	}

	private createDeleteRecordsDialogForm(): UntypedFormGroup {
		// Build the dialog form
		return this._formBuilder.group({
			title: 'Delete records...',
			message:
				'</br><span class="font-medium">Deleting these records will remove them from all tables, allocations, most reports and KPI calculations.</span></br></br><strong>This cannot be undone.</strong></br></br>Do you want to proceed?',
			icon: this._formBuilder.group({
				show: true,
				name: 'priority_high',
				color: 'warn',
			}),
			actions: this._formBuilder.group({
				confirm: this._formBuilder.group({
					show: true,
					label: 'Yes',
					color: 'warn',
				}),
				cancel: this._formBuilder.group({
					show: true,
					label: 'No',
				}),
			}),
			dismissible: true,
		});
	}

	// ------------------------------------------------------------------------
	// @ Unsaved Changes
	// ------------------------------------------------------------------------

	/**
	 * Opens Unsaved Changes dialog
	 * @param actions
	 * @example
	 * this.dialogService.openUnsavedChangesDialog({
	 *    confirmed: () => {
	 *       // Do something
	 *    },
	 *    cancelled: () => {
	 *      // Do something
	 *    }
	 *    undefined: () => {
	 *      // Do something
	 *    }
	 * });
	 */
	openUnsavedChangesDialog(actions: {
		confirmed?: () => void;
		cancelled?: () => void;
		undefined?: () => void;
	}): void {
		// // Open the dialog
		// this._fuseConfirmationService
		// 	.open(this.createUnsavedChangesDialogForm().value)
		// 	.afterClosed()
		// 	.subscribe((result) => {
		// 		if (result === 'confirmed' && actions.confirmed) {
		// 			actions.confirmed();
		// 		} else if (result === 'cancelled' && actions.cancelled) {
		// 			actions.cancelled();
		// 		}
		// 	});
		// Open the native browser confirmation dialog
		const result = window.confirm(
			'You have unsaved changes. Are you sure you want to leave this page?',
		);

		if (result && actions.confirmed) {
			actions.confirmed();
		} else if (!result && actions.cancelled) {
			actions.cancelled();
		}
	}

	/**
	 * Opens Unsaved Changes dialog and returns an observable
	 * @returns Observable<boolean>
	 * @example
	 * this.dialogService.confirmUnsavedChangesAction().subscribe((result) => {
	 *   if (result) {
	 *      // Do something
	 *   }
	 * });
	 */
	confirmUnsavedChangesAction(): Observable<boolean> {
		// Open the dialog
		// return this._fuseConfirmationService
		// 	.open(this.createUnsavedChangesDialogForm().value)
		// 	.afterClosed()
		// 	.pipe(map((result) => result === 'confirmed'));

		// Open the native browser confirmation dialog
		const result = window.confirm(
			'You have unsaved changes. Are you sure you want to leave this page?',
		);

		// Return an Observable with the result
		return of(result);
	}

	private createUnsavedChangesDialogForm(): UntypedFormGroup {
		// Build the dialog form
		return this._formBuilder.group({
			title: 'Unsaved changes...',
			message:
				'</br><span class="font-medium">All of your unsaved changes will be lost!</span></br></br>Do you want to proceed?',
			icon: this._formBuilder.group({
				show: true,
				name: 'priority_high',
				color: 'warn',
			}),
			actions: this._formBuilder.group({
				confirm: this._formBuilder.group({
					show: true,
					label: 'Yes',
					color: 'warn',
				}),
				cancel: this._formBuilder.group({
					show: true,
					label: 'No',
				}),
			}),
			dismissible: true,
		});
	}
}
