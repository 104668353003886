"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseOrderStatusEnum = void 0;
var LeaseOrderStatusEnum;
(function (LeaseOrderStatusEnum) {
    LeaseOrderStatusEnum["PENDING"] = "pending";
    LeaseOrderStatusEnum["APPROVED"] = "approved";
    LeaseOrderStatusEnum["REJECTED"] = "rejected";
    LeaseOrderStatusEnum["IN_PROGRESS"] = "in_progress";
    LeaseOrderStatusEnum["CANCELLED"] = "cancelled";
    LeaseOrderStatusEnum["COMPLETED"] = "completed";
})(LeaseOrderStatusEnum || (exports.LeaseOrderStatusEnum = LeaseOrderStatusEnum = {}));
