"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pricingProfileStatusToColor = void 0;
const pricing_profile_status_enum_1 = require("../../pricing-profiles/enums/pricing-profile-status.enum");
const record_color_enum_1 = require("../enums/record-color.enum");
function pricingProfileStatusToColor(status) {
    switch (status) {
        case pricing_profile_status_enum_1.PricingProfileStatusEnum.ACTIVE:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case pricing_profile_status_enum_1.PricingProfileStatusEnum.INACTIVE:
            return record_color_enum_1.RecordColorEnum.SLATE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.pricingProfileStatusToColor = pricingProfileStatusToColor;
