"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehicleMaintenanceStatusToColor = void 0;
const vehicle_maintenances_1 = require("../../vehicle-maintenances");
const record_color_enum_1 = require("../enums/record-color.enum");
function vehicleMaintenanceStatusToColor(status) {
    switch (status) {
        case vehicle_maintenances_1.VehicleMaintenanceStatusEnum.PENDING:
            return record_color_enum_1.RecordColorEnum.AMBER;
        case vehicle_maintenances_1.VehicleMaintenanceStatusEnum.APPROVED:
            return record_color_enum_1.RecordColorEnum.GREEN;
        case vehicle_maintenances_1.VehicleMaintenanceStatusEnum.REJECTED:
            return record_color_enum_1.RecordColorEnum.ROSE;
        case vehicle_maintenances_1.VehicleMaintenanceStatusEnum.SCHEDULED:
            return record_color_enum_1.RecordColorEnum.TEAL;
        case vehicle_maintenances_1.VehicleMaintenanceStatusEnum.IN_PROGRESS:
            return record_color_enum_1.RecordColorEnum.BLUE;
        case vehicle_maintenances_1.VehicleMaintenanceStatusEnum.COMPLETED:
            return record_color_enum_1.RecordColorEnum.PURPLE;
        default:
            return record_color_enum_1.RecordColorEnum.DEFAULT;
    }
}
exports.vehicleMaintenanceStatusToColor = vehicleMaintenanceStatusToColor;
