import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressStateEnum } from '../../../../../enums/progress-state';
import { NgIf } from '@angular/common';

@Component({
	selector: 'app-progress-indicator',
	templateUrl: 'progress-indicator.component.html',
	standalone: true,
	imports: [MatProgressSpinnerModule, MatIconModule, NgIf],
})
export class ProgressIndicatorComponent {
	@Input() progressState: ProgressStateEnum;
	progressStateEnum = ProgressStateEnum;
}
