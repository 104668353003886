"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadWorthyCheckRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleRoadWorthyCheckRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_ROAD_WORTHY_CHECK;
    }
    code;
    externalReference;
    scheduledAt;
    startsAt;
    endsAt;
    status;
    statusReason;
    recordCategory;
    vehicleId;
    leaseAgreementId;
    vehicleMaintenanceId;
    totalNet;
    totalTax;
    totalGross;
    tags;
    avatarFile;
    files;
}
exports.VehicleRoadWorthyCheckRecordDto = VehicleRoadWorthyCheckRecordDto;
