import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UuidGeneratorService } from 'app/shared/services/uuid-generator.service';

import { ErrorAlertComponent } from '../components/error-alert/error-alert.component';
import { InfoAlertComponent } from '../components/info-alert/info-alert.component';
import { SuccessAlertComponent } from '../components/success-alert/success-alert.component';
import { WarningAlertComponent } from '../components/warning-alert/warning-alert.component';

@Injectable({
	providedIn: 'root',
})
export class AlertsService {
	constructor(
		private _snackBar: MatSnackBar,
		private _uuidGenerator: UuidGeneratorService,
	) {}

	/**
	 * @description Show an info alert snackbar
	 * @param message The message to display
	 * @param isPersistent Whether the snackbar should be persistent until the user dismisses it
	 * @param duration The duration in milliseconds to show the snackbar if persistent is false
	 */
	info(
		message: string,
		isPersistent: boolean = false,
		duration: number = 3000,
	) {
		this._snackBar.openFromComponent(InfoAlertComponent, {
			panelClass: ['snackbar-info'],
			verticalPosition: 'top',
			duration: isPersistent ? 0 : duration,
			data: {
				title: 'Info',
				message: message,
			},
		});
	}

	/**
	 * @description Show a success alert snackbar
	 * @param message The message to display
	 * @param isPersistent Whether the snackbar should be persistent until the user dismisses it
	 * @param duration The duration in milliseconds to show the snackbar if persistent is false
	 */
	success(
		message: string,
		isPersistent: boolean = false,
		duration: number = 3000,
	) {
		this._snackBar.openFromComponent(SuccessAlertComponent, {
			panelClass: ['snackbar-success'],
			duration: isPersistent ? 0 : duration,
			verticalPosition: 'bottom',
			horizontalPosition: 'left',
			data: {
				title: 'Success',
				message: message,
			},
		});
	}

	/**
	 * @description Show a warning alert snackbar
	 * @param message The message to display
	 * @param isPersistent Whether the snackbar should be persistent until the user dismisses it
	 * @param duration The duration in milliseconds to show the snackbar if persistent is false
	 */
	warn(
		message: string,
		isPersistent: boolean = false,
		duration: number = 3000,
	) {
		this._snackBar.openFromComponent(WarningAlertComponent, {
			panelClass: ['snackbar-warn'],
			verticalPosition: 'top',
			duration: isPersistent ? 0 : duration,
			data: {
				title: 'Warning',
				message: message,
			},
		});
	}

	/**
	 * @description Show an error alert snackbar
	 * @param message The message to display
	 * @param error The error to display
	 * @param isPersistent Whether the snackbar should be persistent until the user dismisses it
	 * @param duration The duration in milliseconds to show the snackbar if persistent is false
	 */
	error(
		message: string,
		error: Error,
		isPersistent: boolean = false,
		duration: number = 5000,
	) {
		this._snackBar.openFromComponent(ErrorAlertComponent, {
			panelClass: ['snackbar-error'],
			verticalPosition: 'bottom',
			horizontalPosition: 'left',
			duration: isPersistent ? 0 : duration,
			data: {
				title: 'Error',
				message: message,
				error: error,
				uuid: this._uuidGenerator.generateUuid(),
			},
		});
	}
}
