<span
	matTooltip="{{ tooltip }}"
	class="block rounded-full w-3 h-3"
	[ngClass]="{
		'gray-um-pill': color === colors.GRAY,
		'red-um-pill': color === colors.RED,
		'orange-um-pill': color === colors.ORANGE,
		'amber-um-pill': color === colors.AMBER,
		'yellow-um-pill': color === colors.YELLOW,
		'lime-um-pill': color === colors.LIME,
		'green-um-pill': color === colors.GREEN,
		'emerald-um-pill': color === colors.EMERALD,
		'teal-um-pill': color === colors.TEAL,
		'cyan-um-pill': color === colors.CYAN,
		'sky-um-pill': color === colors.SKY,
		'blue-um-pill': color === colors.BLUE,
		'indigo-um-pill': color === colors.INDIGO,
		'violet-um-pill': color === colors.VIOLET,
		'purple-um-pill': color === colors.PURPLE,
		'fuchsia-um-pill': color === colors.FUCHSIA,
		'pink-um-pill': color === colors.PINK,
		'rose-um-pill': color === colors.ROSE,
		'slate-um-pill': color === colors.SLATE,
		'zinc-um-pill': color === colors.ZINC,
		'stone-um-pill': color === colors.STONE,
		'neutral-um-pill': color === colors.NEUTRAL,
		'default-um-pill': color === colors.DEFAULT,
		'fallback-um-pill': !isValidColor(color)
	}">
</span>
