"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseDto = void 0;
class BaseDto {
    id;
    color;
    createdAt;
    createdByUserId;
    createdByUsername;
    updatedAt;
    updatedByUserId;
    updatedByUsername;
    isDeleted;
    deletedAt;
    deletedByUserId;
    deletedByUsername;
    isDraft;
    isExcludedFromReports;
    isExcludedFromAggregators;
}
exports.BaseDto = BaseDto;
