<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Dense -->
<dense-layout *ngIf="layout === 'dense'"></dense-layout>

<!-- Thin -->
<thin-layout *ngIf="layout === 'thin'"></thin-layout>

