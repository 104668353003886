<div class="flex">
	<div
		(click)="goBack()"
		class="ml-2 cursor-pointer hover:bg-gray-100 rounded-md h-fit flex p-2 duration-100">
		<mat-icon [svgIcon]="'arrow_back_ios'" class="icon-size-4 m-0"></mat-icon>
	</div>
	<div
		(click)="goForward()"
		class="ml-2 cursor-pointer hover:bg-gray-100 rounded-md h-fit flex p-2 duration-100">
		<mat-icon
			[svgIcon]="'arrow_forward_ios'"
			class="icon-size-4 m-0"></mat-icon>
	</div>
</div>
