import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash';
import { NGXLogger } from 'ngx-logger';

import { availableWidgets, dashboards } from './data';

@Injectable({
	providedIn: 'root',
})
export class DashboardsMockApi {
	private _availableWidgets = availableWidgets;
	private _dashboards = dashboards;

	constructor(
		private _fuseMockApiService: FuseMockApiService,
		private logger: NGXLogger,
	) {
		this.registerHandlers();
	}

	registerHandlers(): void {
		this._fuseMockApiService
			.onGet('api/widgets/available-widgets', 300)
			.reply(({ request }) => {
				return [200, cloneDeep(this._availableWidgets)];
			});

		this._fuseMockApiService
			.onGet('api/dashboards', 300)
			.reply(({ request }) => {
				if (localStorage.getItem('dashboards')) {
					this._dashboards = JSON.parse(localStorage.getItem('dashboards'));
				}
				return [200, cloneDeep(this._dashboards)];
			});

		this._fuseMockApiService
			.onPost('api/dashboards/save', 300)
			.reply(({ request }) => {
				const newData = request.body;
				this._dashboards = newData;
				localStorage.setItem('dashboards', JSON.stringify(newData));
				return [200, cloneDeep(this._dashboards)];
			});
	}
}
