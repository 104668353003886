"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformFloat = void 0;
const class_transformer_1 = require("class-transformer");
function TransformFloat({ toClassOnly = true } = {}) {
    return (0, class_transformer_1.Transform)(({ value }) => {
        if (value === null || value === '' || isNaN(Number(value))) {
            return null;
        }
        return parseFloat(value);
    }, { toClassOnly });
}
exports.TransformFloat = TransformFloat;
