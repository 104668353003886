import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RecordColorEnum } from 'ultramarine-shared';

import { RemoveUnderscoresPipe } from '../../pipes/remove-underscores.pipe';

@Component({
	selector: 'app-color-bubble',
	standalone: true,
	templateUrl: 'color-bubble.component.html',
	imports: [NgClass, MatTooltipModule, RemoveUnderscoresPipe],
})
export class ColorBubbleComponent {
	public colors = RecordColorEnum;
	@Input({ required: true }) color: RecordColorEnum;
	@Input() tooltip: string;

	isValidColor(color: RecordColorEnum): boolean {
		const validColors = [
			this.colors.GRAY,
			this.colors.RED,
			this.colors.ORANGE,
			this.colors.AMBER,
			this.colors.YELLOW,
			this.colors.LIME,
			this.colors.GREEN,
			this.colors.EMERALD,
			this.colors.TEAL,
			this.colors.CYAN,
			this.colors.SKY,
			this.colors.BLUE,
			this.colors.INDIGO,
			this.colors.VIOLET,
			this.colors.PURPLE,
			this.colors.FUCHSIA,
			this.colors.PINK,
			this.colors.ROSE,
			this.colors.SLATE,
			this.colors.ZINC,
			this.colors.NEUTRAL,
			this.colors.STONE,
		];

		return validColors.includes(color);
	}
}
