"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidTags = void 0;
const create_tags_schema_1 = require("../schemas/create-tags.schema");
const isValidTags = (tags, abortEarly = false) => {
    if (!tags || tags.length === 0) {
        return {
            isValid: true,
        };
    }
    const { error } = (0, create_tags_schema_1.createTagsSchema)().validate(tags, {
        abortEarly,
    });
    if (error) {
        return {
            isValid: false,
            errors: error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.isValidTags = isValidTags;
