<!-- Loading bar -->
<!-- <fuse-loading-bar></fuse-loading-bar> -->
<mat-drawer-container class="flex-auto h-full">
	<mat-drawer
		class="bg-white border-r border-r-gray-200 w-50"
		[autoFocus]="false"
		[position]="'start'"
		[mode]="drawerMode"
		[(opened)]="showSideMenu">
		<app-ultra-navigation>
			<app-user-portal></app-user-portal>
		</app-ultra-navigation>
	</mat-drawer>
	<mat-drawer-content class="flex flex-col">
		<div
			class="relative flex flex-0 items-center w-full h-12 px-2 md:px-2 z-49 bg-white print:hidden border-b">
			<span
				ultra-button
				class="hover:bg-gray-200"
				(click)="toggleSideMenu()"
				[svgIcon]="'heroicons_outline:menu'">
			</span>
			<app-pop-state class="hidden sm:block"></app-pop-state>
			<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
				<languages></languages>
			</div>
		</div>
		<div class="flex flex-col flex-auto">
			<router-outlet *ngIf="true"></router-outlet>
		</div>
	</mat-drawer-content>
</mat-drawer-container>
