import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApiRequestFilters } from 'ultramarine-shared';

@Injectable({
	providedIn: 'root',
})
export class NavigationContextService {
	constructor(private _router: Router) {}

	public navigationFilters: BehaviorSubject<ApiRequestFilters> =
		new BehaviorSubject<ApiRequestFilters | null>(null);

	public navigateToListWithContext<T>(moduleUrl: string, filters: T) {
		this.navigationFilters.next(filters);
		this._router.navigate(['admin', moduleUrl]);
	}

	public navigatedFromContext: boolean;

	public resetNavigatedFromContext() {
		this.navigatedFromContext = false;
	}

	public pushFiltersToList<T>(filters: T) {
		this.navigationFilters.next(filters);
	}

	public navigateToNewRecordWithContext(
		moduleUrl: string,
		updateNewRecordFromTaskData: () => void,
	): void {
		updateNewRecordFromTaskData();
		this.navigatedFromContext = true;
		this._router.navigate(['admin', moduleUrl, 'new']);
	}
}
