"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagsStatsDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_stats_dto_1 = require("../../shared/dtos/base-stats.dto");
class TagsStatsDto extends base_stats_dto_1.BaseStatsDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.TAG;
    }
}
exports.TagsStatsDto = TagsStatsDto;
