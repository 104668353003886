import { Component } from '@angular/core';

@Component({
	selector: 'app-register',
	templateUrl: 'register.component.html',
	standalone: true,
	host: {
		class: 'w-full',
	},
})
export class RegisterComponent {}
