"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListColumn = void 0;
class ListColumn {
    displayOrder;
    isVisibleInPicker;
    isSelectableInPicker;
    isVisible;
    isSelected;
    name;
    orderByColumnName;
    header;
    headerClass;
    cellClass;
    breakpointsExclude;
    supportsTimeMachine;
}
exports.ListColumn = ListColumn;
