"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateUserDto = void 0;
class CreateUserDto {
    color;
    username;
    status;
    recordCategory;
    email;
    isPasswordChangeRequired;
    userRoleName;
    userRolePermissions;
    password;
    tags;
}
exports.CreateUserDto = CreateUserDto;
