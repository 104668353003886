"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityDocumentTypeEnum = void 0;
var IdentityDocumentTypeEnum;
(function (IdentityDocumentTypeEnum) {
    IdentityDocumentTypeEnum["ID_CARD"] = "id_card";
    IdentityDocumentTypeEnum["PASSPORT"] = "passport";
    IdentityDocumentTypeEnum["RESIDENCE_PERMIT"] = "residence_permit";
    IdentityDocumentTypeEnum["OTHER"] = "other";
})(IdentityDocumentTypeEnum || (exports.IdentityDocumentTypeEnum = IdentityDocumentTypeEnum = {}));
