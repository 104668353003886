"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageSearchDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_search_dto_1 = require("../../shared/dtos/base-search.dto");
class VehicleDamageSearchDto extends base_search_dto_1.BaseSearchDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_DAMAGE;
    }
    code;
    status;
    recordCategory;
    type;
    fault;
    severity;
    reportedAt;
    vehicleLicensePlate;
    avatarFileUrl;
    displayValue() {
        return `${this.code} - ${this.reportedAt}`;
    }
    shortDisplayValue() {
        return ``;
    }
    extraDisplayValue() {
        return `${this.vehicleLicensePlate}`;
    }
    pillDisplayValue() {
        return `${this.status}`;
    }
}
exports.VehicleDamageSearchDto = VehicleDamageSearchDto;
