"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportSearchDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_search_dto_1 = require("../../shared/dtos/base-search.dto");
class ReportSearchDto extends base_search_dto_1.BaseSearchDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.REPORT;
    }
    name;
    path;
    mimeType;
    status;
    displayValue() {
        return `${this.name} ${this.mimeType}`;
    }
    shortDisplayValue() {
        return this.name;
    }
    extraDisplayValue() {
        return null;
    }
    pillDisplayValue() {
        return this.status;
    }
}
exports.ReportSearchDto = ReportSearchDto;
