"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalAgreementStatusEnum = void 0;
var RentalAgreementStatusEnum;
(function (RentalAgreementStatusEnum) {
    RentalAgreementStatusEnum["PENDING"] = "pending";
    RentalAgreementStatusEnum["APPROVED"] = "approved";
    RentalAgreementStatusEnum["REJECTED"] = "rejected";
    RentalAgreementStatusEnum["IN_PROGRESS"] = "in_progress";
    RentalAgreementStatusEnum["COMPLETED"] = "completed";
    RentalAgreementStatusEnum["CANCELLED"] = "cancelled";
})(RentalAgreementStatusEnum || (exports.RentalAgreementStatusEnum = RentalAgreementStatusEnum = {}));
