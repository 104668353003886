import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { ThinLayoutComponent } from 'app/layout/layouts/vertical/thin/thin.component';
import { SharedModule } from 'app/shared/shared.module';

import { PopStateComponent } from '../../../../shared/components/pop-state/pop-state.component';
import { TimeMachineComponent } from '../../../../shared/components/time-machine/time-machine.component';
import { UltraButtonComponent } from '../../../../shared/components/ultramarine-ui/ultra-button/ultra-button.component';
import { UserPortalComponent } from '../../../common/user-portal/user-portal.component';
import { UltraNavigationComponent } from '../../../../shared/components/ultramarine-ui/ultra-navigation/ultra-navigation.component';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
	declarations: [ThinLayoutComponent],
	exports: [ThinLayoutComponent],
	imports: [
		HttpClientModule,
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		FuseFullscreenModule,
		FuseLoadingBarModule,
		FuseNavigationModule,
		LanguagesModule,
		NotificationsModule,
		SearchModule,
		UserModule,
		SharedModule,
		PopStateComponent,
		TimeMachineComponent,
		UltraButtonComponent,
		UserPortalComponent,
		UltraNavigationComponent,
		UltraNavigationComponent,
		MatSidenavModule,
	],
})
export class ThinLayoutModule {}
