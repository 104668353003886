"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserActionEnum = void 0;
var UserActionEnum;
(function (UserActionEnum) {
    UserActionEnum["VIEW"] = "view";
    UserActionEnum["CREATE"] = "create";
    UserActionEnum["UPDATE"] = "update";
    UserActionEnum["DELETE"] = "delete";
    UserActionEnum["MANAGE"] = "manage";
})(UserActionEnum || (exports.UserActionEnum = UserActionEnum = {}));
