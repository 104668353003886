"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadTaxSearchDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_search_dto_1 = require("../../shared/dtos/base-search.dto");
const to_currency_amount_function_1 = require("../../shared/functions/to-currency-amount.function");
class VehicleRoadTaxSearchDto extends base_search_dto_1.BaseSearchDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_ROAD_TAX;
    }
    code;
    externalReference;
    startsAt;
    endsAt;
    status;
    recordCategory;
    vehicleVin;
    vehicleLicensePlate;
    vehicleMakeFull;
    totalGross;
    avatarFileUrl;
    displayValue() {
        return `${this.code}${this.externalReference ? `/${this.externalReference}` : ''}${this.vehicleMakeFull ? ` - ${this.vehicleMakeFull}` : ''}`;
    }
    shortDisplayValue() {
        return `${this.code}${this.vehicleMakeFull ? ` - ${this.vehicleMakeFull}` : ''}`;
    }
    extraDisplayValue() {
        return `${(0, to_currency_amount_function_1.toCurrencyAmount)(this.totalGross, 'EUR', 'en-UK', 2)}`;
    }
    pillDisplayValue() {
        return `${this.status}`;
    }
}
exports.VehicleRoadTaxSearchDto = VehicleRoadTaxSearchDto;
