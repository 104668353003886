import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ErrorsService {
	constructor(private _router: Router) {}

	/**
	 * Navigates the user to the 404 page of the application
	 */
	public throw404Exception(): void {
		this._router.navigate(['/errors/error-404']);
	}

	/*
	 * Navigates the user to the 500 page of the application
	 */
	public throw500Exception(): void {
		this._router.navigate(['/errors/error-500']);
	}
}
