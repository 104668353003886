"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseAgreementOptionEnum = void 0;
var LeaseAgreementOptionEnum;
(function (LeaseAgreementOptionEnum) {
    LeaseAgreementOptionEnum["RETURN"] = "return";
    LeaseAgreementOptionEnum["EXTEND"] = "extend";
    LeaseAgreementOptionEnum["BUY_OUT"] = "buy_out";
    LeaseAgreementOptionEnum["RENEW"] = "renew";
})(LeaseAgreementOptionEnum || (exports.LeaseAgreementOptionEnum = LeaseAgreementOptionEnum = {}));
