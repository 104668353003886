<!-- Button -->
<button
	mat-icon-button
	[matMenuTriggerFor]="userActions"
	class="bg-gray-700 border border-black my-2">
	<span class="relative"> </span>
</button>

<mat-menu
	class="mat-elevation-z0 um-border"
	[xPosition]="'before'"
	#userActions="matMenu">
	<!-- <button mat-menu-item (click)="showUser()">
		<mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
		<div class="flex flex-col">
			<span class="p-0">{{ user.username }}</span>
			<span class="text-sm text-hint">{{ user.userRoleName }}</span>
		</div>
	</button>
	<mat-divider></mat-divider> -->
	<button mat-menu-item (click)="signOut()">
		<mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
		<span>Sign out</span>
	</button>
</mat-menu>
