"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalculationOperatorEnum = void 0;
var CalculationOperatorEnum;
(function (CalculationOperatorEnum) {
    CalculationOperatorEnum["EQUAL"] = "equal";
    CalculationOperatorEnum["NOT_EQUAL"] = "not_equal";
    CalculationOperatorEnum["GREATER_THAN"] = "greater_than";
    CalculationOperatorEnum["GREATER_THAN_OR_EQUAL"] = "greater_than_or_equal";
    CalculationOperatorEnum["LESS_THAN"] = "less_than";
    CalculationOperatorEnum["LESS_THAN_OR_EQUAL"] = "less_than_or_equal";
    CalculationOperatorEnum["IN"] = "in";
    CalculationOperatorEnum["NOT_IN"] = "not_in";
})(CalculationOperatorEnum || (exports.CalculationOperatorEnum = CalculationOperatorEnum = {}));
