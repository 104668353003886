"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapRulesToSubjectAbilities = void 0;
function mapRulesToSubjectAbilities(rules) {
    const subjectRules = rules.reduce((acc, rule) => {
        const subject = rule.subject;
        acc[subject] = acc[subject] || [];
        acc[subject].push(rule);
        return acc;
    }, {});
    const subjectAbilities = Object.entries(subjectRules).map(([subject, rules]) => {
        const abilities = rules.map((rule) => ({
            action: rule.action,
            allow: !rule.inverted,
        }));
        return {
            subject: subject,
            abilities,
        };
    });
    return subjectAbilities;
}
exports.mapRulesToSubjectAbilities = mapRulesToSubjectAbilities;
