"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleTypeEnum = void 0;
var VehicleTypeEnum;
(function (VehicleTypeEnum) {
    VehicleTypeEnum["PETROL"] = "petrol";
    VehicleTypeEnum["GAS"] = "gas";
    VehicleTypeEnum["HYBRID"] = "hybrid";
    VehicleTypeEnum["PLUG_IN_HYBRID"] = "plug_in_hybrid";
    VehicleTypeEnum["ELECTRIC"] = "electric";
})(VehicleTypeEnum || (exports.VehicleTypeEnum = VehicleTypeEnum = {}));
