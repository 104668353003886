<div class="flex mx-1">
	<!-- <mat-form-field>
		<mat-date-range-input
			[formGroup]="range"
			class="hidden"
			[rangePicker]="picker">
			<input matInput [matDatepicker]="picker" />
		</mat-date-range-input>
		<mat-datepicker-toggle
			[matTooltip]="
				'Time machine allows you to see the state of your business in a selected date range'
			"
			matIconSuffix
			[for]="picker"></mat-datepicker-toggle>
		<mat-date-range-picker #picker></mat-date-range-picker>
	</mat-form-field> -->

	<mat-form-field>
		<input [formControl]="date" matInput [matDatepicker]="picker" />
		<mat-datepicker-toggle
			[disabled]="isEditMode | async"
			[matTooltip]="
				'Time machine allows you to see the state of your business in a selected date'
			"
			matIconSuffix
			[for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
	</mat-form-field>
	<div
		(click)="resetToday()"
		[matTooltip]="'Reset Time Machine'"
		class="flex items-center justify-center purple-um-pill time-machine-shadow rounded-full text-sm px-2 font-semibold cursor-pointer hover:scale-105 duration-300"
		@zoomIn
		*ngIf="date.value.getDate().toString() !== today.getDate().toString()">
		<div class="mx-1" *ngIf="date.value">
			{{ date.value | date: 'dd/MM/syyyy' }}
		</div>
		<div class="flex items-center"></div>
	</div>

	<div
		[matTooltip]="'Currently viewing state of data for today'"
		class="flex items-center justify-center rounded-full text-sm px-4 font-bold"
		*ngIf="date.value?.getDate().toString() === today.getDate().toString()">
		Today
	</div>
</div>
