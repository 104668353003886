"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleMaintenanceActionEnum = void 0;
var VehicleMaintenanceActionEnum;
(function (VehicleMaintenanceActionEnum) {
    VehicleMaintenanceActionEnum["APPROVE"] = "approve";
    VehicleMaintenanceActionEnum["REJECT"] = "reject";
    VehicleMaintenanceActionEnum["COMPLETE"] = "complete";
    VehicleMaintenanceActionEnum["PENDING"] = "make_pending";
    VehicleMaintenanceActionEnum["SCHEDULE"] = "schedule";
    VehicleMaintenanceActionEnum["CHECK_IN"] = "check_in";
    VehicleMaintenanceActionEnum["CHECK_OUT"] = "check_out";
})(VehicleMaintenanceActionEnum || (exports.VehicleMaintenanceActionEnum = VehicleMaintenanceActionEnum = {}));
