"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadTaxStatusEnum = void 0;
var VehicleRoadTaxStatusEnum;
(function (VehicleRoadTaxStatusEnum) {
    VehicleRoadTaxStatusEnum["PENDING"] = "pending";
    VehicleRoadTaxStatusEnum["APPROVED"] = "approved";
    VehicleRoadTaxStatusEnum["REJECTED"] = "rejected";
    VehicleRoadTaxStatusEnum["PAID"] = "paid";
    VehicleRoadTaxStatusEnum["EXPIRED"] = "expired";
})(VehicleRoadTaxStatusEnum || (exports.VehicleRoadTaxStatusEnum = VehicleRoadTaxStatusEnum = {}));
