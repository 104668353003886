"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canCreateOrUpdate = void 0;
const create_location_schema_1 = require("../schemas/create-location.schema");
const update_location_schema_1 = require("../schemas/update-location.schema");
const canCreateOrUpdate = (record, abortEarly = false) => {
    if (!record)
        throw new Error('record is required.');
    let validationResult;
    if ('id' in record && record.id) {
        validationResult = (0, update_location_schema_1.updateLocationSchema)().validate(record, {
            abortEarly,
        });
    }
    else {
        validationResult = (0, create_location_schema_1.createLocationSchema)().validate(record, {
            abortEarly,
        });
    }
    if (validationResult.error) {
        return {
            isValid: false,
            errors: validationResult.error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.canCreateOrUpdate = canCreateOrUpdate;
