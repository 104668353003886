"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class UserRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.USER;
    }
    username;
    email;
    isEmailVerified;
    status;
    statusReason;
    recordCategory;
    userRoleName;
    userRolePermissions;
    isPasswordChangeRequired;
    passwordHash;
    lastPasswordChangeAt;
    failedLoginAttempts;
    lastFailedLoginAt;
    isLockedOut;
    lastLoginAt;
    lastLoginIp;
    staffId;
    tags;
    avatarFile;
    files;
}
exports.UserRecordDto = UserRecordDto;
