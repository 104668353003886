"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSearchDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_search_dto_1 = require("../../shared/dtos/base-search.dto");
class UserSearchDto extends base_search_dto_1.BaseSearchDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.USER;
    }
    username;
    email;
    status;
    recordCategory;
    avatarFileUrl;
    displayValue() {
        return `${this.username} ${this.email}`;
    }
    shortDisplayValue() {
        return this.username;
    }
    extraDisplayValue() {
        return null;
    }
    pillDisplayValue() {
        return `${this.status}`;
    }
}
exports.UserSearchDto = UserSearchDto;
