"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppSubjectEnum = void 0;
var AppSubjectEnum;
(function (AppSubjectEnum) {
    AppSubjectEnum["ALL"] = "all";
    AppSubjectEnum["ACCOUNT"] = "account";
    AppSubjectEnum["SETTINGS"] = "settings";
    AppSubjectEnum["TAG"] = "tag";
    AppSubjectEnum["CUSTOMER"] = "customer";
    AppSubjectEnum["LOCATION"] = "location";
    AppSubjectEnum["LOYALTY_PROGRAM"] = "loyalty_program";
    AppSubjectEnum["STAFF"] = "staff";
    AppSubjectEnum["SUPPLIER"] = "supplier";
    AppSubjectEnum["USER"] = "user";
    AppSubjectEnum["FILE"] = "file";
    AppSubjectEnum["VEHICLE"] = "vehicle";
    AppSubjectEnum["VEHICLE_DAMAGE"] = "vehicle_damage";
    AppSubjectEnum["VEHICLE_INSURANCE"] = "vehicle_insurance";
    AppSubjectEnum["VEHICLE_INSURANCE_CLAIM"] = "vehicle_insurance_claim";
    AppSubjectEnum["VEHICLE_MAINTENANCE"] = "vehicle_maintenance";
    AppSubjectEnum["VEHICLE_PURCHASE"] = "vehicle_purchase";
    AppSubjectEnum["VEHICLE_ROAD_TAX"] = "vehicle_road_tax";
    AppSubjectEnum["VEHICLE_ROAD_WORTHY_CHECK"] = "vehicle_road_worthy_check";
    AppSubjectEnum["VEHICLE_SALE"] = "vehicle_sale";
    AppSubjectEnum["VEHICLE_SCRAP"] = "vehicle_scrap";
    AppSubjectEnum["VEHICLE_VALUATION"] = "vehicle_valuation";
    AppSubjectEnum["LEASE_AGREEMENT"] = "lease_agreement";
    AppSubjectEnum["LEASE_ORDER"] = "lease_order";
    AppSubjectEnum["RENTAL_AGREEMENT"] = "rental_agreement";
    AppSubjectEnum["RENTAL_RESERVATION"] = "rental_reservation";
    AppSubjectEnum["PRICING_PROFILE"] = "pricing_profile";
    AppSubjectEnum["TRANSACTION"] = "transaction";
    AppSubjectEnum["DASHBOARD"] = "dashboard";
    AppSubjectEnum["NOTIFICATION"] = "notification";
    AppSubjectEnum["REMINDER"] = "reminder";
    AppSubjectEnum["REPORT"] = "report";
    AppSubjectEnum["AUDIT_LOG"] = "audit_log";
})(AppSubjectEnum || (exports.AppSubjectEnum = AppSubjectEnum = {}));
