"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRecordCategoryEnum = void 0;
var VehicleRecordCategoryEnum;
(function (VehicleRecordCategoryEnum) {
    VehicleRecordCategoryEnum["MOTOR_VEHICLE"] = "motor_vehicle";
    VehicleRecordCategoryEnum["WATER_VESSEL"] = "water_vessel";
    VehicleRecordCategoryEnum["AIRCRAFT"] = "aircraft";
    VehicleRecordCategoryEnum["CONSTRUCTION_EQUIPMENT"] = "construction_equipment";
})(VehicleRecordCategoryEnum || (exports.VehicleRecordCategoryEnum = VehicleRecordCategoryEnum = {}));
