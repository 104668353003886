"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehiclesFilters = void 0;
const api_request_filters_1 = require("../../shared/types/api-request-filters");
class VehiclesFilters extends api_request_filters_1.ApiRequestFilters {
    bodyTypes;
    types;
    exteriorColors;
    interiorColors;
    driveTrainTypes;
    transmissionTypes;
    powerPlantTypes;
    allocations;
    locations;
    categories;
    odometerValueFrom;
    odometerValueTo;
    statuses;
    recordCategories;
    draft;
    deleted;
    tags;
}
exports.VehiclesFilters = VehiclesFilters;
