"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleColorTypeEnum = void 0;
var VehicleColorTypeEnum;
(function (VehicleColorTypeEnum) {
    VehicleColorTypeEnum["EXTERIOR"] = "exterior";
    VehicleColorTypeEnum["INTERIOR"] = "interior";
    VehicleColorTypeEnum["ACCESSORY"] = "accessory";
    VehicleColorTypeEnum["RIM"] = "rim";
})(VehicleColorTypeEnum || (exports.VehicleColorTypeEnum = VehicleColorTypeEnum = {}));
