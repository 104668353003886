<!-- Button -->
<button
	mat-icon-button
	[matTooltip]="tooltip || 'Toggle Fullscreen'"
	(click)="toggleFullscreen()">
	<ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<!-- Default icon -->
<ng-template #defaultIconTpl>
	<mat-icon
		[svgIcon]="'heroicons_outline:arrows-expand'"
		class="text-gray-800"></mat-icon>
</ng-template>
