"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyDto = void 0;
const base_dto_1 = require("../../shared/dtos/base.dto");
class CurrencyDto extends base_dto_1.BaseDto {
    isoCode;
    name;
    symbol;
    isVisible;
}
exports.CurrencyDto = CurrencyDto;
