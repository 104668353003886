"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseAgreementActionEnum = void 0;
var LeaseAgreementActionEnum;
(function (LeaseAgreementActionEnum) {
    LeaseAgreementActionEnum["COMPLETE"] = "complete";
    LeaseAgreementActionEnum["APPROVE"] = "approve";
    LeaseAgreementActionEnum["REJECT"] = "reject";
    LeaseAgreementActionEnum["PENDING"] = "make_pending";
    LeaseAgreementActionEnum["CANCEL"] = "cancel";
    LeaseAgreementActionEnum["CREATE_MAINTENANCE"] = "create_maintenance";
    LeaseAgreementActionEnum["CREATE_DAMAGE"] = "create_damage";
    LeaseAgreementActionEnum["CHECK_IN"] = "check_in";
    LeaseAgreementActionEnum["CHECK_OUT"] = "check_out";
})(LeaseAgreementActionEnum || (exports.LeaseAgreementActionEnum = LeaseAgreementActionEnum = {}));
