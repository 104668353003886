"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagRecordCategoryEnum = void 0;
var TagRecordCategoryEnum;
(function (TagRecordCategoryEnum) {
    TagRecordCategoryEnum["SHARED"] = "shared";
    TagRecordCategoryEnum["CUSTOMER"] = "customer";
    TagRecordCategoryEnum["LOCATION"] = "location";
    TagRecordCategoryEnum["STAFF"] = "staff";
    TagRecordCategoryEnum["SUPPLIER"] = "supplier";
    TagRecordCategoryEnum["USER"] = "user";
    TagRecordCategoryEnum["VEHICLE"] = "vehicle";
    TagRecordCategoryEnum["VEHICLE_DAMAGE"] = "vehicle_damage";
    TagRecordCategoryEnum["VEHICLE_INSURANCE"] = "vehicle_insurance";
    TagRecordCategoryEnum["VEHICLE_INSURANCE_CLAIM"] = "vehicle_insurance_claim";
    TagRecordCategoryEnum["VEHICLE_MAINTENANCE"] = "vehicle_maintenance";
    TagRecordCategoryEnum["VEHICLE_PURCHASE"] = "vehicle_purchase";
    TagRecordCategoryEnum["VEHICLE_ROAD_TAX"] = "vehicle_road_tax";
    TagRecordCategoryEnum["VEHICLE_ROAD_WORTHY_CHECK"] = "vehicle_road_worthy_check";
    TagRecordCategoryEnum["VEHICLE_SALE"] = "vehicle_sale";
    TagRecordCategoryEnum["VEHICLE_SCRAP"] = "vehicle_scrap";
    TagRecordCategoryEnum["VEHICLE_VALUATION"] = "vehicle_valuation";
    TagRecordCategoryEnum["TRANSACTION"] = "transaction";
    TagRecordCategoryEnum["PRICING_PROFILE"] = "pricing_profile";
    TagRecordCategoryEnum["LEASE_AGREEMENT"] = "lease_agreement";
    TagRecordCategoryEnum["LEASE_ORDER"] = "lease_order";
    TagRecordCategoryEnum["RENTAL_AGREEMENT"] = "rental_agreement";
    TagRecordCategoryEnum["RENTAL_RESERVATION"] = "rental_reservation";
})(TagRecordCategoryEnum || (exports.TagRecordCategoryEnum = TagRecordCategoryEnum = {}));
