"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toSanitizedPath = void 0;
function toSanitizedPath(path, options) {
    if (!path) {
        return path;
    }
    const { lowercase = false, replaceSpaces = '-', removeTrailingSlash = true, } = options || {};
    path = path.trim();
    if (replaceSpaces) {
        path = path.replace(/\s+/g, replaceSpaces === true ? '_' : replaceSpaces);
    }
    path = path.replace(/[^a-z0-9-_.\/]/gi, '');
    if (lowercase) {
        path = path.toLowerCase();
    }
    if (removeTrailingSlash) {
        path = path.replace(/\/$/, '');
    }
    return path;
}
exports.toSanitizedPath = toSanitizedPath;
