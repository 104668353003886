"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleMaintenanceListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleMaintenanceListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE_MAINTENANCE;
    }
    code;
    externalReference;
    recordCategory;
    status;
    scheduledAt;
    estimatedCost;
    serviceCenter;
    vehicle;
    supplier;
    tags;
    avatarFileUrl;
    computedTotalNet;
    computedTotalTax;
    computedTotalGross;
    mobileTitle = () => {
        const vehicle = this.vehicle?.licensePlate
            ? this.vehicle.licensePlate
            : this.vehicle?.vin
                ? this.vehicle.vin
                : '';
        const totalGross = this.computedTotalGross
            ? `${this.computedTotalGross} €`
            : '';
        return `${vehicle}` + `${totalGross ? ' / ' + totalGross : ''}`;
    };
    mobileSubtitle = () => {
        return ``;
    };
}
exports.VehicleMaintenanceListDto = VehicleMaintenanceListDto;
