import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';

import { WidgetFactoryComponent } from './adaptive-widgets/widget-factory/widget-factory.component';
import { WidgetCompareRangeFleetComponent } from './adaptive-widgets/widget-fleet/widget-compare-range-fleet/widget-compare-range-fleet.component';
import { WidgetCompareValueFleetLengthCheckPipe } from './adaptive-widgets/widget-fleet/widget-compare-value-fleet/widget-compare-value-fleet-length-check.pipe';
import { WidgetCompareValueFleetComponent } from './adaptive-widgets/widget-fleet/widget-compare-value-fleet/widget-compare-value-fleet.component';
import { WidgetRangeFleetComponent } from './adaptive-widgets/widget-fleet/widget-range-fleet/widget-range-fleet.component';
import { WidgetSummaryFleetComponent } from './adaptive-widgets/widget-fleet/widget-summary-fleet/widget-summary-fleet.component';
import { WidgetValueFleetLengthCheckPipe } from './adaptive-widgets/widget-fleet/widget-value-fleet/widget-value-fleet-length-check.pipe';
import { WidgetValueFleetComponent } from './adaptive-widgets/widget-fleet/widget-value-fleet/widget-value-fleet.component';
import { WidgetLoaderComponent } from './components/widget-loader/widget-loader.component';
import { KNotationPipe } from './pipes/k-notation.pipe';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		MatIconModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatProgressBarModule,
		MatFormFieldModule,
		MatTooltipModule,
		NgApexchartsModule,
		MatDatepickerModule,
		MatInputModule,
		MatFormFieldModule,
		MatNativeDateModule,
		MatCheckboxModule,
	],
	exports: [
		// Widget Factory
		WidgetFactoryComponent,

		// Adaptive Widgets
		WidgetSummaryFleetComponent,
		WidgetValueFleetComponent,
		WidgetRangeFleetComponent,
		WidgetCompareValueFleetComponent,
		WidgetCompareRangeFleetComponent,
	],
	providers: [],
	declarations: [
		//Widget Factory
		WidgetFactoryComponent,

		//Components
		WidgetLoaderComponent,

		//Adaptive Widgets
		WidgetValueFleetComponent,
		WidgetSummaryFleetComponent,
		WidgetRangeFleetComponent,
		WidgetCompareValueFleetComponent,
		WidgetCompareRangeFleetComponent,

		// Pipes
		WidgetValueFleetLengthCheckPipe,
		WidgetCompareValueFleetLengthCheckPipe,
		KNotationPipe,
	],
})
export class WidgetsModule {}
