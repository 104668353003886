"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadTaxActionEnum = void 0;
var VehicleRoadTaxActionEnum;
(function (VehicleRoadTaxActionEnum) {
    VehicleRoadTaxActionEnum["APPROVE"] = "approve";
    VehicleRoadTaxActionEnum["REJECT"] = "reject";
    VehicleRoadTaxActionEnum["PENDING"] = "make_pending";
    VehicleRoadTaxActionEnum["PAY"] = "pay";
})(VehicleRoadTaxActionEnum || (exports.VehicleRoadTaxActionEnum = VehicleRoadTaxActionEnum = {}));
