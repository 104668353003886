"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class UserListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.USER;
    }
    username;
    email;
    isEmailVerified;
    status;
    recordCategory;
    userRoleName;
    userRolePermissions;
    isPasswordChangeRequired;
    passwordHash;
    lastPasswordChangeAt;
    failedLoginAttempts;
    lastFailedLoginAt;
    isLockedOut;
    lastLoginAt;
    lastLoginIp;
    staff;
    tags;
    avatarFileUrl;
    mobileTitle = () => {
        return `${this.username}`;
    };
    mobileSubtitle = () => {
        return (`${this.userRoleName}` +
            `${this.lastLoginAt ? ' / ' + this.lastLoginAt : ''}`);
    };
}
exports.UserListDto = UserListDto;
