"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactTitleEnum = void 0;
var ContactTitleEnum;
(function (ContactTitleEnum) {
    ContactTitleEnum["NONE"] = "none";
    ContactTitleEnum["MR"] = "mr";
    ContactTitleEnum["MRS"] = "mrs";
    ContactTitleEnum["MS"] = "ms";
    ContactTitleEnum["MISS"] = "miss";
    ContactTitleEnum["DR"] = "dr";
    ContactTitleEnum["PROF"] = "prof";
    ContactTitleEnum["SIR"] = "sir";
})(ContactTitleEnum || (exports.ContactTitleEnum = ContactTitleEnum = {}));
