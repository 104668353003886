"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canPerformAction = void 0;
const set_status_schema_1 = require("../../../shared/validators/schemas/set-status.schema");
const rental_reservation_action_enum_1 = require("../../enums/rental-reservation-action.enum");
const rental_reservation_status_enum_1 = require("../../enums/rental-reservation-status.enum");
const canPerformAction = (record, action, abortEarly = false) => {
    if (!record)
        throw new Error('record is required.');
    let validationResult;
    const fixedStatuses = [
        rental_reservation_status_enum_1.RentalReservationStatusEnum.COMPLETED,
        rental_reservation_status_enum_1.RentalReservationStatusEnum.CONFIRMED,
        rental_reservation_status_enum_1.RentalReservationStatusEnum.PENDING,
        rental_reservation_status_enum_1.RentalReservationStatusEnum.CANCELLED,
        rental_reservation_status_enum_1.RentalReservationStatusEnum.NO_SHOW,
    ];
    switch (action) {
        case rental_reservation_action_enum_1.RentalReservationActionEnum.CONFIRM:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_reservation_status_enum_1.RentalReservationStatusEnum.CONFIRMED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_reservation_action_enum_1.RentalReservationActionEnum.CANCEL:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_reservation_status_enum_1.RentalReservationStatusEnum.CANCELLED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_reservation_action_enum_1.RentalReservationActionEnum.COMPLETE:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_reservation_status_enum_1.RentalReservationStatusEnum.COMPLETED, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_reservation_action_enum_1.RentalReservationActionEnum.PENDING:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_reservation_status_enum_1.RentalReservationStatusEnum.PENDING, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_reservation_action_enum_1.RentalReservationActionEnum.NO_SHOW:
            validationResult = (0, set_status_schema_1.setStatusSchema)(rental_reservation_status_enum_1.RentalReservationStatusEnum.NO_SHOW, fixedStatuses).validate(record, {
                abortEarly,
            });
            break;
        case rental_reservation_action_enum_1.RentalReservationActionEnum.CREATE_RENTAL_AGREEMENT:
            break;
        default:
            throw new Error(`Unknown action: ${action}`);
    }
    if (validationResult.error) {
        return {
            isValid: false,
            errors: validationResult.error.details?.map((e) => e.message),
        };
    }
    return { isValid: true };
};
exports.canPerformAction = canPerformAction;
