import { WidgetCompareRangeFleetProperties } from 'app/shared/widgets/adaptive-widgets/widget-fleet/widget-compare-range-fleet/widget-compare-range-fleet.types';
import {
	WidgetCompareValueFleetData,
	WidgetCompareValueFleetProperties,
} from 'app/shared/widgets/adaptive-widgets/widget-fleet/widget-compare-value-fleet/widget-compare-value-fleet.types';
import {
	WidgetRangeFleetData,
	WidgetRangeFleetProperties,
} from 'app/shared/widgets/adaptive-widgets/widget-fleet/widget-range-fleet/widget-range-fleet.types';
import {
	WidgetSummaryFleetData,
	WidgetSummaryFleetProperties,
} from 'app/shared/widgets/adaptive-widgets/widget-fleet/widget-summary-fleet/widget-summary-fleet.types';
import { WidgetValueFleetProperties } from 'app/shared/widgets/adaptive-widgets/widget-fleet/widget-value-fleet/widget-value-fleet.types';

import {
	generateLastMonthData,
	generateLastSixMonthsData,
	generateLastThreeMonthsData,
	generateLastYearData,
} from './mock-generator';

export const availableCarsProperties: WidgetCompareValueFleetProperties = {
	title: 'Available Cars',
	subtitle: 'Subtitle',
	tooltip: 'Till Tip content',
	autoRefresh: false,
	dataEndpoint: 'api/widgets/value/fleet/available-cars/data',
	currentDate: new Date(),
	maxDate: new Date(),
	minDate: new Date(2020, 0, 1),
	label: 'Available Cars',
	shortLabel: 'Cars',
	key: 'available-cars',
	locationOptions: [
		{
			label: 'All Locations',
			shortLabel: 'All',
			key: 'all-locations',
		},
		{
			label: 'Nicosia',
			shortLabel: 'Nic',
			key: 'nicosia',
		},
		{
			label: 'Limassol',
			shortLabel: 'Lim',
			key: 'location-two',
		},
	],
	defaultLocationOption: {
		label: 'All Locations',
		shortLabel: 'All',
		key: 'all-locations',
	},
	carGroupOptions: [
		{
			label: 'All Car Groups',
			shortLabel: 'All',
			key: 'all-car-groups',
		},
		{
			label: 'Car Group One',
			shortLabel: 'G1',
			key: 'car-group-one',
		},
		{
			label: 'Car Group Two',
			shortLabel: 'G2',
			key: 'car-group-two',
		},
	],
	defaultCarGroupOption: {
		label: 'All Car Groups',
		shortLabel: 'All',
		key: 'all-car-groups',
	},
	carStatusOptions: [
		{
			label: 'All Car Statuses',
			shortLabel: 'All',
			key: 'all-car-statuses',
		},
		{
			label: 'Car Status One',
			shortLabel: 'Stat1',
			key: 'car-status-one',
		},
		{
			label: 'Car Status Two',
			shortLabel: 'Stat2',
			key: 'car-status-two',
		},
		{
			label: 'Car Status Three',
			shortLabel: 'Stat3',
			key: 'car-status-three',
		},
	],
	defaultCarStatusOption: {
		label: 'All Car Statuses',
		shortLabel: 'All',
		key: 'all-car-statuses',
	},
};

export const availableCarsData: WidgetCompareValueFleetData = {
	currentDate: new Date(),
	currentValue: Math.floor(Math.random() * 100),
	previousDate: new Date(2022, 0, 1),
	previousValue: Math.floor(Math.random() * 100),
	differenceValue: Math.floor(Math.random() * 100),
};

export const staffUtilizationProperties: WidgetValueFleetProperties = {
	title: 'Staff Utilization',
	uriToDetails: 'staff-utilization',
	subtitle: 'Subtitle',
	autoRefresh: false,
	tooltip: 'Till Tip content',
	dataEndpoint: 'api/widgets/fleet/staff-utilization/data',
	label: 'Staff Utilization',
	shortLabel: 'Staff',
	key: 'staff-utilization',
	dropDownOptions: [
		{
			label: 'Yesterday',
			key: 'yesterday',
			shortLabel: 'Yest',
		},
		{
			label: '7 Days',
			key: 'last7Days',
			shortLabel: '7d',
		},
		{
			label: '30 Days',
			key: 'last30Days',
			shortLabel: '30d',
		},
		{
			label: '90 Days',
			key: 'last90Days',
			shortLabel: '90d',
		},
	],
	selectedDropDownOption: {
		label: 'Yesterday',
		key: 'yesterday',
		shortLabel: 'Yest',
	},
};
//the keys inside these object are of type WidgetValueFleetData
export const staffUtilizationData = {
	yesterday: {
		currentValue: 0.41,
	},
	last7Days: {
		currentValue: 0.44055,
	},
	last30Days: {
		currentValue: 0.89,
	},
	last90Days: {
		currentValue: 0.2,
	},
};

//#region RPU-Aggregate
//#region RPU-Aggregate Value
export const rentalPercentageUtilizationValueProperties: WidgetValueFleetProperties =
	{
		title: 'Rental Percentage Utilization',
		uriToDetails: 'rental-percentage-utilisation',
		subtitle: 'Subtitle',
		autoRefresh: false,
		tooltip: 'There will be details about the RPU',
		dataEndpoint: 'api/widgets/fleet/value/rental-percentage-utilization/data',
		label: 'Rental Percentage Utilization',
		shortLabel: 'RPU',
		key: 'rental-percentage-utilization',
		dropDownOptions: [
			{
				label: 'All Locations',
				key: 'all',
				shortLabel: 'All',
			},
			{
				label: 'Nicosia',
				key: 'nicosia',
				shortLabel: 'Nic',
			},
			{
				label: 'Limassol',
				key: 'limassol',
				shortLabel: 'Lim',
			},
		],
		selectedDropDownOption: {
			label: 'All',
			key: 'all',
			shortLabel: 'All',
		},
	};
export const rentalPercentageUtilizationValueData = {
	all: {
		currentValue: 0.41,
	},
	nicosia: {
		currentValue: 0.44055,
	},
	limassol: {
		currentValue: 0.89,
	},
};
//#endregion
//#region RPU-Aggregate Range
export const rentalPercentageUtilizationRangeProperties: WidgetRangeFleetProperties =
	{
		title: 'RPU',
		subtitle: 'chart',
		autoRefresh: false,
		label: 'Rental Percentage Utilisation',
		uriToDetails: 'rental-percentage-utilisation',
		tooltip: 'Till Tip content for range widget for RPU',
		dataEndpoint: 'api/widgets/fleet/range/rental-percentage-utilization/data',
		shortLabel: 'RPU',
		key: 'rental-percentage-utilisation',
		ageOptions: [
			{
				label: 'Last Month',
				key: 'last-month',
				shortLabel: 'LM',
			},
			{
				label: 'Last Three Months',
				key: 'last-three-months',
				shortLabel: 'L3M',
			},
			{
				label: 'Last Six Months',
				key: 'last-six-months',
				shortLabel: 'L6M',
			},
			{
				label: 'Last Year',
				key: 'last-year',
				shortLabel: 'LY',
			},
		],
		defaultAgeOption: {
			label: 'Last Month',
			key: 'last-month',
			shortLabel: 'LM',
		},
		locationOptions: [
			{
				label: 'All Locations',
				key: 'all',
				shortLabel: 'All',
			},
			{
				label: 'Nicosia',
				key: 'nicosia',
				shortLabel: 'Nic',
			},
			{
				label: 'Limassol',
				key: 'limassol',
				shortLabel: 'Lim',
			},
		],
		defaultLocationOption: {
			label: 'All',
			key: 'all',
			shortLabel: 'All',
		},
	};
//the keys inside these object are of type WidgetRangeFleetData
export const rentalPercentageUtilizationRangeData = {
	lastMonth: {
		label: 'Last Month',
		key: 'last-month',
		data: [{ name: 'Customers', data: generateLastMonthData() }],
	},
	lastThreeMonths: {
		label: 'Last Three Months',
		key: 'last-three-months',
		data: [{ name: 'Customers', data: generateLastThreeMonthsData() }],
	},
	lastSixMonths: {
		label: 'Last Six Months',
		key: 'last-six-months',
		data: [{ name: 'Customers', data: generateLastSixMonthsData() }],
	},
	lastYear: {
		label: 'Last Year',
		key: 'last-year',
		data: [{ name: 'Customers', data: generateLastYearData() }],
	},
};
//#endregion
//#endregion

//#region ARD-Aggregate
//#region ARD-Aggregate Value
export const averageRentalDurationValueProperties: WidgetValueFleetProperties =
	{
		title: 'Average Rental Duration',
		uriToDetails: 'average-rental-duration',
		subtitle: 'Subtitle',
		autoRefresh: false,
		tooltip: 'There will be details about the ARD',
		dataEndpoint: 'api/widgets/fleet/value/average-rental-duration/data',
		label: 'Average Rental Duration',
		shortLabel: 'ARD',
		key: 'average-rental-duration',
		dropDownOptions: [
			{
				label: 'All Locations',
				key: 'all',
				shortLabel: 'All',
			},
			{
				label: 'Nicosia',
				key: 'nicosia',
				shortLabel: 'Nic',
			},
			{
				label: 'Limassol',
				key: 'limassol',
				shortLabel: 'Lim',
			},
		],
		selectedDropDownOption: {
			label: 'All',
			key: 'all',
			shortLabel: 'All',
		},
	};
//the keys inside these object are of type WidgetRangeFleetData
export const averageRentalDurationValueData = {
	all: {
		currentValue: 0.21,
	},
	nicosia: {
		currentValue: 0.3205,
	},
	limassol: {
		currentValue: 0.69,
	},
};
//#endregion
//#region ARD-Aggregate Range
export const averageRentalDurationRangeProperties: WidgetRangeFleetProperties =
	{
		title: 'ARD',
		subtitle: 'chart',
		autoRefresh: false,
		label: 'Average Rental Duration',
		uriToDetails: 'average-rental-duration',
		tooltip: 'Till Tip content for range widget for ARD',
		dataEndpoint: 'api/widgets/fleet/range/average-rental-duration/data',
		shortLabel: 'ARD',
		key: 'average-rental-duration',
		ageOptions: [
			{
				label: 'Last Month',
				key: 'last-month',
				shortLabel: 'LM',
			},
			{
				label: 'Last Three Months',
				key: 'last-three-months',
				shortLabel: 'L3M',
			},
			{
				label: 'Last Six Months',
				key: 'last-six-months',
				shortLabel: 'L6M',
			},
			{
				label: 'Last Year',
				key: 'last-year',
				shortLabel: 'LY',
			},
		],
		defaultAgeOption: {
			label: 'Last Month',
			key: 'last-month',
			shortLabel: 'LM',
		},
		locationOptions: [
			{
				label: 'All Locations',
				key: 'all',
				shortLabel: 'All',
			},
			{
				label: 'Nicosia',
				key: 'nicosia',
				shortLabel: 'Nic',
			},
			{
				label: 'Limassol',
				key: 'limassol',
				shortLabel: 'Lim',
			},
		],
		defaultLocationOption: {
			label: 'All',
			key: 'all',
			shortLabel: 'All',
		},
	};
//the keys inside these object are of type WidgetRangeFleetData
export const averageRentalDurationRangeData = {
	lastMonth: {
		label: 'Last Month',
		key: 'last-month',
		data: [{ name: 'Customers', data: generateLastMonthData() }],
	},
	lastThreeMonths: {
		label: 'Last Three Months',
		key: 'last-three-months',
		data: [{ name: 'Customers', data: generateLastThreeMonthsData() }],
	},
	lastSixMonths: {
		label: 'Last Six Months',
		key: 'last-six-months',
		data: [{ name: 'Customers', data: generateLastSixMonthsData() }],
	},
	lastYear: {
		label: 'Last Year',
		key: 'last-year',
		data: [{ name: 'Customers', data: generateLastYearData() }],
	},
};
//#endregion
//#endregion

//-----------CUSTOMERS AGGREGATE RANGE--------------------------
export const customersProperties: WidgetRangeFleetProperties = {
	title: 'Customers',
	uriToDetails: '',
	subtitle: 'Subtitle',
	autoRefresh: true,
	tooltip: 'Till Tip content for range widget',
	dataEndpoint: 'api/widgets/range/fleet/customers/data',
	label: 'Customers',
	shortLabel: 'Cust',
	key: 'customers',
	ageOptions: [
		{
			label: 'Last Month',
			key: 'last-month',
			shortLabel: 'LM',
		},
		{
			label: 'Last Three Months',
			key: 'last-three-months',
			shortLabel: 'L3M',
		},
		{
			label: 'Last Six Months',
			key: 'last-six-months',
			shortLabel: 'L6M',
		},
		{
			label: 'Last Year',
			key: 'last-year',
			shortLabel: 'LY',
		},
	],
	defaultAgeOption: {
		label: 'Last Month',
		key: 'last-month',
		shortLabel: 'LM',
	},
	locationOptions: [
		{
			label: 'All Locations',
			key: 'all',
			shortLabel: 'All',
		},
		{
			label: 'Nicosia',
			key: 'nicosia',
			shortLabel: 'Nic',
		},
		{
			label: 'Limassol',
			key: 'limassol',
			shortLabel: 'Lim',
		},
	],
	defaultLocationOption: {
		label: 'All',
		key: 'all',
		shortLabel: 'all',
	},
};
//the keys inside these object are of type WidgetRangeFleetData
export const customersData = {
	lastMonth: {
		label: 'Last Month',
		key: 'last-month',
		data: [{ name: 'Customers', data: generateLastMonthData() }],
	},
	lastThreeMonths: {
		label: 'Last Three Months',
		key: 'last-three-months',
		data: [{ name: 'Customers', data: generateLastThreeMonthsData() }],
	},
	lastSixMonths: {
		label: 'Last Six Months',
		key: 'last-six-months',
		data: [{ name: 'Customers', data: generateLastSixMonthsData() }],
	},
	lastYear: {
		label: 'Last Year',
		key: 'last-year',
		data: [{ name: 'Customers', data: generateLastYearData() }],
	},
};
//----------------------------------------------------

export const damagedCarsProperties: WidgetCompareRangeFleetProperties = {
	title: 'Damaged Cars',
	subtitle: 'damaged cars',
	autoRefresh: true,
	tooltip: 'Till Tip content for compare range widget',
	dataEndpoint: 'api/widgets/range/compare/fleet/damaged-cars/data',
	label: 'Damaged Cars',
	shortLabel: 'DC',
	key: 'damaged-cars',
	ageOptions: [
		{
			label: 'Last Month',
			key: 'last-month',
			shortLabel: 'LM',
		},
		{
			label: 'Last Three Months',
			key: 'last-three-months',
			shortLabel: 'L3M',
		},
		{
			label: 'Last Six Months',
			key: 'last-six-months',
			shortLabel: 'L6M',
		},
		{
			label: 'Last Year',
			key: 'last-year',
			shortLabel: 'LY',
		},
	],
	defaultAgeOption: {
		label: 'Last Month',
		key: 'last-month',
		shortLabel: 'LM',
	},
	secondaryOptions: [
		{
			label: 'Nicosia',
			shortLabel: 'Nic',
			key: 'nicosia',
		},
		{
			label: 'Limassol',
			shortLabel: 'Lim',
			key: 'limassol',
		},
		{
			label: 'Larnaca',
			shortLabel: 'Lar',
			key: 'larnaca',
		},
		{
			label: 'Paphos',
			shortLabel: 'Pap',
			key: 'paphos',
		},
	],
	defaultSecondaryOption: {
		label: 'Nicosia',
		shortLabel: 'Nic',
		key: 'nicosia',
	},
};

//the keys inside these object are of type WidgetCompareRangeFleetData
export const damagedCarsData = {
	lastMonth: {
		label: 'Last Month',
		key: 'last-month',
		data: [
			{
				name: damagedCarsProperties.secondaryOptions[0].label,
				data: generateLastMonthData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[1].label,
				data: generateLastMonthData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[2].label,
				data: generateLastMonthData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[3].label,
				data: generateLastMonthData(),
			},
		],
	},
	lastThreeMonths: {
		label: 'Last Three Months',
		key: 'last-three-months',
		data: [
			{
				name: damagedCarsProperties.secondaryOptions[0].label,
				data: generateLastThreeMonthsData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[1].label,
				data: generateLastThreeMonthsData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[2].label,
				data: generateLastThreeMonthsData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[3].label,
				data: generateLastThreeMonthsData(),
			},
		],
	},
	lastSixMonths: {
		label: 'Last Six Months',
		key: 'last-six-months',
		data: [
			{
				name: damagedCarsProperties.secondaryOptions[0].label,
				data: generateLastSixMonthsData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[1].label,
				data: generateLastSixMonthsData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[2].label,
				data: generateLastSixMonthsData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[3].label,
				data: generateLastSixMonthsData(),
			},
		],
	},
	lastYear: {
		label: 'Last Year',
		key: 'last-year',
		data: [
			{
				name: damagedCarsProperties.secondaryOptions[0].label,
				data: generateLastYearData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[1].label,
				data: generateLastYearData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[2].label,
				data: generateLastYearData(),
			},
			{
				name: damagedCarsProperties.secondaryOptions[3].label,
				data: generateLastYearData(),
			},
		],
	},
};

export const carSummaryProperties: WidgetSummaryFleetProperties = {
	title: 'Car Summary',
	subtitle: 'Subtitle',
	autoRefresh: true,
	tooltip: 'Till Tip content for summary widget',
	dataEndpoint: 'api/widgets/summary/fleet/car-summary/data',
	label: 'Car Summary',
	shortLabel: 'Car Sum',
	key: 'car-summary',
	ageOptions: [
		{
			label: 'Last Month',
			key: 'last-month',
			shortLabel: 'LM',
		},
		{
			label: 'Last Three Months',
			key: 'last-three-months',
			shortLabel: 'L3M',
		},
		{
			label: 'Last Six Months',
			key: 'last-six-months',
			shortLabel: 'L6M',
		},
		{
			label: 'Last Year',
			key: 'last-year',
			shortLabel: 'LY',
		},
	],
	defaultAgeOption: {
		label: 'Last Month',
		key: 'last-month',
		shortLabel: 'LM',
	},
};

export const carSummaryData: WidgetSummaryFleetData = {};
