"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactToShortName = void 0;
function contactToShortName(contact) {
    if (!contact) {
        return '';
    }
    return contact.companyName
        ? `${contact.companyName.trim().replace(/\s+/g, ' ')}`
        : `${contact.firstName ? contact.firstName.trim().replace(/\s+/g, ' ') : ''}` +
            `${contact.lastName ? ' ' + contact.lastName.trim().replace(/\s+/g, ' ') : ''}`;
}
exports.contactToShortName = contactToShortName;
