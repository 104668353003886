"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalReservationStatusEnum = void 0;
var RentalReservationStatusEnum;
(function (RentalReservationStatusEnum) {
    RentalReservationStatusEnum["PENDING"] = "pending";
    RentalReservationStatusEnum["CONFIRMED"] = "confirmed";
    RentalReservationStatusEnum["NO_SHOW"] = "no_show";
    RentalReservationStatusEnum["COMPLETED"] = "completed";
    RentalReservationStatusEnum["CANCELLED"] = "cancelled";
    RentalReservationStatusEnum["EXPIRED"] = "expired";
})(RentalReservationStatusEnum || (exports.RentalReservationStatusEnum = RentalReservationStatusEnum = {}));
