"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateLocationDto = void 0;
class CreateLocationDto {
    color;
    status;
    recordCategory;
    name;
    contact;
    tags;
}
exports.CreateLocationDto = CreateLocationDto;
