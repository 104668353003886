"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingProfilePeriodEnum = void 0;
var PricingProfilePeriodEnum;
(function (PricingProfilePeriodEnum) {
    PricingProfilePeriodEnum["ALWAYS"] = "always";
    PricingProfilePeriodEnum["WEEKDAY"] = "weekday";
    PricingProfilePeriodEnum["WEEKEND"] = "weekend";
    PricingProfilePeriodEnum["WEEK_RANGE"] = "week_range";
    PricingProfilePeriodEnum["DATE_RANGE"] = "date_range";
})(PricingProfilePeriodEnum || (exports.PricingProfilePeriodEnum = PricingProfilePeriodEnum = {}));
