"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalAgreementSearchDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_search_dto_1 = require("../../shared/dtos/base-search.dto");
const to_currency_amount_function_1 = require("../../shared/functions/to-currency-amount.function");
class RentalAgreementSearchDto extends base_search_dto_1.BaseSearchDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.RENTAL_AGREEMENT;
    }
    documentNumber;
    qrCode;
    status;
    recordCategory;
    vehicleVin;
    vehicleLicensePlate;
    customerContactShortName;
    avatarFileUrl;
    computedPeriod;
    computedTotalNet;
    computedTotalTax;
    computedTotalGross;
    displayValue() {
        return `${this.documentNumber}${this.customerContactShortName ? ` - ${this.customerContactShortName}` : ''}`;
    }
    shortDisplayValue() {
        return this.documentNumber;
    }
    extraDisplayValue() {
        return `${(0, to_currency_amount_function_1.toCurrencyAmount)(this.computedTotalGross, 'EUR', 'en-UK', 2)}`;
    }
    pillDisplayValue() {
        return `${this.status}`;
    }
}
exports.RentalAgreementSearchDto = RentalAgreementSearchDto;
