"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomColor = void 0;
const record_color_enum_1 = require("../enums/record-color.enum");
function getRandomColor() {
    const colors = Object.values(record_color_enum_1.RecordColorEnum);
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}
exports.getRandomColor = getRandomColor;
