import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { AppNotification } from 'app/core/notifications/notifications.types';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import { ApiErrorCodeEnum, ApiResponse } from 'ultramarine-shared';

import { NOTIFICATIONS } from './data';

@Injectable({
	providedIn: 'root',
})
export class NotificationsMockApi {
	private _notifications: AppNotification[] = NOTIFICATIONS;

	/**
	 * Constructor
	 */
	constructor(
		private _fuseMockApiService: FuseMockApiService,
		private _logger: NGXLogger,
	) {
		// Register Mock API handlers
		this.registerHandlers();
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Register Mock API handlers
	 */
	registerHandlers(): void {
		/**
		 * GET - All notifications
		 */
		this._fuseMockApiService.onGet('api/notifications').reply(() => {
			// Return the response
			const response: ApiResponse<AppNotification[]> = {
				errorCode: ApiErrorCodeEnum.OK,
				data: cloneDeep(this._notifications),
			};

			return [200, response];
		});

		/**
		 * POST - Add new notification
		 */
		this._fuseMockApiService
			.onPost('api/notifications')
			.reply(({ request }) => {
				// Map the request body to the record type
				const record = request.body as AppNotification;

				// Generate a new id
				record.id =
					this._notifications.length > 0
						? Math.max(
								...this._notifications.map((notification) => notification.id),
							) + 1
						: 1;

				// Set created... fields
				record.createdAt = DateTime.utc();

				// Add the new record
				this._notifications.push(record);

				// Return the response
				const response: ApiResponse<AppNotification> = {
					errorCode: ApiErrorCodeEnum.OK,
					data: cloneDeep(record),
				};

				return [200, response];
			});

		/**
		 * Patch - Toggle read status on all notifications
		 */
		this._fuseMockApiService
			.onPatch('api/notifications/toggle-all-read')
			.reply(({ request }) => {
				const flag = request.body as boolean;

				// Go through all notifications
				this._notifications = this._notifications.map(
					(item: AppNotification) => {
						return { ...item, isRead: flag };
					},
				);

				// Return the response
				const response: ApiResponse<boolean> = {
					errorCode: ApiErrorCodeEnum.OK,
					data: cloneDeep(flag),
				};

				return [200, response];
			});

		/**
		 * UPDATE - Update notification
		 */
		this._fuseMockApiService
			.onPatch('api/notifications/:id')
			.reply(({ urlParams, request }) => {
				// Get the id from the params
				const id = parseInt(urlParams.id, 10);

				// Map the request body to the record type
				const data = request.body as AppNotification;

				// Find the record
				const recordIndex = this._notifications.findIndex(
					(record) => record.id === id,
				);

				// Return the response
				if (recordIndex === -1) {
					return [
						404,
						{
							message: `Record with id ${id} could not be found.`,
						},
					];
				}

				// Update the record
				this._notifications[recordIndex] = data;

				// Return the response
				const response: ApiResponse<AppNotification> = {
					errorCode: ApiErrorCodeEnum.OK,
					data: cloneDeep(data),
				};

				return [200, response];
			});

		/**
		 * DELETE - Delete notification
		 */
		this._fuseMockApiService
			.onDelete('api/notifications/:id')
			.reply(({ urlParams, request }) => {
				// Get the id
				const id = request.params.get('id');

				// Prepare the deleted notification
				let deletedNotification = null;

				// Find the notification
				const index = this._notifications.findIndex(
					(item: any) => item.id === id,
				);

				// Store the deleted notification
				deletedNotification = cloneDeep(this._notifications[index]);

				// Delete the notification
				this._notifications.splice(index, 1);

				// Return the response
				const response: ApiResponse<AppNotification> = {
					errorCode: ApiErrorCodeEnum.OK,
					data: cloneDeep(deletedNotification),
				};

				return [200, response];
			});

		// ------------------------------------------------------------------------
		// @ Toggle read status - POST
		// ------------------------------------------------------------------------
		this._fuseMockApiService
			.onPost('api/notifications/toggle-read-status')
			.reply(({ request }) => {
				// Get the notification
				const notification = cloneDeep(request.body.notification);

				// Prepare the updated notification
				let updatedNotification = null;

				// Find the notification and update it
				this._notifications.forEach(
					(item: any, index: number, notifications: any[]) => {
						if (item.id === notification.id) {
							// Update the notification
							notifications[index].read = notification.read;

							// Store the updated notification
							updatedNotification = notifications[index];
						}
					},
				);

				// Return the response
				return [200, updatedNotification];
			});
	}
}
