"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.rentalReservationValidators = void 0;
const can_create_or_update_function_1 = require("./validators/functions/can-create-or-update.function");
const can_perform_action_function_1 = require("./validators/functions/can-perform-action.function");
const is_valid_customer_function_1 = require("./validators/functions/is-valid-customer.function");
__exportStar(require("./dtos/batch-delete-rental-reservations.dto"), exports);
__exportStar(require("./dtos/batch-update-rental-reservations.dto"), exports);
__exportStar(require("./dtos/create-rental-reservation.dto"), exports);
__exportStar(require("./dtos/rental-reservation-list.dto"), exports);
__exportStar(require("./dtos/rental-reservation-record.dto"), exports);
__exportStar(require("./dtos/rental-reservation-search.dto"), exports);
__exportStar(require("./dtos/rental-reservations-app-options.dto"), exports);
__exportStar(require("./dtos/rental-reservations-stats.dto"), exports);
__exportStar(require("./dtos/update-rental-reservation.dto"), exports);
__exportStar(require("./enums/rental-reservation-record-category.enum"), exports);
__exportStar(require("./enums/rental-reservation-status.enum"), exports);
__exportStar(require("./types/rental-reservations-filters"), exports);
__exportStar(require("./validators/schemas/create-rental-reservation.schema"), exports);
__exportStar(require("./validators/schemas/rental-reservation-customer.schema"), exports);
__exportStar(require("./validators/schemas/update-rental-reservation.schema"), exports);
exports.rentalReservationValidators = {
    canCreateOrUpdate: can_create_or_update_function_1.canCreateOrUpdate,
    isValidCustomer: is_valid_customer_function_1.isValidCustomer,
    canPerformAction: can_perform_action_function_1.canPerformAction,
};
