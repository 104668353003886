"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatusEnum = void 0;
var PaymentStatusEnum;
(function (PaymentStatusEnum) {
    PaymentStatusEnum["PENDING"] = "pending";
    PaymentStatusEnum["PAID"] = "paid";
    PaymentStatusEnum["OVERDUE"] = "overdue";
    PaymentStatusEnum["REFUNDED"] = "refunded";
    PaymentStatusEnum["WAIVED"] = "waived";
    PaymentStatusEnum["WRITTEN_OFF"] = "written_off";
})(PaymentStatusEnum || (exports.PaymentStatusEnum = PaymentStatusEnum = {}));
