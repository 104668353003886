"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadTaxesFilters = void 0;
const shared_1 = require("../../shared");
const transform_date_decorator_1 = require("../../shared/decorators/transform-date.decorator");
const api_request_filters_1 = require("../../shared/types/api-request-filters");
class VehicleRoadTaxesFilters extends api_request_filters_1.ApiRequestFilters {
    startsAtFrom;
    startsAtTo;
    endsAtFrom;
    endsAtTo;
    totalGrossFrom;
    totalGrossTo;
    vehicles;
    statuses;
    recordCategories;
    draft;
    deleted;
    tags;
}
exports.VehicleRoadTaxesFilters = VehicleRoadTaxesFilters;
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], VehicleRoadTaxesFilters.prototype, "startsAtFrom", void 0);
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], VehicleRoadTaxesFilters.prototype, "startsAtTo", void 0);
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], VehicleRoadTaxesFilters.prototype, "endsAtFrom", void 0);
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], VehicleRoadTaxesFilters.prototype, "endsAtTo", void 0);
__decorate([
    (0, shared_1.TransformFloat)(),
    __metadata("design:type", Number)
], VehicleRoadTaxesFilters.prototype, "totalGrossFrom", void 0);
__decorate([
    (0, shared_1.TransformFloat)(),
    __metadata("design:type", Number)
], VehicleRoadTaxesFilters.prototype, "totalGrossTo", void 0);
