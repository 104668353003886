"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateAccountDto = void 0;
class CreateAccountDto {
    color;
    accountId;
    schemaName;
    subdomain;
    status;
    recordCategory;
    authTwoFactorEnabled;
    authTwoFactorMethod;
    authTwoFactorPhoneMobile;
    authTwoFactorEmail;
    authTwoFactorSecretKey;
    authTwoFactorRecoveryCodes;
    authExpirePasswords;
    authExpirePasswordsPeriod;
    contact;
}
exports.CreateAccountDto = CreateAccountDto;
