import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { ApiResponse, UserRecordDto } from 'ultramarine-shared';

import { AuthService } from '../auth.service';

export const NoAuthGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
):
	| Observable<boolean | UrlTree | Observable<UrlTree>>
	| Promise<boolean | UrlTree>
	| boolean
	| UrlTree => {
	const authService = inject(AuthService);
	const router = inject(Router);
	const logger = inject(NGXLogger);

	return authService.validateSession().pipe(
		switchMap((response: ApiResponse<UserRecordDto>) => {
			if (response) {
				// Log
				logger.info('NoAuth Guard > User is authenticated');
				return of(router.createUrlTree(['admin/dashboards']));
			}
		}),
		catchError((error) => {
			// Handle other errors or rethrow
			logger.info('NoAuth Guard > User is not authenticated');
			return of(true);
		}),
	);
};
