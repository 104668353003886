"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateVehicleDto = void 0;
const transform_date_decorator_1 = require("../../shared/decorators/transform-date.decorator");
const transform_int_decorator_1 = require("../../shared/decorators/transform-int.decorator");
class CreateVehicleDto {
    color;
    vin;
    status;
    recordCategory;
    licensePlate;
    registeredAt;
    deregisteredAt;
    makeId;
    modelId;
    year;
    series;
    trim;
    manufacturedAt;
    exteriorColorId;
    interiorColorId;
    bodyType;
    type;
    steeringPosition;
    doors;
    transmissionType;
    driveTrainType;
    odometerUnit;
    odometerValue;
    primaryPowerPlantType;
    secondaryPowerPlantType;
    engineSizeUnit;
    engineSize;
    engineOutput;
    engineTurbine;
    averageEmissionsCO2;
    emissionsUnitCO2;
    averageFuelConsumption;
    fuelConsumptionUnit;
    fuelTankCapacity;
    fuelTankCapacityUnit;
    averageElectricConsumption;
    electricBatteryCapacity;
    electricBatteryVoltage;
    electricBatteryType;
    electricMotorOutput;
    electricMotorRangeFrom;
    electricMotorRangeTo;
    allocation;
    locationId;
    categoryId;
    tags;
}
exports.CreateVehicleDto = CreateVehicleDto;
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], CreateVehicleDto.prototype, "registeredAt", void 0);
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], CreateVehicleDto.prototype, "deregisteredAt", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "makeId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "modelId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "year", void 0);
__decorate([
    (0, transform_date_decorator_1.TransformDate)(),
    __metadata("design:type", Date)
], CreateVehicleDto.prototype, "manufacturedAt", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "exteriorColorId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "interiorColorId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "doors", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "odometerValue", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "engineSize", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "engineOutput", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "averageEmissionsCO2", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "averageFuelConsumption", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "fuelTankCapacity", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "averageElectricConsumption", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "electricBatteryCapacity", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "electricBatteryVoltage", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "electricMotorOutput", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "electricMotorRangeFrom", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "electricMotorRangeTo", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "locationId", void 0);
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateVehicleDto.prototype, "categoryId", void 0);
