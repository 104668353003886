"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleRoadWorthyCheckActionEnum = void 0;
var VehicleRoadWorthyCheckActionEnum;
(function (VehicleRoadWorthyCheckActionEnum) {
    VehicleRoadWorthyCheckActionEnum["APPROVE"] = "approve";
    VehicleRoadWorthyCheckActionEnum["REJECT"] = "reject";
    VehicleRoadWorthyCheckActionEnum["PENDING"] = "make_pending";
    VehicleRoadWorthyCheckActionEnum["PAY"] = "pay";
    VehicleRoadWorthyCheckActionEnum["CREATE_MAINTENANCE"] = "create_maintenance";
    VehicleRoadWorthyCheckActionEnum["SCHEDULE"] = "schedule";
})(VehicleRoadWorthyCheckActionEnum || (exports.VehicleRoadWorthyCheckActionEnum = VehicleRoadWorthyCheckActionEnum = {}));
