//CHART OVERVIEW GENERATOR
///////////////////////////////////////////////
import { DateTime } from 'luxon';
const dt = DateTime;
export function generateLastMonthData() {
	const month = [];
	let oldestDt = dt.now().minus({ days: 30 });
	for (let i = 0; i < 30; i++) {
		month.push({ x: oldestDt.toJSDate(), y: Math.floor(Math.random() * 50) });
		oldestDt = oldestDt.plus({ hours: 24 });
	}
	return month;
}

export function generateLastThreeMonthsData() {
	const lastThreeMonths = [];
	let oldestDt = dt.now().minus({ days: 90 });
	for (let i = 0; i < 30; i++) {
		lastThreeMonths.push({
			x: oldestDt.toJSDate(),
			y: Math.floor(Math.random() * 50),
		});
		oldestDt = oldestDt.plus({ hours: 72 });
	}
	return lastThreeMonths;
}

export function generateLastSixMonthsData() {
	const lastSixMonths = [];
	let oldestDt = dt.now().minus({ days: 180 });
	for (let i = 0; i < 30; i++) {
		lastSixMonths.push({
			x: oldestDt.toJSDate(),
			y: Math.floor(Math.random() * 50),
		});
		oldestDt = oldestDt.plus({ hours: 144 });
	}
	return lastSixMonths;
}

export function generateLastYearData() {
	const lastYear = [];
	let oldestDt = dt.now().minus({ days: 360 });
	for (let i = 0; i < 30; i++) {
		lastYear.push({
			x: oldestDt.toJSDate(),
			y: Math.floor(Math.random() * 50),
		});
		oldestDt = oldestDt.plus({ hours: 288 });
	}
	return lastYear;
}
///////////////////////////////////////////////
