"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleMaintenanceItemTypeEnum = void 0;
var VehicleMaintenanceItemTypeEnum;
(function (VehicleMaintenanceItemTypeEnum) {
    VehicleMaintenanceItemTypeEnum["OIL_CHANGE"] = "oil_change";
    VehicleMaintenanceItemTypeEnum["TIRE_CHANGE"] = "tire_change";
    VehicleMaintenanceItemTypeEnum["BRAKE_CHANGE"] = "brake_change";
    VehicleMaintenanceItemTypeEnum["BATTERY_CHANGE"] = "battery_change";
    VehicleMaintenanceItemTypeEnum["REGULAR_SERVICE"] = "regular_service";
    VehicleMaintenanceItemTypeEnum["MAJOR_SERVICE"] = "major_service";
    VehicleMaintenanceItemTypeEnum["TRANSMISSION_SERVICE"] = "transmission_service";
    VehicleMaintenanceItemTypeEnum["ENGINE_SERVICE"] = "engine_service";
    VehicleMaintenanceItemTypeEnum["INTERIOR_REPAIR"] = "interior_repair";
    VehicleMaintenanceItemTypeEnum["EXTERIOR_REPAIR"] = "exterior_repair";
    VehicleMaintenanceItemTypeEnum["BODY_REPAIR"] = "body_repair";
    VehicleMaintenanceItemTypeEnum["RECALL"] = "recall";
    VehicleMaintenanceItemTypeEnum["EXTERIOR_DETAIL"] = "exterior_detail";
    VehicleMaintenanceItemTypeEnum["INTERIOR_DETAIL"] = "interior_detail";
    VehicleMaintenanceItemTypeEnum["WASH"] = "wash";
    VehicleMaintenanceItemTypeEnum["ROAD_WORTHY_CHECK"] = "road_worthy_check";
    VehicleMaintenanceItemTypeEnum["OTHER"] = "other";
})(VehicleMaintenanceItemTypeEnum || (exports.VehicleMaintenanceItemTypeEnum = VehicleMaintenanceItemTypeEnum = {}));
