"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleCategoryRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class VehicleCategoryRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.VEHICLE;
    }
    name;
    description;
    avatarFile;
    files;
}
exports.VehicleCategoryRecordDto = VehicleCategoryRecordDto;
