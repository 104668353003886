"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleScrapStatusEnum = void 0;
var VehicleScrapStatusEnum;
(function (VehicleScrapStatusEnum) {
    VehicleScrapStatusEnum["PENDING"] = "pending";
    VehicleScrapStatusEnum["APPROVED"] = "approved";
    VehicleScrapStatusEnum["REJECTED"] = "rejected";
    VehicleScrapStatusEnum["SCHEDULED"] = "scheduled";
    VehicleScrapStatusEnum["IN_PROGRESS"] = "in_progress";
    VehicleScrapStatusEnum["COMPLETED"] = "completed";
})(VehicleScrapStatusEnum || (exports.VehicleScrapStatusEnum = VehicleScrapStatusEnum = {}));
