"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleStatusEnum = void 0;
var VehicleStatusEnum;
(function (VehicleStatusEnum) {
    VehicleStatusEnum["IN_PARK"] = "in_park";
    VehicleStatusEnum["OUT_OF_SERVICE"] = "out_of_service";
    VehicleStatusEnum["SOLD"] = "sold";
    VehicleStatusEnum["SCRAPPED"] = "scrapped";
    VehicleStatusEnum["RESERVED"] = "reserved";
    VehicleStatusEnum["RENTED"] = "rented";
    VehicleStatusEnum["ORDERED"] = "ordered";
    VehicleStatusEnum["LEASED"] = "leased";
    VehicleStatusEnum["IN_MAINTENANCE"] = "in_maintenance";
})(VehicleStatusEnum || (exports.VehicleStatusEnum = VehicleStatusEnum = {}));
