"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationActionEnum = void 0;
var LocationActionEnum;
(function (LocationActionEnum) {
    LocationActionEnum["OPEN"] = "open";
    LocationActionEnum["CLOSE"] = "close";
    LocationActionEnum["ASSIGN_VEHICLE"] = "assign_vehicle";
    LocationActionEnum["ASSIGN_STAFF"] = "assign_staff";
})(LocationActionEnum || (exports.LocationActionEnum = LocationActionEnum = {}));
