<div class="flex flex-col justify-between h-full">
	<div>
		<div
			class="flex justify-between border-b border-gray-200 p-2 pb-4 h-16 bg-gray-100">
			<img
				class="w-fit h-7 p-1"
				src="assets/images/logo/ultramarine.png"
				alt="Logo image" />
			<ng-content></ng-content>
		</div>
		<div class="flex flex-col w-full overflow-y-auto my-1 px-1 gap-0.5">
			<div
				[routerActive]="item.link"
				(click)="item.link ? onNavigate(item) : null"
				*ngFor="let item of navigationData"
				ultra-menu-item-expandable
				[content]="item.title"
				[type]="item.type"
				[svgIcon]="item.icon">
				<ng-container *ngIf="item.children">
					<ng-container *ngFor="let item of item.children">
						<button
							[routerActive]="item.link"
							(click)="onNavigate(item)"
							class="w-full pl-4 !mx-0 max-w-full"
							*ngIf="item.type === 'basic'"
							[content]="item.title"
							[svgIcon]="item.icon"
							[disabled]="item.disabled"
							ultra-menu-item></button>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="text-md border-t border-t-gray-200 text-center py-2 bg-white">
		<span class="text-gray-500">Split Second Connect.</span>
		<span class="ml-1 text-gray-500">{{ currentYear }}</span>
	</div>
</div>
