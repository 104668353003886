"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFileDto = void 0;
const transform_int_decorator_1 = require("../../shared/decorators/transform-int.decorator");
class CreateFileDto {
    name;
    size;
    mimeType;
    status;
    sensitivity;
    subject;
    url;
    tags;
}
exports.CreateFileDto = CreateFileDto;
__decorate([
    (0, transform_int_decorator_1.TransformInt)(),
    __metadata("design:type", Number)
], CreateFileDto.prototype, "size", void 0);
