import { AppNotification } from 'app/core/notifications/notifications.types';
import { DateTime } from 'luxon';

export const NOTIFICATIONS: AppNotification[] = [
	{
		id: 1,
		image: 'assets/images/logo/blue.png',
		icon: 'notifications_active',
		title: 'Welcome to Ultramarine!',
		description: 'Welcome to our interactive demo! Explore at your own pace.',
		link: '/',
		useRouter: true,
		isRead: false,
		createdAt: DateTime.now(),
	},
];
