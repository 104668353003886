import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
	FuseNavigationService,
	FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { AppConfigService } from 'app/core/config/app.config.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NGXLogger } from 'ngx-logger';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'dense-layout',
	templateUrl: './dense.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
	// ------------------------------------------------------------------------
	// @ Public properties
	// ------------------------------------------------------------------------
	isScreenSmall: boolean;
	navigation: Navigation;
	navigationAppearance: 'default' | 'dense' = 'dense';

	// ------------------------------------------------------------------------
	// @ Private properties
	// ------------------------------------------------------------------------
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _logger: NGXLogger,
		private _appConfigService: AppConfigService,
		private _navigationService: NavigationService,
		private _fuseMediaWatcherService: FuseMediaWatcherService,
		private _fuseNavigationService: FuseNavigationService,
	) {}

	// ------------------------------------------------------------------------
	// @ Accessors
	// ------------------------------------------------------------------------

	/**
	 * Getter for current year
	 */
	get currentYear(): number {
		return new Date().getFullYear();
	}

	// ------------------------------------------------------------------------
	// @ Lifecycle hooks
	// ------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to services
		this._navigationService.navigation$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((navigation: Navigation) => {
				// Check
				if (!navigation) {
					return;
				}

				// Log
				this._logger.debug('navigation', navigation);

				// Update version badge
				const item = navigation.default
					.find((group) => group.id === 'docs')
					.children.find((item) => item.id === 'docs.change_log');

				// Update the badge title
				item.badge.title = `${this._appConfigService.config.buildVersion}`;

				this.navigation = navigation;
			});

		// Subscribe to media changes
		this._fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Check if the screen is small
				this.isScreenSmall = !matchingAliases.includes('md');

				// Change the navigation appearance
				this.navigationAppearance = this.isScreenSmall ? 'default' : 'dense';
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// ------------------------------------------------------------------------
	// @ Public methods
	// ------------------------------------------------------------------------

	/**
	 * Toggle navigation
	 *
	 * @param name
	 */
	toggleNavigation(name: string): void {
		// Get the navigation
		const navigation =
			this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				name,
			);

		if (navigation) {
			// Toggle the opened status
			navigation.toggle();
		}
	}

	/**
	 * Toggle the navigation appearance
	 */
	toggleNavigationAppearance(): void {
		this.navigationAppearance =
			this.navigationAppearance === 'default' ? 'dense' : 'default';
	}
}
