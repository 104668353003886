import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiResponse, UserRecordDto } from 'ultramarine-shared';

import { AuthService } from '../auth.service';

export const AuthGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
):
	| Observable<boolean | UrlTree>
	| Promise<boolean | UrlTree>
	| boolean
	| UrlTree => {
	const authService = inject(AuthService);
	const logger = inject(NGXLogger);
	const router = inject(Router);
	const cookieService = inject(CookieService);

	// Check if skipValidateSession is set to true, if so skip the validation and remove the cookie
	// TODO: this could be done without a cookie in the future, just a field in a service
	if (cookieService.get('skipValidateSession') === 'true') {
		logger.info('AuthGuard > skipValidateSession is set to true');
		cookieService.delete('skipValidateSession');
		return true;
	}

	return authService.validateSession().pipe(
		map((response: ApiResponse<UserRecordDto>) => {
			if (response) {
				// Log
				logger.info('AuthGuard > User is authenticated');
				return true;
			}
		}),
		catchError((error) => {
			//TODO : check the exception type to make sure that is a redirect exception.
			// We should only react to authentication exceptions for saving the redirect URL

			// redirect URL so that the user is redirected to the page they were trying to access
			const redirectUrl = state.toString();

			//create url string out of comma separated redirectUrl string
			const redirectUrlArray = redirectUrl.split(',').join('/');

			const urlTree = router.createUrlTree(['authentication/sign-in'], {
				queryParams: { redirectURL: redirectUrlArray },
			});
			logger.info('AuthGuard > Redirecting to:', urlTree.toString());
			return of(urlTree);
			// Handle other errors or rethrow
		}),
	);
};
