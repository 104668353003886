"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class ReportListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.REPORT;
    }
    mobileTitle = () => {
        return ``;
    };
    mobileSubtitle = () => {
        return ``;
    };
}
exports.ReportListDto = ReportListDto;
