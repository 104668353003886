"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
const contact_to_short_name_function_1 = require("../../shared/functions/contact-to-short-name.function");
class AccountListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.ACCOUNT;
    }
    accountId;
    schemaName;
    subdomain;
    status;
    recordCategory;
    authTwoFactorEnabled;
    authTwoFactorMethod;
    authTwoFactorPhoneMobile;
    authTwoFactorEmail;
    authTwoFactorSecretKey;
    authTwoFactorRecoveryCodes;
    authExpirePasswords;
    authExpirePasswordsPeriod;
    contact;
    avatarFileUrl;
    mobileTitle = () => {
        const contactName = (0, contact_to_short_name_function_1.contactToShortName)(this.contact);
        return `${this.accountId}${contactName ? ' - ' + contactName : ''}`;
    };
    mobileSubtitle = () => {
        return this.subdomain
            ? `${this.subdomain.trim().replace(/\s+/g, ' ')}`
            : '';
    };
}
exports.AccountListDto = AccountListDto;
