"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaffListDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
const contact_to_short_name_function_1 = require("../../shared/functions/contact-to-short-name.function");
class StaffListDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.STAFF;
    }
    code;
    status;
    recordCategory;
    contact;
    user;
    location;
    tags;
    avatarFileUrl;
    mobileTitle = () => {
        const contactName = (0, contact_to_short_name_function_1.contactToShortName)(this.contact);
        return `${this.code}${contactName ? ' - ' + contactName : ''}`;
    };
    mobileSubtitle = () => {
        const email = this.contact?.email;
        const phoneMobile = this.contact?.phoneMobile;
        const location = this.location?.code;
        return (`${phoneMobile ? phoneMobile : ''}` +
            `${email ? ' / ' + email : ''}` +
            `${location ? ' / ' + location : ''}`);
    };
}
exports.StaffListDto = StaffListDto;
