"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDamageStatusEnum = void 0;
var VehicleDamageStatusEnum;
(function (VehicleDamageStatusEnum) {
    VehicleDamageStatusEnum["PENDING"] = "pending";
    VehicleDamageStatusEnum["APPROVED"] = "approved";
    VehicleDamageStatusEnum["REJECTED"] = "rejected";
    VehicleDamageStatusEnum["ESTIMATED"] = "estimated";
    VehicleDamageStatusEnum["REPAIRED"] = "repaired";
    VehicleDamageStatusEnum["IGNORED"] = "ignored";
})(VehicleDamageStatusEnum || (exports.VehicleDamageStatusEnum = VehicleDamageStatusEnum = {}));
