"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class SettingsDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.SETTINGS;
    }
    shortDateFormat;
    shortTimeFormat;
    longDateFormat;
    longTimeFormat;
    firstDayOfWeek;
    amountDecimalDigits;
    amountDecimalSeparator;
    amountGroupSeparator;
    distanceUnit;
    fuelConsumptionUnit;
    vehicleEmissionsUnitCO2;
    vehicleEngineOutputUnit;
    vehicleFuelTankCapacityUnit;
    baseCurrencyId;
    baseCurrency;
    defaultLanguageId;
    defaultLanguage;
    defaultCountryId;
    defaultCountry;
}
exports.SettingsDto = SettingsDto;
