import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { AppContextService } from '../../../shared/services/app-context.service';

import { SearchData } from './search.model';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	constructor(private _appContext: AppContextService) {}

	public search(data: SearchData) {
		return this._appContext.apiService.get('/search', {}).pipe(
			catchError((error: Error) => {
				this._appContext.logger.error('Search error', error);
				this._appContext.alertsService.error(`Could not search`, error);
				this._appContext.loaderService.hide();
				return throwError(
					() => new Error(`Could not search`, { cause: error }),
				);
			}),
		);
	}
}
