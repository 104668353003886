"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalAgreementRecordDto = void 0;
const app_subject_enum_1 = require("../../authz/enums/app-subject.enum");
const base_dto_1 = require("../../shared/dtos/base.dto");
class RentalAgreementRecordDto extends base_dto_1.BaseDto {
    static get modelName() {
        return app_subject_enum_1.AppSubjectEnum.RENTAL_AGREEMENT;
    }
    documentNumber;
    qrCode;
    status;
    statusReason;
    recordCategory;
    startsAt;
    endsAt;
    option;
    vehicleId;
    customerId;
    rentalReservationId;
    vehicleMaintenances;
    vehicleDamages;
    checkOuts;
    checkIns;
    pricingItems;
    drivers;
    tags;
    avatarFile;
    files;
}
exports.RentalAgreementRecordDto = RentalAgreementRecordDto;
